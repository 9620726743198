import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment-timezone';
import { EventModel } from '../../../../shared/model/event';
import { ActivityModel } from '../../../../shared/model/actvity';
import {FacadeService} from "../../../../shared/services/facade.service";

@Component({
    selector: 'app-detail-view',
    templateUrl: './detail-view.component.html',
    styleUrls: ['./detail-view.component.scss']
})
export class DetailViewComponent implements OnInit {

    public event: EventModel = {} as EventModel;
    public eventDates: string;

    constructor(private facadeService: FacadeService) {}

    ngOnInit() {

        this.facadeService.eventService.getEvent().subscribe((event) => {

            this.event = event;

            let dates = [];
            this.event.activities.forEach((activity) => {
                if (activity.start_at) dates.push(moment(activity.start_at).format('DD/MM/YYYY'));
            });

            this.eventDates = dates.filter((item,index,self) => self.indexOf(item)==index).join(' - ');
        });
    }


    getActivityType(activity: ActivityModel): string {
        let activityIcons = {
            'food': 'fas fa-utensils',
            'drink': 'fas fa-glass-martini',
            'music': 'fas fa-music',
            'assembly': 'fas fa-gavel',
            'sport': 'fas fa-futbol',
            'other': 'far fa-calendar',
        };

        return activityIcons[activity.type];
    }

}
