import { Component, OnInit } from '@angular/core';
import {DialogConfig} from "../dialog/dialog-config";
import {DialogRef} from "../dialog/dialog-ref";

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {

  title: string = '';
  msg: string = '';
  type: string = 'alert';

  constructor(public config: DialogConfig, public dialog: DialogRef) {}

  onClose() {
    this.dialog.close(false);
  }

  ngOnInit() {
    this.title = this.config.title;
    this.msg = this.config.msg;
    this.type = this.config.type || this.type;
  }

  onOk() {
    this.dialog.close(true);
  }

}
