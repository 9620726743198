import { ActivityModel } from "./actvity";
import { ReferentModel } from "./referent";
import { PartecipantModel } from "./partecipant";

export class EventModel
{
    id: number;
    name: string;
    description: string;
    status: string = 'hidden';
    label: string;
    is_public: boolean = false;
    is_close: boolean = false;
    allow_partecipate: boolean = false;
    max_partecipants: number;
    actual_partecipants: number = 0;
    actual_payment: number = 0;
    partecipants_per_user: number = 10;
    auto_close: number = 0;
    share: Array<any> = [];

    activities: ActivityModel[];
    referents: ReferentModel[];
    partecipants: PartecipantModel[];
}
