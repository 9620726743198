import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR} from "@angular/forms";
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-reactive-datetime-field',
  templateUrl: './reactive-datetime-field.component.html',
  styleUrls: ['./reactive-datetime-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReactiveDatetimeFieldComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ReactiveDatetimeFieldComponent),
      multi: true
    }
  ]
})
export class ReactiveDatetimeFieldComponent implements OnInit, ControlValueAccessor {

  @Input() config;
  @Input() group;
  @Input() formSubmitAttempt;

  value: string;
  formControl;

  private days: Array<string>;
  private months: Array<any>;
  private years: Array<number>;

  private day: number;
  private year: number;
  private month: any;
  private hour: string;
  private minute: string;

  private date: any;

  constructor() { }


  ngOnInit() {

    this.buildYears();

    this.months = [
      { value: 1, name: 'Gen', days: 31 },
      { value: 2, name: 'Feb', days: 28 },
      { value: 3, name: 'Mar', days: 31 },
      { value: 4, name: 'Apr', days: 30 },
      { value: 5, name: 'Mag', days: 31 },
      { value: 6, name: 'Giu', days: 30 },
      { value: 7, name: 'Lug', days: 31 },
      { value: 8, name: 'Ago', days: 31 },
      { value: 9, name: 'Set', days: 30 },
      { value: 10, name: 'Ott', days: 31 },
      { value: 11, name: 'Nov', days: 30 },
      { value: 12, name: 'Dic', days: 31 },
    ];

    this.buildDays();



    this.formControl = this.group.get(this.config.key);
    this.formControl.valueChanges.subscribe((value) => {
      this.writeValue(value)
    });

    this.writeValue(this.formControl.value);
  }

  buildYears() {

    let year = moment().year();
    let startYear = this.config.yearFrom || year;
    let finishYear = this.config.yearTo || year + 1;

    this.years = [];
    for (let i = startYear; i <= finishYear; i++) {
      this.years.push(i);
    }
  }

  buildDays() {

    let max = this.month ? this.month.days : 31;

    if (this.month && this.month.value == 2 && this.isBissextile()) {
      max = 29;
    }

    this.days = [];
    for (let i = 1; i <= max; i++) {
      this.days.push(('0'+i).substr(-2));
    }
  }

  isBissextile()
  {
    return ((this.year % 4 == 0 && this.year % 100 != 0) || this.year % 400 == 0);
  }

  showDate()
  {
    return this.config.format == 'datetime' || this.config.format == 'date';
  }

  showTime()
  {
    return this.config.format == 'datetime' || this.config.format == 'time';
  }

  changeDate(type, e) {

    let date = this.date;

    switch(type) {
      case 'd':
        date.date(e);
        break;
      case 'm':
        this.buildDays();
        if (date.date() > this.month.days) {
          date.date(this.month.days);
        }
        date.month(e-1);
        break;
      case 'y':
        date.year(e);
        break;
      case 'h':
        this.hour = e;
        date.hours(e);
        break;
      case 'i':
        this.minute = e;
        date.minutes(e);
        break;
    }

    if (date.minutes()%5 != 0) {
      date.minutes(date.minutes() + 5 - date.minutes()%5);
    }

    date.seconds(0);

    this.propagateChange(date.format());
  }

  isValid()
  {
    return this.formControl.valid || !(
        (!this.formControl.valid && this.formControl.touched) ||
        (this.formControl.untouched && this.formSubmitAttempt)
    );
  }


  writeValue(value) {

    let tz = this.config.format == 'datetime' ? 'Europe/Rome' : 'UTC';
    let date = value ? moment(value).tz(tz) : moment().tz(tz);

    if (this.config.format == 'date') {
      date.hours(0).minutes(0).seconds(0);
    }

    this.value = value;
    this.date = date;

    this.day = this.date.date();
    this.month = this.months.filter((m) => m.value == this.date.month()+1)[0];
    this.year = this.date.year();

    this.buildDays();
  }

  validate() {
  }

  propagateChange: any = (value) => {
    this.formControl.setValue(value);
    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
    this.formControl.updateValueAndValidity();
  };


  validateFn: any = () => {};

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    //this.onChangeCallback = fn;
    this.propagateChange = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    //this.onTouchedCallback = fn;
  }

}
