import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable()
export class LoadingService {

  constructor() { }

  private isLoading: Subject<boolean> = new Subject<boolean>();
  private loadingArray: BehaviorSubject<Array<string>> = new BehaviorSubject<Array<string>>([]);
  private loadingArrayData: Array<string> = [];

  loading(action: string, loading: boolean) {

    if (loading) {

        this.loadingArrayData.push(action);

    } else {

        const index = this.loadingArrayData.indexOf(action);
        if (index > -1) {
            this.loadingArrayData.splice(index, 1);
        }

    }

    this.isLoading.next(this.loadingArrayData.length > 0);
    this.loadingArray.next(this.loadingArrayData);
  }

  getIsLoading()
  {
    return this.isLoading.asObservable();
  }

  getLoadingArray()
  {
    return this.loadingArray.asObservable();
  }
}
