import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {
    Router, Resolve,
    ActivatedRouteSnapshot
} from '@angular/router';
import {FacadeService} from "../../../shared/services/facade.service";
import {PurchaseOrderModel} from "../../../shared/model/purchase-orders/purchase-order";
import {OrderRequestModel} from "../../../shared/model/purchase-orders/order-request";


@Injectable()
export class OrderRequestResolver implements Resolve<OrderRequestModel> {
    constructor(private facadeService: FacadeService, private router: Router) { }
    
    resolve(route: ActivatedRouteSnapshot): Observable<OrderRequestModel> {
        let purchaseOrderId = +route.params['purchaseOrderId'];
        let id = +route.params['id'];
        return this.facadeService.purchaseOrderService.getOrderRequest(purchaseOrderId, id).pipe(
            map(order_request => order_request)
        );
    }
}