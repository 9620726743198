import { Injectable } from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {
    Router, Resolve,
    ActivatedRouteSnapshot
} from '@angular/router';
import {FacadeService} from "../../../shared/services/facade.service";
import {PurchaseOrderModel} from "../../../shared/model/purchase-orders/purchase-order";
import {OrderRequestModel} from "../../../shared/model/purchase-orders/order-request";
import {OrderRequestStatModel} from "../../../shared/model/purchase-orders/order-request-stat";
import {OrderRequestCashModel} from "../../../shared/model/purchase-orders/order-request-cash";


@Injectable()
export class OrderRequestCashResolver implements Resolve<{ order_request_cash: OrderRequestCashModel, purchase_order: PurchaseOrderModel }> {
    constructor(private facadeService: FacadeService, private router: Router) { }

    resolve(route: ActivatedRouteSnapshot): Observable<{ order_request_cash: OrderRequestCashModel, purchase_order: PurchaseOrderModel }> {
        let purchaseOrderId = +route.params['id'];

        let a  = this.facadeService.purchaseOrderService.loadOrderRequestCash(purchaseOrderId).pipe();
        let b  = this.facadeService.purchaseOrderService.getPurchaseOrder(purchaseOrderId).pipe();

        return forkJoin(a,b).pipe(map((allResponses) => {
            return {
                order_request_cash: allResponses[0],
                purchase_order: allResponses[1],
            };
        }));
    }
}