import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {BehaviorSubject, Observable, of} from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { UserModel } from '../../model/user';
import { RoleModel } from '../../model/role';
import {HttpService} from "../http.service";
import {AccountModel} from "../../model/account";

@Injectable({
    providedIn: 'root'
})
export class UsersService {

    public currentAccount: AccountModel;

    private usersUrl = '/users';  // URL to web api

    private users: BehaviorSubject<UserModel[]> = new BehaviorSubject<UserModel[]>([]);
    private user: BehaviorSubject<UserModel> = new BehaviorSubject<UserModel>({} as UserModel);

    private dataStore: {
        users: UserModel[]
    } = { users: []};

    constructor(private http: HttpService) { }

    getAccountGroups() {

        const usersGroups = this.currentAccount.users_groups;

        let groups: Array<{}>= [];

        for (let i in usersGroups) {
            groups.push({ value: usersGroups[i].id, label: usersGroups[i].name });
        }

        return groups;
    }


    /** GET heroes from the server */
    getUsers (): Observable<UserModel[]> {

        return this.http.get('loadUsers', this.usersUrl).pipe(
            tap(users => this.log(`fetched users`)),
            catchError(this.handleError('getUsers', []))
        );

    }

    getUser() {

        return this.user.asObservable();

    }


    loadUser(user: UserModel | number) {

        const id = typeof user === 'number' ? user : user.id;
        const url = `${this.usersUrl}/${id}`;

        return this.http.get('loadUser', url).pipe(
            map(
                (user: UserModel) => {
                    const index = this.dataStore.users.findIndex((u) => u.id == user.id);

                    if (index > -1) {
                        this.dataStore.users[index] = user;
                    } else {
                        this.dataStore.users.push(user);
                    }

                    this.user.next(user);
                    this.users.next(this.dataStore.users);

                    return user;
                }
            ),
            tap(_ => this.log(`fetched user id=${id}`)),
            catchError(this.handleError<UserModel>(`getUser id=${id}`))
        );

    }

    exportUsers(): Observable<any> {

        const url = `${this.usersUrl}/export`;

        return this.http.get('exportUsers', url).pipe(
            tap(_ => this.log(`exported users`)),
            catchError(this.handleError<UserModel>(`exportUsers`))
        );

    }


    updateUser (user: UserModel): Observable<any> {

        const id = user.id;
        const url = `${this.usersUrl}/${id}`;

        return this.http.post('updateUser', url, user).pipe(
            tap(_ => this.log(`updated user id=${user.id}`)),
            catchError(this.handleError<any>('updateUser'))
        );

    }


    updateRole (role: RoleModel): Observable<any> {

        const id = role.id;
        const userId = role.user_id;
        const url = `${this.usersUrl}/${userId}/roles/${id}`;

        return this.http.post('updateRole', url, role).pipe(
            tap(_ => this.log(`updated role id=${role.id}`)),
            catchError(this.handleError<any>('updateRole'))
        );
    }


    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T> (operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    /** Log a HeroService message with the MessageService */
    private log(message: string) {
        console.log('UsersService: ' + message);
    }
}
