import { Component, OnInit } from '@angular/core';
import {DialogConfig} from "../../../../core/components/dialog/dialog-config";
import {DialogRef} from "../../../../core/components/dialog/dialog-ref";

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {

    public day: string;

    constructor(public config: DialogConfig, public dialog: DialogRef) {}

    onClose() {
        this.dialog.close({});
    }

    ngOnInit() {
        this.day = this.config.data.filterDay;
    }

    onOk() {
        this.dialog.close({ filterDay: this.day } );
    }
}