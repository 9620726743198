import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-search-partecipant-bar',
  templateUrl: './search-partecipant-bar.component.html',
  styleUrls: ['./search-partecipant-bar.component.scss']
})
export class SearchPartecipantBarComponent implements OnInit {
 
  @Output() onFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onExport: EventEmitter<any> = new EventEmitter<any>();

  public filter = {
    term: '',
    type: 'enabled'
  };

  constructor() { }

  ngOnInit() {
  }

  onChange(change) {
    this.onFilter.emit(this.filter);
  }

  onExportClick(event)
  {
    this.onExport.emit();
  }


}
