import { Component, OnInit } from '@angular/core';
import {PurchaseOrderModel} from "../../../../shared/model/purchase-orders/purchase-order";
import {FacadeService} from "../../../../shared/services/facade.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DialogService} from "../../../../core/components/dialog/dialog.service";
import {OrderRequestModel} from "../../../../shared/model/purchase-orders/order-request";
import {LoadingService} from "../../../../shared/services/loading.service";
import {FilterOrderRequestDialogComponent} from "../../components/filter-order-request-dialog/filter-order-request-dialog.component";
import {SearchOrderRequestDialogComponent} from "../../components/search-order-request-dialog/search-order-request-dialog.component";
import {StateService} from "../../../../shared/services/state.service";
import {AlertDialogComponent} from "../../../../core/components/alert-dialog/alert-dialog.component";

@Component({
  selector: 'app-order-request-list',
  templateUrl: './order-request-list.component.html',
  styleUrls: ['./order-request-list.component.scss']
})
export class OrderRequestListComponent implements OnInit {

  public purchase_order: PurchaseOrderModel;

  public order_requests: OrderRequestModel[];

  public isLoading: boolean = false;

  public state: { sort: string, view: string, searchFilter: any } = {
    sort: 'ASC',
    view: 'all',
    searchFilter: false
  };

  canSeeTotals: boolean = false;

  constructor(private facadeService: FacadeService, private stateService: StateService, private router: Router, public dialog: DialogService, private route: ActivatedRoute,
              private loadingService: LoadingService ) { }

  ngOnInit() {
    this.route.data.subscribe((data: { purchase_order: PurchaseOrderModel }) => {
      if(data.purchase_order) this.purchase_order = data.purchase_order;
      else this.router.navigate(['/purchase-orders']);
    });

    this.loadingService.getLoadingArray().subscribe((loading) => {
      this.isLoading = loading.some((value) => ['loadOrderRequests'].indexOf(value) > -1 );
    });

    this.stateService.getState().subscribe((state) => {
      this.state = Object.assign({}, this.state, state);
    });

    this.getOrderRequests();
    this.facadeService.purchaseOrderService.getOrderRequests().subscribe((order_requests) => this.order_requests = order_requests);

    this.canSeeTotals = this.facadeService.authService.isEnabled() && (this.facadeService.authService.isSuperuser() || this.facadeService.authService.isAdmin() || this.facadeService.authService.isCollaborator());
  }

  platesServed(orderRequest)
  {
    return orderRequest.plates.filter((p) => p.quantity && p.served).length;
  }

  totalPlates(orderRequest)
  {
    return orderRequest.plates.filter((p) => p.quantity).length;
  }

  getOrderRequests()
  {
    const filter = {
      sort: this.state.sort
    };
    this.facadeService.purchaseOrderService.loadOrderRequests(this.purchase_order.id, filter).subscribe();
  }

  add(): void {
    this.router.navigate(['/purchase-orders/order-request-form/' + this.purchase_order.id + '/-1']);
  }

  complete(orderRequest, e): void {
    e.preventDefault();
    e.stopPropagation();

    const ref = this.dialog.open(AlertDialogComponent, { msg: 'Vuoi davvero chiudere la comanda?', type: 'confirm' });
    ref.afterClosed.subscribe(result => {
      if (result) {
        this.facadeService.purchaseOrderService.completeOrderRequest(orderRequest).subscribe((order_request) => {
          const index = this.order_requests.findIndex((or) => or.id == order_request.id);
          this.order_requests[index] = order_request;
          this.order_requests = this.order_requests.slice();
        });
      }
    });
  }

  scrollTop(): void {
    const section = document.querySelector('section.shift-list');
    if (section) {
      section.scrollIntoView();
    }
  }

  onSort(sort): void {
    this.stateService.applyState({ sort: sort });
    this.getOrderRequests();
  }


  onSearchFilter(): void {

    const ref = this.dialog.open(SearchOrderRequestDialogComponent, { data: { searchFilter: this.state.searchFilter }});
    ref.afterClosed.subscribe(result => {
      this.stateService.applyState({searchFilter: result.searchFilter || false });
    });

  }

  onFilter(): void {
    const ref = this.dialog.open(FilterOrderRequestDialogComponent, { data: { view: this.state.view }});
    ref.afterClosed.subscribe(result => {
      if (result && result.view) {
        this.stateService.applyState({view: result.view});
      }
    });
  }


}
