import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import {FacadeService} from "../services/facade.service";


@Injectable()
export class LoggedGuard implements CanActivate {
  constructor(private facadeService: FacadeService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    
    if (this.facadeService.authService.isAuth()) {
      return Promise.resolve(true);
    }

    if (this.facadeService.authService.getToken()) {
      return this.facadeService.initializeUser().then(() => {
    
        return true;

      }).catch(() => {

        this.router.navigate(['auth']);
        return false;
        
      });
    }
  
    this.router.navigate(['auth']);
    return Promise.resolve(false);
  }

}
