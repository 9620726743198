import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, of} from "rxjs";
import {HttpService} from "../http.service";
import {catchError, map, tap} from "rxjs/operators";
import {ShiftGroupModel} from "../../model/shift/shift_group";
import {GroupUserModel} from "../../model/shift/group_user";
import {ShiftModel} from "../../model/shift/shift";

@Injectable({
  providedIn: 'root'
})
export class ShiftGroupsService {

  private shiftGroupsUrl = '/shifts/groups';  // URL to web api

  private shift_groups: BehaviorSubject<ShiftGroupModel[]> = new BehaviorSubject<ShiftGroupModel[]>([]);

  private dataStore: {
    shift_groups: ShiftGroupModel[]
  } = { shift_groups: []};


  constructor(private http: HttpService) { }

  getShiftGroups() {
    return this.shift_groups.asObservable();
  }

  loadShiftGroups (): Observable<ShiftGroupModel[]> {
    return this.http.get('loadShiftGroups', this.shiftGroupsUrl).pipe(
        map((shift_groups) => {
          Object.assign(this.dataStore, {shift_groups: shift_groups});
          this.shift_groups.next(this.dataStore.shift_groups);
          return shift_groups;
        }),
        tap(shifts => this.log(`fetched shift groups`)),
        catchError(this.handleError('loadShiftGroups', []))
    );
  }


  getShiftGroup(id: number): Observable<ShiftGroupModel> {

    const url = `${this.shiftGroupsUrl}/${id}`;

    if (id < 0) {
      return of({ id: -1, name: '', group_users: [] } as ShiftGroupModel);
    }

    return this.http.get('loadShiftGroup', url).pipe(
        tap(_ => this.log(`fetched shift_group id=${id}`)),
        catchError(this.handleError<ShiftModel>(`getShiftGroup id=${id}`))
    );

  }

  addShiftGroup (shiftGroup: ShiftGroupModel): Observable<ShiftGroupModel> {

    return this.http.post('addShiftGroup', this.shiftGroupsUrl, shiftGroup).pipe(
        tap((shift_group: ShiftGroupModel) => this.log(`added shift_group w/ id=${shift_group.id}`)),
        catchError(this.handleError<ShiftGroupModel>('addShiftGroup'))
    );

  }

  deleteShiftGroup (shift: ShiftGroupModel| number): Observable<ShiftGroupModel> {

    const id = typeof shift === 'number' ? shift : shift.id;
    const url = `${this.shiftGroupsUrl}/${id}`;

    return this.http.delete('deleteShiftGroup', url).pipe(
        tap(_ => this.log(`deleted shift_group id=${id}`)),
        catchError(this.handleError<ShiftGroupModel>('deleteShiftGroup'))
    );

  }

  /** PUT: update the hero on the server */
  updateShiftGroup (shiftGroup: ShiftGroupModel): Observable<any> {

    const id = shiftGroup.id;
    const url = `${this.shiftGroupsUrl}/${id}`;

    return this.http.post('updateShiftGroup', url, shiftGroup).pipe(
        tap((shift_group: ShiftGroupModel) => this.log(`updated shift_group id=${shift_group.id}`)),
        catchError(this.handleError<any>('updateShiftGroup'))
    );

  }


  addGroupUser(shiftGroup: ShiftGroupModel | number, groupUser: GroupUserModel): Observable<GroupUserModel> {

    const id = typeof shiftGroup === 'number' ? shiftGroup : shiftGroup.id;
    const url = `${this.shiftGroupsUrl}/${id}/group-users`;

    return this.http.post('addGroupUser', url, groupUser).pipe(
        tap((groupUser: GroupUserModel) => this.log(`added groupUser w/ id=${groupUser.id}`)),
        catchError(this.handleError<GroupUserModel>('addGroupUser'))
    );
  }

  /** DELETE: delete the shiftGroup from the server */
  deleteGroupUser (shiftGroup: ShiftGroupModel | number, groupUser: GroupUserModel | number): Observable<GroupUserModel> {
    const id = typeof shiftGroup === 'number' ? shiftGroup : shiftGroup.id;
    const groupUserId = typeof groupUser === 'number' ? groupUser : groupUser.id;
    const url = `${this.shiftGroupsUrl}/${id}/group-users/${groupUserId}`;

    return this.http.delete('deleteGroupUser', url).pipe(
        tap(_ => this.log(`deleted groupUser id=${groupUserId}`)),
        catchError(this.handleError<GroupUserModel>('deleteGroupUser'))
    );
  }
  
  
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    console.log('ShiftsService: ' + message);
  }


}
