import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {BehaviorSubject, Observable, of} from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { EventModel } from '../../model/event';
import { ActivityModel } from '../../model/actvity';
import { ReferentModel } from '../../model/referent';
import {PartecipantModel} from "../../model/partecipant";
import { HttpService } from "../http.service";
import {AccountModel} from "../../model/account";
import {AuthService} from "../auth.service";
import {UserModel} from "../../model/user";


@Injectable({
    providedIn: 'root'
})
export class EventsService {

    public currentAccount: AccountModel;

    private eventsUrl = '/events';  // URL to web api

    private events: BehaviorSubject<EventModel[]> = new BehaviorSubject<EventModel[]>([]);
    private event: BehaviorSubject<EventModel> = new BehaviorSubject<EventModel>({} as EventModel);

    private dataStore: {
        events: EventModel[]
    } = { events: []};

    constructor(private http: HttpService, private authService: AuthService) { }


    getEvent()
    {
        return this.event.asObservable();
    }

    getEvents() {
        return this.events.asObservable();
    }

    getDefaultShareOptions()
    {
        let groups = this.authService.getGroups();

        if (this.authService.isCollaborator()) {
            if (groups.length) {
                return [groups[0]];
            }
            return [];
        } else {
            return ['all'];
        }
    }

    getShareOptions()
    {
        const usersGroups = this.currentAccount.users_groups;
        let share: Array<{value: any, disabled: boolean, label: string, exclusive?: boolean}> = [
            {value: 'all', label: 'Tutti', exclusive: true, disabled: false }
        ];

        for (let i in usersGroups) {
            share.push({ value: usersGroups[i].id, label: usersGroups[i].name, disabled: false });
        }


        if (this.authService.isCollaborator()) {

            for (let i in share) {
                if (this.authService.getGroups().indexOf(share[i].value) < 0) {
                    share[i].disabled = true;
                }
            }

        }

        return share;

    }


    loadEvents (filter): Observable<EventModel[]> {

        return this.http.get('loadEvents', this.eventsUrl, filter).pipe(
            map((events: EventModel[]) => {

               Object.assign(this.dataStore, { events: events });
               this.events.next(this.dataStore.events);
               return events;

            }),
            tap(events => this.log(`fetched events`)),
            catchError(this.handleError('getEvents', []))
        );
    }

    loadEvent(id: number): Observable<EventModel> {

        const url = `${this.eventsUrl}/${id}`;

        return this.http.get('loadEvent', url).pipe(

            map((event: EventModel) => {
                const index = this.dataStore.events.findIndex((e) => e.id == event.id);

                if (index > -1) {
                    this.dataStore.events[index] = event;
                } else {
                    this.dataStore.events.push(event);
                }

                this.event.next(event);
                this.events.next(this.dataStore.events);

                return event;
            }),

            tap(_ => this.log(`fetched event id=${id}`)),
            catchError(this.handleError<EventModel>(`getEvent id=${id}`))
        );

    }

    addEvent (event: EventModel): Observable<EventModel> {

        return this.http.post('addEvent', this.eventsUrl, event).pipe(
            tap((event: EventModel) => this.log(`added event w/ id=${event.id}`)),
            catchError(this.handleError<EventModel>('addEvent'))
        );

    }

    deleteEvent (event: EventModel | number): Observable<EventModel> {

        const id = typeof event === 'number' ? event : event.id;
        const url = `${this.eventsUrl}/${id}`;

        return this.http.delete('deleteEvent', url).pipe(
            tap(_ => this.log(`deleted event id=${id}`)),
            catchError(this.handleError<EventModel>('deleteEvent'))
        );

    }


    updateEvent (event: EventModel): Observable<any> {

        const id = event.id;
        const url = `${this.eventsUrl}/${id}`;

        return this.http.post('updateEvent', url, event).pipe(
            tap(_ => this.log(`updated event id=${event.id}`)),
            catchError(this.handleError<any>('updateEvent'))
        );

    }

    loadPartecipant(event: EventModel | number, partecipant: PartecipantModel | number): Observable<PartecipantModel> {

        const id = typeof event === 'number' ? event : event.id;
        const partecipantId = typeof partecipant === 'number' ? partecipant : partecipant.id;
        const url = `${this.eventsUrl}/${id}/partecipants/${partecipantId}`;

        return this.http.get('loadPartecipant', url).pipe(
            tap(_ => this.log(`read partecipant id=${partecipantId}`)),
            catchError(this.handleError<PartecipantModel>('getPartecipant'))
        );
    }

    addPartecipant (event: EventModel | number, partecipant: PartecipantModel): Observable<PartecipantModel> {

        const id = typeof event === 'number' ? event : event.id;
        const url = `${this.eventsUrl}/${id}/partecipants`;

        return this.http.post('addPartecipant', url, partecipant).pipe(
            tap((partecipant: PartecipantModel) => this.log(`added partecipant w/ id=${partecipant.id}`)),
            catchError(this.handleError<PartecipantModel>('addPartecipant'))
        );
    }

    /** DELETE: delete the event from the server */
    deletePartecipant (event: EventModel | number, partecipant: PartecipantModel | number): Observable<PartecipantModel> {
        const id = typeof event === 'number' ? event : event.id;
        const partecipantId = typeof partecipant === 'number' ? partecipant : partecipant.id;
        const url = `${this.eventsUrl}/${id}/partecipants/${partecipantId}`;

        return this.http.delete('deletePartecipant', url).pipe(
            tap(_ => this.log(`deleted partecipant id=${partecipantId}`)),
            catchError(this.handleError<PartecipantModel>('deletePartecipant'))
        );
    }

    /** PUT: update the hero on the server */
    updatePartecipant (event: EventModel | number, partecipant: PartecipantModel): Observable<any> {

        const id = typeof event === 'number' ? event : event.id;
        const partecipantId = partecipant.id;
        const url = `${this.eventsUrl}/${id}/partecipants/${partecipantId}`;

        return this.http.post('updatePartecipant', url, partecipant).pipe(
            tap(_ => this.log(`updated partecipant id=${partecipantId}`)),
            catchError(this.handleError<any>('updatePartecipant'))
        );
    }

    updatePartecipantPayment(event: EventModel | number, partecipant: PartecipantModel): Observable<any> {
        const id = typeof event === 'number' ? event : event.id;
        const partecipantId = partecipant.id;
        const url = `${this.eventsUrl}/${id}/partecipants/${partecipantId}/payment`;

        return this.http.post('updatePartecipantPayment', url, partecipant).pipe(
            tap(_ => this.log(`updated partecipant id=${partecipantId}`)),
            catchError(this.handleError<any>('updatePartecipant'))
        );
    }

    exportPartecipants(event: EventModel | number): Observable<any> {

        const id = typeof event === 'number' ? event : event.id;
        const url = `${this.eventsUrl}/${id}/partecipants/export`;

        return this.http.get('exportPartecipants', url).pipe(
            tap(_ => this.log(`exported partecipants`)),
            catchError(this.handleError<any>(`exportPartecipants`))
        );

    }


    addActivity (event: EventModel, activity: ActivityModel): Observable<ActivityModel> {

        const id = event.id;
        const url = `${this.eventsUrl}/${id}/activities`;

        return this.http.post('addActivity', url, activity).pipe(
            tap((activity: ActivityModel) => this.log(`added activity w/ id=${activity.id}`)),
            catchError(this.handleError<ActivityModel>('addActivity'))
        );

    }


    deleteActivity (event: EventModel, activity: ActivityModel | number): Observable<ActivityModel> {

        const id = event.id;
        const activityId = typeof activity === 'number' ? activity : activity.id;
        const url = `${this.eventsUrl}/${id}/activities/${activityId}`;

        return this.http.delete('deleteActivity', url).pipe(
            tap(_ => this.log(`deleted activity id=${activityId}`)),
            catchError(this.handleError<ActivityModel>('deleteActivity'))
        );

    }


    updateActivity (event: EventModel | number, activity: ActivityModel): Observable<any> {

        const id = typeof event === 'number' ? event : event.id;
        const activityId = activity.id;
        const url = `${this.eventsUrl}/${id}/activities/${activityId}`;

        return this.http.post('updateActivity', url, activity).pipe(
            tap(_ => this.log(`updated activity id=${activityId}`)),
            catchError(this.handleError<any>('updateActivity'))
        );

    }


    addReferent (event: EventModel, referent: ReferentModel): Observable<ReferentModel> {

        const id = event.id;
        const url = `${this.eventsUrl}/${id}/referents`;

        return this.http.post('addReferent', url, referent).pipe(
            tap((referent: ReferentModel) => this.log(`added referent w/ id=${referent.id}`)),
            catchError(this.handleError<ReferentModel>('addReferent'))
        );

    }


    deleteReferent (event: EventModel, referent: ReferentModel | number): Observable<ReferentModel> {

        const id = event.id;
        const referentId = typeof referent === 'number' ? referent : referent.id;
        const url = `${this.eventsUrl}/${id}/referents/${referentId}`;

        return this.http.delete('deleteReferent', url).pipe(
            tap(_ => this.log(`deleted referent id=${referentId}`)),
            catchError(this.handleError<ReferentModel>('deleteReferent'))
        );

    }


    updateReferent (event: EventModel | number, referent: ReferentModel): Observable<any> {

        const id = typeof event === 'number' ? event : event.id;
        const referentId = referent.id;
        const url = `${this.eventsUrl}/${id}/referents/${referentId}`;

        return this.http.post('updateReferent', url, referent).pipe(
            tap(_ => this.log(`updated referent id=${referentId}`)),
            catchError(this.handleError<any>('updateReferent'))
        );

    }


    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T> (operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    /** Log a HeroService message with the MessageService */
    private log(message: string) {
        console.log('EventService: ' + message);
    }
}