import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import { AccountComponent } from './account.component';
import { AccountListComponent } from './pages/account-list/account-list.component';

import {AdminGuard} from "../../shared/guard/admin.guard";
import {AccountFormComponent} from "./pages/account-form/account-form.component";
import {AccountResolve} from "./resolvers/account.resolver";


const accountRoutes: Routes = [
    {
        path: '',
        component: AccountComponent,
        children: [
            {
                path: '',
                component: AccountListComponent
            }
        ]
    },
    {
        path: 'edit/:id',
        component: AccountFormComponent,
        data: {
            history: false
        },
        resolve: {
            account: AccountResolve
        }
    }
];

@NgModule({
  imports: [
    RouterModule.forChild(accountRoutes)
  ],
  exports: [
    RouterModule
  ]
})

export class AccountRoutingModule {
}