import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad } from '@angular/router';
import { Observable } from 'rxjs';
import { Route } from '@angular/compiler/src/core';
import {FacadeService} from "../services/facade.service";

@Injectable()
export class AccountGuard implements CanLoad {
  constructor(private facadeService: FacadeService, private router: Router) {}

  canLoad(route:Route): Observable<boolean> | Promise<boolean> | boolean {

    if (this.facadeService.getCurrentAccountId()) {
      return Promise.resolve(true);
    }

    this.router.navigate(['404']);
    return Promise.resolve(false);
  }
}
