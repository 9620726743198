import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

    @Input() type: string = 'button';
    @Input() floating: boolean = false;
    @Input() active: boolean = false;
    @Input() size: string = 'normal';
    @Input() link: string = '';
    @Input() icon: string = '';
    @Input() text: string = '';
    @Input() disabled: boolean = false;
    @Input() inverse: boolean = false;
    @Input() danger: boolean = false;
    @Input() success: boolean = false;
    @Input() alert: number = 0;

    @Output() onClick: EventEmitter<any> = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

    click(e) {
        if (!this.disabled) {
            this.onClick.emit(e);
        }
    }
}
