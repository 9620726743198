import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterOrderRequests'
})
export class FilterOrderRequestsPipe implements PipeTransform {

  transform(value: any, status: string, searchFilter: any ): any {
    return value.filter((v) => {
      return {
        pending: !v.completed && !v.cancelled,
        completed: v.completed,
        cancelled: v.cancelled,
        processed: v.cancelled || v.completed,
        all: true
      }[status] && (
            !searchFilter
          || v.number == searchFilter.number
          || v.client_name.indexOf(searchFilter.client_name) > -1
          || v.reference.indexOf(searchFilter.reference) > -1
      );

    });
  }

}
