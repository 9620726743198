import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
    HttpResponse
} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { LoadingService } from '../services/loading.service';
import { environment } from '../../../environments/environment';
import { AlertService } from '../services/alert.service';
import {FacadeService} from "../services/facade.service";


@Injectable()
export class RequestInterceptor implements HttpInterceptor {

    constructor(public facadeService: FacadeService, private router: Router, private loadingService: LoadingService, private alertService: AlertService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const action = request.headers.get('X-Action');
        let headers = {};

        headers['Content-Type'] = 'application/json';

        if (this.facadeService.authService.getToken()) {
            headers['Authorization'] = `Bearer ${this.facadeService.authService.getToken()}`;
        }
        if (this.facadeService.authService.getDeviceUUID()) {
            headers['DeviceUUID'] = `${this.facadeService.authService.getDeviceUUID()}`
        }

        request = request.clone({
            url: `${environment.apiUrl}${request.url}`,
            setHeaders: headers
        });

        this.loadingService.loading(action, true);

        return next.handle(request).pipe(
            map((ev: HttpEvent<any>) => {

                if (ev instanceof HttpResponse) {
                    this.loadingService.loading(action, false);
                    this.displayMessage(ev.body);
                    ev = ev.clone({ body: ev.body.data });
                }

                return ev;

            }),
            catchError((err) => {

                this.loadingService.loading(action, false);

                if (err instanceof HttpErrorResponse) {

                    if (err.status === 401) {
                        this.facadeService.authService.logout();
                        this.router.navigate(['/auth']);
                    } else {
                        this.displayMessage(err.error);
                    }

                }

                return throwError(err);
            }));
    }

    displayMessage(response) {
        this.alertService.setMessage({
            success: response.success,
            text: response.message
        });
    }
}