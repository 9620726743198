import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Error404Component } from './pages/error404/error404.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { RouterModule } from '@angular/router';
import { BottomBarComponent } from './components/bottom-bar/bottom-bar.component';
import { NavTopBarComponent } from './components/nav-top-bar/nav-top-bar.component';
import { LoadingMaskComponent } from './components/loading-mask/loading-mask.component';
import { DatetimeSelectComponent } from './components/datetime-select/datetime-select.component';
import { UserAutocompleteComponent } from './components/user-autocomplete/user-autocomplete.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ChipsComponent } from './components/chips/chips.component';
import { ReplaceMarkdownPipe } from './pipes/replace-markdown.pipe';
import { CardComponent } from './components/card/card.component';
import { PanelComponent } from './components/panel/panel.component';
import { CustomTopBarComponent } from './components/custom-top-bar/custom-top-bar.component';
import { ButtonComponent } from './components/button/button.component';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { DialogComponent } from './components/dialog/dialog.component';
import {InsertionDirective} from "./components/dialog/insertion.directive";
import {DialogService} from "./components/dialog/dialog.service";
import { ModalDialogComponent } from './components/modal-dialog/modal-dialog.component';
import { PasswordFieldComponent } from './components/password-field/password-field.component';
import { TextFieldComponent } from './components/text-field/text-field.component';
import { CheckboxFieldComponent } from './components/checkbox-field/checkbox-field.component';
import { SelectFieldComponent } from './components/select-field/select-field.component';
import { MultiSelectFieldComponent } from './components/multi-select-field/multi-select-field.component';
import {MultiSelectFieldModalComponent} from "./components/multi-select-field/multi-select-field-modal.component";
import { ReactiveTextFieldComponent } from './components/reactive-text-field/reactive-text-field.component';
import {DynamicControlDirective} from "./directive/dynamic-control.directive";
import { ListItemComponent } from './components/list-item/list-item.component';
import { ReactiveSelectFieldComponent } from './components/reactive-select-field/reactive-select-field.component';
import { ReactiveDatetimeFieldComponent } from './components/reactive-datetime-field/reactive-datetime-field.component';
import { ReactiveCheckboxFieldComponent } from './components/reactive-checkbox-field/reactive-checkbox-field.component';
import {ZoomDirective} from "./directive/zoom.directive";
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import {ConfirmDialogComponent} from "./components/confirm-dialog/confirm-dialog.component";
import { MarkdownModule } from 'ngx-markdown';
import { TextDialogComponent } from './components/text-dialog/text-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        MarkdownModule.forChild(),
    ],
    declarations: [
        Error404Component,
        TopBarComponent,
        SideNavComponent,
        BottomBarComponent,
        NavTopBarComponent,
        LoadingMaskComponent,
        DatetimeSelectComponent,
        UserAutocompleteComponent,
        ChipsComponent,
        ReplaceMarkdownPipe,
        CardComponent,
        PanelComponent,
        CustomTopBarComponent,
        ButtonComponent,
        ToggleSwitchComponent,
        DialogComponent,
        InsertionDirective,
        ModalDialogComponent,
        PasswordFieldComponent,
        TextFieldComponent,
        CheckboxFieldComponent,
        SelectFieldComponent,
        MultiSelectFieldComponent,
        MultiSelectFieldModalComponent,
        ReactiveTextFieldComponent,
        DynamicControlDirective,
        ZoomDirective,
        ListItemComponent,
        ReactiveSelectFieldComponent,
        ReactiveDatetimeFieldComponent,
        ReactiveCheckboxFieldComponent,
        AlertDialogComponent,
        ConfirmDialogComponent,
        TextDialogComponent,
    ],
    exports: [
        TopBarComponent,
        BottomBarComponent,
        NavTopBarComponent,
        LoadingMaskComponent,
        DatetimeSelectComponent,
        UserAutocompleteComponent,
        ChipsComponent,
        ReplaceMarkdownPipe,
        CardComponent,
        PanelComponent,
        CustomTopBarComponent,
        ButtonComponent,
        ToggleSwitchComponent,
        DialogComponent,
        ModalDialogComponent,
        PasswordFieldComponent,
        TextFieldComponent,
        CheckboxFieldComponent,
        SelectFieldComponent,
        MultiSelectFieldComponent,
        ReactiveTextFieldComponent,
        DynamicControlDirective,
        ZoomDirective,
        ListItemComponent,
        ReactiveSelectFieldComponent,
        ReactiveDatetimeFieldComponent,
        ReactiveCheckboxFieldComponent,
    ],

    entryComponents: [
        DialogComponent,
        MultiSelectFieldModalComponent,
        AlertDialogComponent,
      ConfirmDialogComponent,
      TextDialogComponent
    ]

})
export class CoreModule { }
