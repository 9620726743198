import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';


@Pipe({
  name: 'groupActivitiesByDate'
})
export class GroupActivitiesByDatePipe implements PipeTransform {

  transform(value: any, args?: any): any {

      let activities:{day: string, activities: any}[] = [];
      for (let i in value) {

        let index = value[i].start_at ? moment(value[i].start_at).format('YYYY-MM-DD') : 0;
        activities[index] = activities[index] || {
          day: index,
          activities: []
        };

        activities[index].activities.push(value[i]);
        activities[index].activities.sort((a,b) => (a.start_at > b.start_at) ? 1 : ((b.start_at > a.start_at) ? -1 : 0));
      }

      return Object.values(activities).sort((a,b) => (a.day > b.day) ? 1 : ((b.day > a.day) ? -1 : 0));
  }

}
