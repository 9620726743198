import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { EventModel } from '../../../../shared/model/event';
import * as moment from 'moment-timezone';
import {FacadeService} from "../../../../shared/services/facade.service";

@Component({
    selector: 'app-referent-view',
    templateUrl: './referent-view.component.html',
    styleUrls: ['./referent-view.component.scss']
})
export class ReferentViewComponent implements OnInit {

    public event: EventModel = {} as EventModel;
    public eventDates: string;

    constructor(private facadeService: FacadeService) {}

    ngOnInit() {

        this.facadeService.eventService.getEvent().subscribe((event) => {

            this.event = event;

            let dates = [];
            this.event.activities.forEach((activity) => {
                if (activity.start_at) dates.push(moment(activity.start_at).format('DD/MM/YYYY'));
            });

            this.eventDates = dates.filter((item,index,self) => self.indexOf(item)==index).join(' - ');
        });
    }

    getUserPicture(userId): string
    {
        return '';
    }
}
