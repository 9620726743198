import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {
    Router, Resolve,
    ActivatedRouteSnapshot
} from '@angular/router';
import { ShiftModel } from '../../../shared/model/shift/shift';
import {FacadeService} from "../../../shared/services/facade.service";


@Injectable()
export class ShiftResolver implements Resolve<ShiftModel> {
    constructor(private facadeService: FacadeService, private router: Router) { }
    
    resolve(route: ActivatedRouteSnapshot): Observable<ShiftModel> {
        let id = +route.params['id'];
        return this.facadeService.shiftService.getShift(id).pipe(
            map(event => event)
        );
    }
}