import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {
    Router, Resolve,
    ActivatedRouteSnapshot
} from '@angular/router';
import {FacadeService} from "../../../shared/services/facade.service";

@Injectable()
export class EventResolve implements Resolve<boolean> {
    constructor(private facadeService: FacadeService, private router: Router) { }
    
    resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
        let id = +route.params['id'];
        return this.facadeService.eventService.loadEvent(id).pipe(
            map(() => true)
        );
    }
}