import {Component, ElementRef, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormBuilder, NG_VALIDATORS, NG_VALUE_ACCESSOR} from "@angular/forms";
import {FacadeService} from "../../../../shared/services/facade.service";
import {GroupUserModel} from "../../../../shared/model/shift/group_user";

@Component({
  selector: 'app-group-user-field',
  templateUrl: './group-user-field.component.html',
  styleUrls: ['./group-user-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GroupUserFieldComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => GroupUserFieldComponent),
      multi: true
    }
  ]
})
export class GroupUserFieldComponent implements OnInit, ControlValueAccessor {

  constructor(private facadeService: FacadeService, private elementRef: ElementRef, private formBuilder: FormBuilder) { }

@Input() config;
  @Input() group;
  @Input() formSubmitAttempt;

  value: Partial<GroupUserModel>[];

  shift_group_id: number;
  searchTerm: string = '';
  formControl;

  private ids = 0;

  ngOnInit() {
    this.shift_group_id = this.config.shift_group_id;
    this.formControl = this.group.get(this.config.key);
    this.formControl.valueChanges.subscribe((value) => {
      this.writeValue(value.slice())
    });

    this.writeValue(this.formControl.value.slice());
  }

  onSelectUser(e) {
    this.searchTerm = e.term;
    if (e.selected) {
      this.add(e.selected);
    }
  }

  add(selectedUser)
  {
    const v = this.value.slice();

    v.push({
      id: this.ids-=1,
      user_id: parseInt(selectedUser.id),
      full_name: selectedUser.name,
      picture: selectedUser.picture,
      shift_group_id: this.shift_group_id
    });

    this.searchTerm = '';

    this.propagateChange(v);
  }

  remove(groupUser: Partial<GroupUserModel>)
  {
    const v = this.value.filter((gu) => gu.id != groupUser.id);
    this.propagateChange(v);
  }

  isValid()
  {
    return this.formControl.valid || !(
        (!this.formControl.valid && this.formControl.touched) ||
        (this.formControl.untouched && this.formSubmitAttempt)
    );
  }

  hasErrors(groupUser)
  {
    if(this.formControl.errors && this.formControl.errors.duplicateValues) {
      return this.formControl.errors.duplicateValues.indexOf(groupUser.user_id) > -1;
    }
    return false;
  }

  writeValue(value) {

    value.sort((a, b) => {
      const nameA = (a.full_name || '').toUpperCase();
      const nameB = (b.full_name || '').toUpperCase();
      let comparison = 0;
      if (nameA > nameB) {
        comparison = 1;
      }
      if (nameA < nameB) {
        comparison = -1;
      }
      return comparison;
    });

    this.value = value;
  }

  validate() {
  }

  propagateChange: any = (value) => {

    let currentValue = this.formControl.value;
    let currentValueIds = currentValue.map(v => v.id);
    let valueIds = value.map(v => v.id);
    let valuesToAdd = value.filter((v) => currentValueIds.indexOf(v.id) < 0);
    let valuesToRemove = currentValue.filter(v => valueIds.indexOf(v.id) < 0);

    for (let i in valuesToRemove) {
      this.formControl.removeAt(currentValue.findIndex(v => v.id == valuesToRemove[i].id));
    }

    for (let i in valuesToAdd) {
      this.formControl.push(this.formBuilder.group(valuesToAdd[i]));
    }

    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
    this.formControl.updateValueAndValidity();

  };


  validateFn: any = () => {};

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    //this.onChangeCallback = fn;
    this.propagateChange = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    //this.onTouchedCallback = fn;
  }
}
