import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {AccountModel} from "../../../../shared/model/account";
import {FacadeService} from "../../../../shared/services/facade.service";
import {FormBuilder, FormGroup, NgForm, Validators} from "@angular/forms";
import {PurchaseOrderModel, uniqueMenuItem} from "../../../../shared/model/purchase-orders/purchase-order";
import {ReactiveTextFieldComponent} from "../../../../core/components/reactive-text-field/reactive-text-field.component";
import {ReactiveDatetimeFieldComponent} from "../../../../core/components/reactive-datetime-field/reactive-datetime-field.component";
import {MenuItemFieldComponent} from "../../../purchase-orders/components/menu-item-field/menu-item-field.component";
import {ReactiveCheckboxFieldComponent} from "../../../../core/components/reactive-checkbox-field/reactive-checkbox-field.component";

@Component({
  selector: 'app-account-form',
  templateUrl: './account-form.component.html',
  styleUrls: ['./account-form.component.scss']
})
export class AccountFormComponent implements OnInit {

    public account: AccountModel = {} as AccountModel;
    accountForm: FormGroup;
    config;
    formSubmitAttempt: boolean = false;

    constructor(private route: ActivatedRoute, private router: Router, private facadeService: FacadeService, private formBuilder: FormBuilder, private location: Location) { }

    ngOnInit() {
        let currentAccount = this.facadeService.getCurrentAccount();

        this.route.data.subscribe((data: { account: AccountModel }) => {
            if(data.account) this.account = data.account;
            else {
                const next = currentAccount.is_placti ? '/accounts' : '/';
                this.router.navigate([next]);
            }
        });
        this.initForm();
    }

    isValid()
    {
        return true;
    }

    fieldIsValid(field) {
        const control = this.accountForm.get(field);
        return control.valid || !(
            (!control.valid && control.touched) ||
            (control.untouched && this.formSubmitAttempt)
        );
    }

    initForm()
    {
        const me = this,
            account = me.account,
            config = [
                {
                    component: ReactiveTextFieldComponent,
                    type: 'control',
                    label: 'Nome',
                    key: 'name',
                    placeholder: '',
                    value: account.name,
                    disabled: false,
                    validators: [ Validators.required ]
                },
                {
                    component: ReactiveTextFieldComponent,
                    type: 'control',
                    label: 'Dominio',
                    key: 'domain',
                    placeholder: '',
                    value: account.domain,
                    disabled: account.origin && account.origin.substr(-11) != '.placti.com',
                    validators: [ Validators.required ]
                },
                {
                    component: ReactiveTextFieldComponent,
                    type: 'control',
                    label: 'URL',
                    key: 'origin',
                    placeholder: '',
                    value: account.origin,
                    disabled: true
                },
                {
                    component: ReactiveTextFieldComponent,
                    type: 'control',
                    label: 'Inbox',
                    key: 'inbox_email',
                    placeholder: '',
                    value: account.inbox_email,
                    disabled: true
                },
                {
                    component: ReactiveTextFieldComponent,
                    type: 'control',
                    label: 'Email',
                    key: 'email',
                    placeholder: '',
                    value: account.email,
                    disabled: false,
                    validators: [ Validators.required ]
                },
                {
                    component: ReactiveCheckboxFieldComponent,
                    type: 'control',
                    label: 'Abilita utenti automaticamente',
                    key: 'auto_enable_user',
                    value: account.auto_enable_user,
                    disabled: false,
                    validators: []
                },
            ];

        me.accountForm = me.formBuilder.group({});

        config.forEach(control => {
            me.accountForm.addControl(
                control.key,
                this.createControl(control)
            )
        });

        me.config = config;
    }

    createControl(control)
    {
        switch(control.type) {
            case 'array':
                let array = this.formBuilder.array([], control.validators);
                for (let i in control.value) {
                    array.push(this.formBuilder.group(control.value[i]));
                }
                return array;
            case 'control':
                return this.formBuilder.control({ value: control.value, disabled: control.disabled }, control.validators);
        }
    }

    save()
    {
        if (this.accountForm.valid) {
            if (this.accountForm.dirty) {
                this.onSubmit();
            }
        } else {
            this.formSubmitAttempt = true;
        }
    }


    onSubmit()
    {
        Object.assign(this.account, this.accountForm.value);
        let fn;
        if(this.account.id < 0) {
            fn = this.facadeService.accountService.addAccount(this.account);
        } else {
            fn = this.facadeService.accountService.updateAccount(this.account);
        }

        fn.subscribe(account => {
            this.account = account;
            this.formSubmitAttempt = false;
            this.accountForm.patchValue(account);
            this.accountForm.updateValueAndValidity();
            this.accountForm.markAsPristine();
            this.location.go('/purchase-orders/form/' + account.id);
        });
    }

}
