import {Component, OnInit} from '@angular/core';
import {DomSanitizer, Meta, Title} from '@angular/platform-browser';

import {Router} from '@angular/router';
import {UserModel} from './shared/model/user';
import {AuthService} from './shared/services/auth.service';
import {environment} from '../environments/environment';
import {SwUpdate} from "@angular/service-worker";
import {RoutingStateService} from "./shared/services/routingstate.service";
import {EchoService} from "./shared/services/echo.service";
import {FacadeService} from "./shared/services/facade.service";
import {HttpService} from "./shared/services/http.service";
import {AccountModel} from "./shared/model/account";

declare const ga;
declare global {
    interface Window { io: any; }
    interface Window { Echo: any; }
    interface Window { Placti: { account: AccountModel } }
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

    user: UserModel;
    account: AccountModel;
    isLoaded: boolean = true;
    authenticationLoading: boolean = false;

    constructor(

        private title: Title,
        private meta: Meta,

        private router: Router,
        public sanitizer: DomSanitizer,

        private swUpdate: SwUpdate,
        private routingStateService: RoutingStateService,
        private echoService: EchoService,

        private facadeService: FacadeService,

    ) {

        this.account = this.facadeService.getCurrentAccount();

        this.facadeService.getAuthenticationLoading().subscribe((loading) => this.authenticationLoading = loading);

        this.facadeService.authService.setFacebookOauth();

        this.facadeService.authService.setDeviceUUID();

  }

    ngOnInit() {

        if (environment.production && this.account.social_login) {
            ga('set', 'anonymizeIp', true);
            ga('create', 'UA-129757542-2', 'auto');
            ga('send', 'pageview');
        }

        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe(() => {
                window.location.reload();
            });
        }

        this.routingStateService.loadRouting();

         if (environment.production) {
            this.echoService.init();
         }

        this.facadeService.authService.getUser().subscribe(user => {

            if (user.id) {
                this.facadeService.notificationService.loadNotificationCount().subscribe();

                if (user.roles[0].enabled) {
                    this.facadeService.messageService.loadMessageCount().subscribe();
                }
            }
        });
    }

}