import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss']
})
export class ModalDialogComponent implements OnInit {

  @Input() header: boolean = true;
  @Input() fullHeight: boolean = false;
  @Input() footer: boolean = true;
  @Input() closable: boolean = true;
  @Output() onClose: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  close(e){
      this.onClose.emit(e);
  }
}
