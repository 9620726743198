import {ComponentFactoryResolver, ComponentRef, Directive, Input, OnInit, ViewContainerRef} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Directive({
    selector: '[appDynamicControl]'
})
export class DynamicControlDirective implements OnInit {

    @Input() group: FormGroup;
    @Input() config;

    _formSubmitAttempt: boolean = false;
    @Input() set formSubmitAttempt(formSubmitAttempt) {
        this._formSubmitAttempt = formSubmitAttempt;
        if (this.component) {
            this.component.instance.formSubmitAttempt = formSubmitAttempt;
        }
    };

    component: ComponentRef<any>;

    constructor(
        private resolver: ComponentFactoryResolver,
        private container: ViewContainerRef
    ) { }

    ngOnInit()
    {
        const cFactory = this.resolver.resolveComponentFactory(this.config.component);
        this.component = this.container.createComponent(cFactory);
        this.component.instance.group = this.group;
        this.component.instance.config = this.config;
        this.component.instance.formSubmitAttempt = this._formSubmitAttempt;
    }

}
