import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'app-reactive-checkbox-field',
  templateUrl: './reactive-checkbox-field.component.html',
  styleUrls: ['./reactive-checkbox-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReactiveCheckboxFieldComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ReactiveCheckboxFieldComponent),
      multi: true
    }
  ]
})
export class ReactiveCheckboxFieldComponent implements OnInit, ControlValueAccessor {


  @Input() config;
  @Input() group;

  value: boolean;
  formControl;

  constructor() { }

  ngOnInit() {
    this.formControl = this.group.get(this.config.key);
    this.formControl.valueChanges.subscribe(v => this.changeValue(v));
    this.value = this.config.value;
  }

  isValid()
  {
    return !(this.formControl.invalid && this.formControl.touched);
  }

  changeValue(v)
  {
    this.value = v;
    this.propagateChange(v);
  }

  writeValue() {
  }

  validate() {

  }

  propagateChange: any = () => {};
  validateFn: any = () => {};

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    //this.onChangeCallback = fn;
    this.propagateChange = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    //this.onTouchedCallback = fn;
  }


}
