import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShiftsComponent } from './shifts.component';
import { ShiftResolver } from './resolvers/shift.resolver';
import { CoreModule } from '../../core/core.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ShiftsRoutingModule } from './shifts.routing';
import { ShiftPageComponent } from './pages/shift-page/shift-page.component';
import { ShiftListComponent } from './pages/shift-list/shift-list.component';
import { FilterDialogComponent } from './components/filter-dialog/filter-dialog.component';
import { ShiftGroupListComponent } from './pages/shift-group-list/shift-group-list.component';
import { ShiftGroupFormComponent } from './pages/shift-group-form/shift-group-form.component';
import {ShiftGroupResolver} from "./resolvers/shift-group.resolver";
import {ReactiveTextFieldComponent} from "../../core/components/reactive-text-field/reactive-text-field.component";
import { GroupUserFieldComponent } from './components/group-user-field/group-user-field.component';
import { WorkerFieldComponent } from './components/worker-field/worker-field.component';
import { ShiftFormComponent } from './pages/shift-form/shift-form.component';
import {ReactiveSelectFieldComponent} from "../../core/components/reactive-select-field/reactive-select-field.component";
import {ReactiveDatetimeFieldComponent} from "../../core/components/reactive-datetime-field/reactive-datetime-field.component";
import {ReactiveCheckboxFieldComponent} from "../../core/components/reactive-checkbox-field/reactive-checkbox-field.component";

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        FormsModule,
        ReactiveFormsModule,
        ShiftsRoutingModule
    ],
    declarations: [
        ShiftsComponent,
        ShiftPageComponent,
        ShiftListComponent,
        FilterDialogComponent,
        ShiftGroupListComponent,
        ShiftGroupFormComponent,
        GroupUserFieldComponent,
        WorkerFieldComponent,
        ShiftFormComponent
    ],
    providers: [
        ShiftResolver,
        ShiftGroupResolver,
    ],
    entryComponents: [
        FilterDialogComponent,
        ReactiveTextFieldComponent,
        GroupUserFieldComponent,
        WorkerFieldComponent,
        ReactiveSelectFieldComponent,
        ReactiveDatetimeFieldComponent,
        ReactiveCheckboxFieldComponent
    ]
})
export class ShiftsModule { }
