import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CoreModule} from "../../core/core.module";
import { PurchaseOrdersComponent } from './purchase-orders.component';
import { PurchaseOrderFormComponent } from './pages/purchase-order-form/purchase-order-form.component';
import { PurchaseOrderListComponent } from './pages/purchase-order-list/purchase-order-list.component';
import {PurchaseOrderResolver} from "./resolvers/purchase-order.resolver";
import {ReactiveFormsModule} from "@angular/forms";
import {PurchaseOrdersRoutingModule} from "./purchase-orders.routing";
import {PurchaseOrdersResolver} from "./resolvers/purchase-orders.resolver";
import {ReactiveTextFieldComponent} from "../../core/components/reactive-text-field/reactive-text-field.component";
import { OrderRequestListComponent } from './pages/order-request-list/order-request-list.component';
import { OrderRequestFormComponent } from './pages/order-request-form/order-request-form.component';
import { OrderRequestChartComponent } from './pages/order-request-chart/order-request-chart.component';
import {OrderRequestResolver} from "./resolvers/order-request.resolver";
import { PlateFieldComponent } from './components/plate-field/plate-field.component';
import {OrderRequestStatsResolver} from "./resolvers/order-request-stats.resolver";
import { FilterOrderRequestStatsPipe } from './pipes/filter-order-request-stats.pipe';
import { FilterOrderRequestsPipe } from './pipes/filter-order-requests.pipe';
import { FilterOrderRequestDialogComponent } from './components/filter-order-request-dialog/filter-order-request-dialog.component';
import { SearchOrderRequestDialogComponent } from './components/search-order-request-dialog/search-order-request-dialog.component';
import {MenuItemFieldComponent} from "./components/menu-item-field/menu-item-field.component";
import {MenuItemFieldModalComponent} from "./components/menu-item-field/menu-item-field-modal.component";
import { OrderRequestCashComponent } from './pages/order-request-cash/order-request-cash.component';
import {OrderRequestCashResolver} from "./resolvers/order-request-cash.resolver";

@NgModule({
  declarations: [
        PurchaseOrdersComponent,
        PurchaseOrderFormComponent,
        PurchaseOrderListComponent,
        MenuItemFieldComponent,
        OrderRequestListComponent,
        OrderRequestFormComponent,
        OrderRequestChartComponent,
        PlateFieldComponent,
        FilterOrderRequestStatsPipe,
        FilterOrderRequestsPipe,
        FilterOrderRequestDialogComponent,
        SearchOrderRequestDialogComponent,
        MenuItemFieldModalComponent,
        OrderRequestCashComponent
  ],
  imports: [
      CommonModule,
      CoreModule,
      ReactiveFormsModule,
      PurchaseOrdersRoutingModule
  ],
    providers: [
        PurchaseOrderResolver,
        PurchaseOrdersResolver,
        OrderRequestResolver,
        OrderRequestStatsResolver,
        OrderRequestCashResolver
    ],

    entryComponents: [
        MenuItemFieldComponent,
        ReactiveTextFieldComponent,
        PlateFieldComponent,
        FilterOrderRequestDialogComponent,
        SearchOrderRequestDialogComponent,
        MenuItemFieldModalComponent
    ]
})
export class PurchaseOrdersModule { }
