import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appZoom]'
})
export class ZoomDirective implements AfterViewInit, OnInit {

  @Input() fittext? = true;
  @Input() compression? = 1;
  @Input() activateOnResize? = true;
  @Input() delay? = 100;

  private fittextParent: HTMLElement;
  private fittextElement: HTMLElement;
  private computed: CSSStyleDeclaration;
  private newlines: number;
  private lineHeight: string;
  private display: string;
  private calcSize = 10;
  private resizeTimeout: number;


  constructor(
      private el: ElementRef,
      private renderer: Renderer2
  ) {
    this.fittextElement = el.nativeElement;
    this.fittextParent = this.fittextElement.parentElement;
    this.computed = window.getComputedStyle(this.fittextElement);
    this.newlines = this.fittextElement.childElementCount > 0 ? this.fittextElement.childElementCount : 1;
    this.lineHeight = this.computed['line-height'];
    this.display = this.computed['display'];
  }

  @HostListener('window:resize')
  public onWindowResize = (): void => {
    if (this.activateOnResize) {
      this.zoom();
    }
  };

  ngOnInit(): void {
    this.renderer.setStyle(this.fittextElement, 'visibility', 'hidden');
  }

  public ngAfterViewInit() {
    this.zoom();
  }

  private zoom(): void {

    this.resizeTimeout = setTimeout(
        (() => {
          if (this.fittextElement.offsetHeight * this.fittextElement.offsetWidth !== 0) {
            // reset to default
            this.setZoom(1);
            // set new
            this.setZoom(this.calculateZoom());
          }
        }).bind(this),
        this.delay
    );

  }

  private calculateZoom(): number {

    let w = this.fittextElement.scrollWidth;
    let ow = this.fittextElement.offsetWidth;

    if (w > ow) {
      return ow/w;
    }

    return 1;
  }

  private setZoom (zoom): void {
    this.renderer.setStyle(this.fittextElement, 'visibility', 'visible');
    this.renderer.setStyle(this.fittextElement, 'zoom', zoom);
    this.renderer.setStyle(this.fittextElement, 'transform', 'perspective(1px)');
    this.renderer.setStyle(this.fittextElement, 'backface-visibility', 'hidden');
  }

}