import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SoundService {

  click: any;

  constructor() {

    this.click = new Audio();
    this.click.src = environment.apiUrl + "/sound/click.mp3";
    this.click.load();
  }

  playClick() {
    this.click.play();
  }

}
