import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FacadeService} from "../../../../shared/services/facade.service";
import {ShiftGroupModel, uniqueGroupUser} from "../../../../shared/model/shift/shift_group";
import {ReactiveTextFieldComponent} from "../../../../core/components/reactive-text-field/reactive-text-field.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {GroupUserFieldComponent} from "../../components/group-user-field/group-user-field.component";

@Component({
  selector: 'app-shift-group-form',
  templateUrl: './shift-group-form.component.html',
  styleUrls: ['./shift-group-form.component.scss']
})
export class ShiftGroupFormComponent implements OnInit {

  shift_group: ShiftGroupModel;
  shiftGroupForm: FormGroup;
  config;
  formSubmitAttempt: boolean = false;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private facadeService: FacadeService,
      private formBuilder: FormBuilder ) { }

  ngOnInit() {
    this.route.data.subscribe((data: { shift_group: ShiftGroupModel }) => {
      if(data.shift_group) this.shift_group = data.shift_group;
      else this.router.navigate(['/shifts/groups']);
    });

    this.initForm();
  }

  initForm()
  {
    const me = this,
        shiftGroup = me.shift_group,
        config = [
          {
            component: ReactiveTextFieldComponent,
            type: 'control',
            label: 'Nome',
            key: 'name',
            placeholder: '',
            value: shiftGroup.name,
            disabled: false,
            validators: [ Validators.required ]
          },
          {
            component: GroupUserFieldComponent,
            type: 'array',
            label: 'Utenti',
            key: 'group_users',
            shift_group_id: shiftGroup.id,
            validators: [ uniqueGroupUser() ],
            value: shiftGroup.group_users,
          }
        ];

    me.shiftGroupForm = me.formBuilder.group({});
    config.forEach(control => {
      me.shiftGroupForm.addControl(
          control.key,
          this.createControl(control)
      )
    });

    me.config = config;
  }

  createControl(control)
  {
    switch(control.type) {
      case 'array':
        let array = this.formBuilder.array([], control.validators);
        for (let i in control.value) {
          array.push(this.formBuilder.group(control.value[i]));
        }
        return array;
      case 'control':
        return this.formBuilder.control({ value: control.value, disabled: control.disabled }, control.validators);
    }
  }


  onSubmit()
  {
    Object.assign(this.shift_group, this.shiftGroupForm.value);
    let fn;
    if(this.shift_group.id < 0) {
      fn = this.facadeService.shiftGroupService.addShiftGroup(this.shift_group);
    } else {
      fn = this.facadeService.shiftGroupService.updateShiftGroup(this.shift_group);
    }

    fn.subscribe(shift_group => {
      this.shift_group = shift_group;
      this.formSubmitAttempt = false;
      this.shiftGroupForm.patchValue(shift_group);
      this.shiftGroupForm.updateValueAndValidity();
      this.shiftGroupForm.markAsPristine();
    });
  }

  save()
  {
    if (this.shiftGroupForm.valid) {
      if (this.shiftGroupForm.dirty) {
        this.onSubmit();
      }
    } else {
      this.formSubmitAttempt = true;
    }
  }


  remove()
  {
    this.facadeService.shiftGroupService.deleteShiftGroup(this.shift_group)
        .subscribe(() => this.router.navigate(['/shifts/groups']));
  }
}

