import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
    selector: '[appScrollTracker]'
})

export class ScrollerDirective {

@HostListener('scroll', ['$event'])

    onScroll(event) {
        // do tracking
        // console.log('scrolled', event.target.scrollTop);
        // Listen to click events in the component
        const tracker = event.target;

        const limit = tracker.scrollHeight - tracker.clientHeight;
        console.log(event.target.scrollTop, limit);
        if (event.target.scrollTop === limit) {
            alert('end reached');
        }
    }

    constructor(private el: ElementRef) {  }
}