import { Injectable } from '@angular/core';
import {BehaviorSubject, of} from "rxjs";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class StateService {

  private state = {};

  private data = {};

  constructor(private router: Router) { }

  getState()
  {
    let stateId = this.router.url;
    if (!this.state[stateId]) {
      this.initState(stateId);
    }
    return this.state[stateId].asObservable();
  }

  applyState(state)
  {
    let stateId = this.router.url;
    if (!this.state[stateId]) {
      this.initState(stateId);
    }

    this.data[stateId] = Object.assign({}, this.data[stateId], state);
    this.state[stateId].next(this.data[stateId]);
  }

  initState(stateId) {
    this.data[stateId] = {};
    this.state[stateId] = new BehaviorSubject<{}>({});
  }
}
