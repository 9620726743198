import { Component, OnInit } from '@angular/core';
import {DialogConfig} from "../../../../core/components/dialog/dialog-config";
import {DialogRef} from "../../../../core/components/dialog/dialog-ref";

@Component({
  selector: 'app-search-order-request-dialog',
  templateUrl: './search-order-request-dialog.component.html',
  styleUrls: ['./search-order-request-dialog.component.scss']
})
export class SearchOrderRequestDialogComponent implements OnInit {

  public search: any;

  constructor(public config: DialogConfig, public dialog: DialogRef) {}

  onClose() {
    this.dialog.close({});
  }

  ngOnInit() {
    this.search = this.config.data.searchFilter ? Object.assign({}, this.config.data.searchFilter) : {
      number: null,
      reference: null,
      client_name: null
    };
  }

  onOk() {
    this.dialog.close({ searchFilter: this.search } );
  }
}
