import { Component, OnInit } from '@angular/core';
import {FacadeService} from "../../../../shared/services/facade.service";
import {Router} from "@angular/router";
import {DialogService} from "../../../../core/components/dialog/dialog.service";
import {ShiftGroupModel} from "../../../../shared/model/shift/shift_group";

@Component({
  selector: 'app-shift-group-list',
  templateUrl: './shift-group-list.component.html',
  styleUrls: ['./shift-group-list.component.scss']
})
export class ShiftGroupListComponent implements OnInit {

  public shift_groups: ShiftGroupModel[];
  public loggedUserId: number;

  public isLoaded: boolean = false;
  public isSuperuser: boolean = false;

  constructor(private facadeService: FacadeService, private router: Router, public dialog: DialogService) { }

  ngOnInit() {
    this.isSuperuser =  (this.facadeService.authService.isSuperuser() || this.facadeService.authService.isAdmin()) && this.facadeService.authService.isEnabled();
    this.loggedUserId = this.facadeService.authService.getUserId();
    this.getShiftGroups();
  }

  getShiftGroups(): void {

    this.facadeService.shiftGroupService.loadShiftGroups()
        .subscribe(shift_groups => {
          this.shift_groups = shift_groups;
          this.isLoaded = true;
          this.scrollTop();
        });
  }


  scrollTop(): void {
    const section = document.querySelector('section.shift-page');
    if (section) {
      section.scrollIntoView();
    }
  }

  onCheckboxClick(shiftGroup, e)
  {
      let groupUser = shiftGroup.group_users.filter(groupUser => groupUser.user_id == this.loggedUserId);

      e.preventDefault();
      e.stopPropagation();

      if (groupUser.length) {
        groupUser = groupUser[0];
        this.facadeService.shiftGroupService.deleteGroupUser(shiftGroup, groupUser)
            .subscribe(success => {
              shiftGroup.group_users = shiftGroup.group_users.filter(shiftGroupUser => groupUser.id != shiftGroupUser.id )
            });

      } else {

        this.facadeService.shiftGroupService.addGroupUser(shiftGroup, { id: -1, user_id: this.loggedUserId, shift_group_id: shiftGroup.id })
            .subscribe(groupUser => {
              shiftGroup.group_users.push(groupUser);
            });
      }
  }

    isChecked(shiftGroup)
    {
        let groupUsers = shiftGroup.group_users.filter((groupUser) => groupUser.user_id == this.facadeService.authService.getUserId());
        return !!groupUsers.length;
    }

  add(): void {
      this.router.navigate(['/shifts/groups/-1']);
  }

}
