import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {EventsRoutingModule} from './events.routing';

import { EventsComponent } from './events.component';
import { EventListComponent } from './pages/event-list/event-list.component';
import { EventDetailComponent } from './pages/event-detail/event-detail.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivityFormComponent } from './components/activity-form/activity-form.component';
import { CoreModule } from '../../core/core.module';
import { EventResolve } from './resolvers/event.resolver';
import { ReferentFormComponent } from './components/referent-form/referent-form.component';
import { DetailFormComponent } from './components/detail-form/detail-form.component';
import { AdvancedFormComponent } from './components/advanced-form/advanced-form.component';
import { EventPageComponent } from './pages/event-page/event-page.component';
import { GroupActivitiesByDatePipe } from './pipes/group-activities-by-date.pipe';
import { FilterDialogComponent } from './components/filter-dialog/filter-dialog.component';
import { PartecipantFormComponent } from './pages/partecipant-form/partecipant-form.component';
import {PartecipantViewComponent} from "./pages/partecipant-view/partecipant-view.component";
import {DetailViewComponent} from "./pages/detail-view/detail-view.component";
import {ReferentViewComponent} from "./pages/referent-view/referent-view.component";
import {DynamicControlDirective} from "../../core/directive/dynamic-control.directive";
import {ReactiveTextFieldComponent} from "../../core/components/reactive-text-field/reactive-text-field.component";
import { ActivityFieldComponent } from './components/activity-field/activity-field.component';
import { SearchPartecipantBarComponent } from './components/search-partecipant-bar/search-partecipant-bar.component';
import { FilterPartecipantByNamePipe } from './pipes/filter-partecipant-by-name.pipe';
import { RegisterPaymentDialogComponent } from './components/register-payment-dialog/register-payment-dialog.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        EventsRoutingModule,
        CoreModule,
    ],
    declarations: [
        EventsComponent,
        EventListComponent,
        EventDetailComponent,
        ActivityFormComponent,
        ReferentFormComponent,
        DetailFormComponent,
        AdvancedFormComponent,
        EventPageComponent,
        GroupActivitiesByDatePipe,
        FilterDialogComponent,
        PartecipantFormComponent,
        PartecipantViewComponent,
        DetailViewComponent,
        ReferentViewComponent,
        ActivityFieldComponent,
        SearchPartecipantBarComponent,
        FilterPartecipantByNamePipe,
        RegisterPaymentDialogComponent,
    ],
    providers: [
        EventResolve,
    ],
    entryComponents: [
        FilterDialogComponent,
        ReactiveTextFieldComponent,
        ActivityFieldComponent,
        RegisterPaymentDialogComponent
    ]
})
export class EventsModule { }