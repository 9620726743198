import { Injectable } from '@angular/core';

import {BehaviorSubject, Observable, of} from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { AccountModel } from '../../model/account';
import {HttpService} from "../http.service";

@Injectable({
    providedIn: 'root'
})
export class AccountService {

    private accountsUrl = '/accounts';  // URL to web api

    private accounts: BehaviorSubject<AccountModel[]> = new BehaviorSubject<AccountModel[]>([]);
    private account: BehaviorSubject<AccountModel> = new BehaviorSubject<AccountModel>({} as AccountModel);

    private dataStore: {
        accounts: AccountModel[]
    } = { accounts: []};


    constructor(private http: HttpService) { }

    /** GET heroes from the server */
    getAccounts (): Observable<AccountModel[]> {

        return this.http.get('loadAccounts', this.accountsUrl).pipe(
            tap(accounts => this.log(`fetched accounts`)),
            catchError(this.handleError('getAccounts', []))
        );

    }

    getAccount(id) {
        return id < 0
            ? of({ id: -1, name: '' } as AccountModel)
            : this.loadAccount(id);
    }

    loadAccount(account: AccountModel | number) {

        const id = typeof account === 'number' ? account : account.id;
        const url = `${this.accountsUrl}/${id}`;

        return this.http.get('loadAccount', url).pipe(
            map((account: AccountModel) => {
                const index = this.dataStore.accounts.findIndex((a) => a.id == account.id);

                if (index > -1) {
                    this.dataStore.accounts[index] = account;
                } else {
                    this.dataStore.accounts.push(account);
                }

                this.account.next(account);
                this.accounts.next(this.dataStore.accounts);

                return account;
            }),
            tap(_ => this.log(`fetched account id=${id}`)),
            catchError(this.handleError<AccountModel>(`getAccount id=${id}`))
        );
    }


    addAccount (account: AccountModel): Observable<AccountModel> {

        return this.http.post('addAccount', this.accountsUrl, account).pipe(
            tap((account: AccountModel) => this.log(`added account w/ id=${account.id}`)),
            catchError(this.handleError<AccountModel>('addAccount'))
        );

    }


    readPrivacy() {
        return this.http.get('readPrivacy', '/account/account-privacy');
    }

    updateAccount(account: AccountModel): Observable<any> {

        const id = account.id;
        const url = `${this.accountsUrl}/${id}`;

        return this.http.post('updateAccount', url, account).pipe(
            map((account: AccountModel) => {
                const index = this.dataStore.accounts.findIndex((a) => a.id == account.id);

                if (index > -1) {
                    this.dataStore.accounts[index] = account;
                } else {
                    this.dataStore.accounts.push(account);
                }

                this.account.next(account);
                this.accounts.next(this.dataStore.accounts);

                return account;
            }),
            tap(_ => this.log(`updated account id=${account.id}`)),
            catchError(this.handleError<any>('updateAccount'))
        );
    }
  

    /**
    * Handle Http operation that failed.
    * Let the app continue.
    * @param operation - name of the operation that failed
    * @param result - optional value to return as the observable result
    */
    private handleError<T> (operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    /** Log a HeroService message with the MessageService */
    private log(message: string) {
        console.log('AccountService: ' + message);
    }

}

