import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR} from "@angular/forms";


@Component({
    selector: 'app-reactive-text-field',
    templateUrl: './reactive-text-field.component.html',
    styleUrls: ['./reactive-text-field.component.scss'],

    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ReactiveTextFieldComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ReactiveTextFieldComponent),
            multi: true
        }
    ]
})

export class ReactiveTextFieldComponent implements OnInit, ControlValueAccessor {

    @Input() config;
    @Input() group;
    @Input() formSubmitAttempt;

    value: string;
    formControl;

    constructor() { }

    ngOnInit() {
        this.formControl = this.group.get(this.config.key);
    }

    isValid()
    {
        return !this.formControl.invalid || !(
            (!this.formControl.valid && this.formControl.touched) ||
            (this.formControl.untouched && this.formSubmitAttempt)
        );
    }

    changeValue(v)
    {
        this.value = v;
        this.propagateChange(v);
    }

    writeValue() {
    }

    validate() {

    }

    propagateChange: any = () => {};
    validateFn: any = () => {};

    //From ControlValueAccessor interface
    registerOnChange(fn: any) {
        //this.onChangeCallback = fn;
        this.propagateChange = fn;
    }

    //From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        //this.onTouchedCallback = fn;
    }

}
