import {OrderRequestModel} from "./order-request";
import {MenuItemModel} from "./menu-item";
import {AbstractControl, ValidatorFn} from "@angular/forms";

export class PurchaseOrderModel
{
    id: number;
    name: string;
    date: Date;

    menu_items: MenuItemModel[];
    order_requests: OrderRequestModel[];

}

export function uniqueMenuItem(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
        let uniq = control.value
            .map((menuItem) => {
                return {
                    count: 1,
                    name: menuItem.name.toLowerCase()
                }
            })
            .reduce((a, b) => {
                a[b.name] = (a[b.name] || 0) + b.count;
                return a
            }, {});

        let duplicates = Object.keys(uniq).filter((a) => { return uniq[a] > 1 });
        return duplicates.length ? {'duplicateValues': duplicates} : null;
    };
}