import { Component, OnInit, Input, Output, EventEmitter, ViewChild, HostListener, ElementRef } from '@angular/core';
import {HttpService} from "../../../shared/services/http.service";

@Component({
  selector: 'app-user-autocomplete',
  templateUrl: './user-autocomplete.component.html',
  styleUrls: ['./user-autocomplete.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class UserAutocompleteComponent implements OnInit {

  @Input() label: string = 'Nome';
  @Input() set term(term) {
    this.autocomplete.term = term;
  }
  @Input() placeholder: string = null;
  @Input() allowFreeText: boolean = false;
  @Input() url: string = '/users/search';
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('autocompleteContainer', { static: false }) autocompleteContainer:ElementRef;


  public autocomplete = {
    term: '',
    selected: null
  };

  private debounceTimer;
  private minQueryLength = 2;
  public resultsData: { name: string, id?: number, picture?: string; is_group?: boolean; email?: string, phone?: string; }[] = [];
  public resultsHidden: boolean = true;

  constructor(private http: HttpService) { }

  ngOnInit() {
  }

  onClick(event) {
    if (this.autocompleteContainer && !this.autocompleteContainer.nativeElement.contains(event.target)) {
      this.hideResultsContainer(false);
    }
  }
  

  onChange(changes) {
    let me = this;
    me.resetFields();
    me.debounce(function () {
      me.search();
    }, 200)();

  }

  debounce (fn, delay) {
    let me = this;
    return function () {
        var context = this, args = arguments;
        clearTimeout(me.debounceTimer);
        me.debounceTimer = setTimeout(function () {
            fn.apply(context, args);
      }, delay);
    };
  }

  doRequest(params) {
    return this.http.get('searchUser', this.url, params)
    .pipe();
  }


  search() {

    let me = this,
    term = me.autocomplete.term.trim(),
    params = {
        term: term,
        limit: 5
    };

    if (params.term.length >= me.minQueryLength) {
  
      me.doRequest(params).subscribe((results) => {

        me.parseResponse(results);

      });

    } else {
      me.resetFields();
      me.hideResultsContainer(true);
    }
  }

  parseResponse (users) {
    let me = this,
        rows = [];

    if (this.allowFreeText && users.length == 0) {
      users.push({
        name: me.autocomplete.term
      });
    }

    me.resultsData = users;
    me.resultsHidden = false;
  }

  resetFields () {

    // me.field.val(me.query);
    // me.field.data('id', null);

    this.autocomplete.selected = null;
    this.onSelect.emit(this.autocomplete);
  }

  hideResultsContainer (empty) {
    var me = this;
    // selectedItem = me.resultsContainer.find('.item.selected');
    me.resultsHidden = true;
    // if (selectedItem.exists()) {
    //     selectedItem.removeClass('selected');
    // }

    if (empty) {
        me.resultsData = [];
    }
  }

  onItemClick(result)
  {
    this.autocomplete.selected = result;
    this.autocomplete.term = result.name;
    this.hideResultsContainer(false);
    this.onSelect.emit(this.autocomplete);
  }
}
