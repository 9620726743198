import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';

@Injectable()
export class AlertService {

  private message: BehaviorSubject<{success: boolean, text: string}> = new BehaviorSubject<{success: boolean, text: string}>({} as {success: boolean, text: string});

  constructor() {}

  getMessage(): Observable<{success: boolean, text: string}> {
    return this.message.asObservable();
  }
  setMessage(message) {
    this.message.next(message);
  }

}