import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import { EventListComponent } from './pages/event-list/event-list.component';
import { EventsComponent } from './events.component';
import { EventDetailComponent } from './pages/event-detail/event-detail.component';
import { EventResolve } from './resolvers/event.resolver';
import {EventPageComponent} from "./pages/event-page/event-page.component";
import {SuperuserGuard} from "../../shared/guard/superuser.guard";
import {PartecipantFormComponent} from "./pages/partecipant-form/partecipant-form.component";
import {AuthGuard} from "../../shared/guard/auth.guard";
import {ReferentViewComponent} from "./pages/referent-view/referent-view.component";
import {DetailViewComponent} from "./pages/detail-view/detail-view.component";
import {PartecipantViewComponent} from "./pages/partecipant-view/partecipant-view.component";


const eventsRoutes: Routes = [
    {
        path: '',
        component: EventsComponent,
        children: [
            {
                path: '',
                component: EventPageComponent,
                canActivate: [ AuthGuard ]
            },
            {
                path: 'edit',
                component: EventListComponent,
                canActivate: [ SuperuserGuard ]
            },
            {
                path: 'detail/:id',
                component: EventDetailComponent,
                canActivate: [ SuperuserGuard ],
                resolve: {
                    event: EventResolve
                }
            },
            {
                path: ':id/partecipate',
                component: PartecipantFormComponent,
                canActivate: [ AuthGuard ],
                resolve: {
                    event: EventResolve
                }
            },
            {
                path: ':id/referents',
                component: ReferentViewComponent,
                canActivate: [ AuthGuard ],
                resolve: {
                    event: EventResolve
                }
            },
            {
                path: ':id/details',
                component: DetailViewComponent,
                canActivate: [ AuthGuard ],
                resolve: {
                    event: EventResolve
                }
            },
            {
                path: ':id/partecipants',
                component: PartecipantViewComponent,
                canActivate: [ SuperuserGuard ],
                resolve: {
                    event: EventResolve
                }
            }
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(eventsRoutes)
    ],
    exports: [
        RouterModule
    ]
})

export class EventsRoutingModule {
}