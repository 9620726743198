import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-datetime-select',
  templateUrl: './datetime-select.component.html',
  styleUrls: ['./datetime-select.component.scss']
})
export class DatetimeSelectComponent implements OnInit {

  @Input() _value: Date;

  @Input() set value(value: Date) {
    
    this._value = value;
    this.date = value ? moment(value).tz('Europe/Rome') : moment().tz('Europe/Rome');

    this.day = this.date.date();
    this.month = this.months.filter((m) => m.value == this.date.month()+1)[0];
    this.year = this.date.year(); 

    this.buildDays();
  }
 
  

  get value(): Date {
     return this._value;
  }

  @Input() type: string = 'datetime';
  @Input() dateLabel: string = 'Data';
  @Input() timeLabel: string = 'Ora';
  @Input() disabled: boolean = false;
  @Input() set yearFrom(year: number) {
    this.startYear = year;
    this.buildYears();
  };
  @Input() yearTo(year: number) {
    this.finishYear = year;
    this.buildYears();
  };
  @Output() onChange: EventEmitter<Date> = new EventEmitter();

  private days: Array<string>;
  private months: Array<any>;
  private years: Array<number>;
  private startYear: number;
  private finishYear: number;

  private day: number;
  private year: number;
  private month: any;
  private hour: string;
  private minute: string;

  private date: any;

  constructor() { 

    this.buildYears();

    this.months = [
      { value: 1, name: 'Gen', days: 31 },
      { value: 2, name: 'Feb', days: 28 },
      { value: 3, name: 'Mar', days: 31 },
      { value: 4, name: 'Apr', days: 30 },
      { value: 5, name: 'Mag', days: 31 },
      { value: 6, name: 'Giu', days: 30 },
      { value: 7, name: 'Lug', days: 31 },
      { value: 8, name: 'Ago', days: 31 },
      { value: 9, name: 'Set', days: 30 },
      { value: 10, name: 'Ott', days: 31 },
      { value: 11, name: 'Nov', days: 30 },
      { value: 12, name: 'Dic', days: 31 },
    ];

    this.buildDays();

  }
  
  buildYears() {

    let year = moment().year();
    this.startYear = this.startYear || year;
    this.finishYear = this.finishYear || year + 1;

    this.years = [];
    for (let i = this.startYear; i <= this.finishYear; i++) {
      this.years.push(i);
    }
  }

  ngOnInit() { }

  buildDays() {

    let max = this.month ? this.month.days : 31;

    if (this.month && this.month.value == 2 && this.isBissextile()) {
      max = 29;
    }

    this.days = [];
    for (let i = 1; i <= max; i++) {
      this.days.push(('0'+i).substr(-2));
    }
  }

  isBissextile()
  {
    return ((this.year % 4 == 0 && this.year % 100 != 0) || this.year % 400 == 0);
  }

  showDate()
  {
    return this.type == 'datetime' || this.type == 'date';
  }

  showTime()
  {
    return this.type == 'datetime' || this.type == 'time';
  }

  changeDate(type, e) {
    
    let date = this.date;

    switch(type) {
      case 'd': 
        date.date(e); 
        break;
      case 'm': 
        this.buildDays();
        if (date.date() > this.month.days) {
          date.date(this.month.days);
        }
        date.month(e-1); 
        break;
      case 'y':
        date.year(e); 
        break;
      case 'h': 
        this.hour = e;
        date.hours(e); 
        break;
      case 'i': 
        this.minute = e;
        date.minutes(e); 
        break;
    }

    if (date.minutes()%5 != 0) {
      date.minutes(date.minutes() + 5 - date.minutes()%5);
    }

    date.seconds(0);

    this._value = date.format();
    this.onChange.emit(this._value);
  }
}
