import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountModel } from '../../../../shared/model/account';
import { AuthService } from '../../../../shared/services/auth.service';
import { environment } from '../../../../../environments/environment';
import {FacadeService} from "../../../../shared/services/facade.service";

@Component({
  selector: 'app-account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.scss']
})
export class AccountListComponent implements OnInit {

  public isLoaded: boolean = false;
  public accounts: AccountModel[];
  public isPlacti: boolean = false;
  public currentAccount: AccountModel = {} as AccountModel;

  constructor(private facadeService: FacadeService, private authService: AuthService, private router: Router) { }
  
  ngOnInit() {
    this.getAccounts();
    this.isPlacti = this.facadeService.getCurrentAccount()['is_placti'];
  }

  getAccounts(): void {
    this.facadeService.accountService.getAccounts()
    .subscribe(accounts => {
      this.accounts = accounts;
      this.isLoaded = true;
    });
  }

  add(): void {
    this.router.navigate(['/account/edit/-1']);
  }

  edit(account) {
    this.router.navigate(["/account/edit/" + account.id ]);
  }

  getAccountLogo(account) {
    return environment.apiUrl + account.logo + '?v=' + environment.version;
  }

}
