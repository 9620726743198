import {NgModule, APP_INITIALIZER, LOCALE_ID, ErrorHandler, Injectable} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {APP_CONFIG, AppConfig} from './config/app.config';

import {AppRoutingModule} from './app.routing';
import {CoreModule} from './core/core.module';

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import { AuthService } from './shared/services/auth.service';
import { AuthGuard } from './shared/guard/auth.guard';
import { RequestInterceptor } from './shared/interceptor/auth.interceptor';
import { Router, Route } from '@angular/router';
import { AccountGuard } from './shared/guard/account.guard';
import { LoadingService } from './shared/services/loading.service';

import {CommonModule, registerLocaleData} from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { PlactiGuard } from './shared/guard/placti.guard';
import { SuperuserGuard } from './shared/guard/superuser.guard';
import { AdminGuard } from './shared/guard/admin.guard';
import { LoggedGuard } from './shared/guard/logged.guard';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {ScrollerDirective} from "./shared/directive/scroller.directive";
import {RoutingStateService} from "./shared/services/routingstate.service";
import {DialogService} from "./core/components/dialog/dialog.service";
import {ShiftsModule} from "./modules/shifts/shifts.module";
import {EchoService} from "./shared/services/echo.service";
import {FacadeService} from "./shared/services/facade.service";
import {AlertService} from "./shared/services/alert.service";
import {HttpService} from "./shared/services/http.service";
import * as Sentry from "@sentry/browser";
import { EventsModule } from "./modules/events/events.module";
import { initAccount } from "./shared/services/facade.service";
import { ZoomDirective } from './core/directive/zoom.directive';
import {PurchaseOrdersModule} from "./modules/purchase-orders/purchase-orders.module";
import {AccountModule} from "./modules/account/account.module";
import { MarkdownModule } from 'ngx-markdown';


registerLocaleData(localeIt);

// Sentry.init({
//     dsn: "https://a3aed982cebe4422abd88c52c9cb9cf1@sentry.io/1409541"
// });
//
// @Injectable()
// export class SentryErrorHandler implements ErrorHandler {
//     constructor() {}
//     handleError(error) {
//         if (environment.production) {
//             Sentry.captureException(error.originalError || error);
//         }
//         throw error;
//     }
// }
//

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        CoreModule,
        AppRoutingModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        MarkdownModule.forRoot(),

        ShiftsModule, // provvisorio in attesa di capire come risolvere il problema dell'EntryComponent nei Moduli
        EventsModule, // provvisorio in attesa di capire come risolvere il problema dell'EntryComponent nei Moduli
        PurchaseOrdersModule,
        AccountModule,
    ],
    declarations: [
        AppComponent,
        ScrollerDirective
    ],
    providers: [
//        { provide: ErrorHandler, useClass: SentryErrorHandler },
        { provide: LOCALE_ID, useValue: "it" },
        { provide: APP_CONFIG, useValue: AppConfig },
        { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
        { provide: APP_INITIALIZER, useFactory: initAccount, deps: [FacadeService], multi: true },
        DialogService,
        AuthService,
        LoadingService,
        FacadeService,
        AlertService,
        EchoService,
        HttpService,
        RoutingStateService,
        AuthGuard,
        AccountGuard,
        PlactiGuard,
        SuperuserGuard,
        AdminGuard,
        LoggedGuard
    ],
    bootstrap: [
        AppComponent
    ]
})

export class AppModule {
}