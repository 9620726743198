import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountComponent } from './account.component';
import { AccountListComponent } from './pages/account-list/account-list.component';
import { AccountRoutingModule } from './account.routing';
import { CoreModule } from '../../core/core.module';
import { ReactiveFormsModule} from '@angular/forms';
import {AccountResolve} from "./resolvers/account.resolver";
import {AccountFormComponent} from "./pages/account-form/account-form.component";
import {ReactiveTextFieldComponent} from "../../core/components/reactive-text-field/reactive-text-field.component";
import {ReactiveCheckboxFieldComponent} from "../../core/components/reactive-checkbox-field/reactive-checkbox-field.component";

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        AccountRoutingModule,
        CoreModule
    ],
    declarations: [
        AccountComponent,
        AccountListComponent,
        AccountFormComponent
    ],
    providers: [
        AccountResolve
    ],
    entryComponents: [
        ReactiveTextFieldComponent,
        ReactiveCheckboxFieldComponent,
    ]
})
export class AccountModule { }
