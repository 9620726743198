import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  @Input() header: boolean = true;
  @Input() footer: boolean = true;

  public mainHeight = "100%";

  constructor() { }

  ngOnInit() {
    let margin = 0;
    if (this.header) margin += 45;
    if (this.footer) margin += 45;
    if (margin > 0) this.mainHeight = "calc(100% - " + margin + "px)";
  }

}
