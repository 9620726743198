import { Injectable } from '@angular/core';
import { UserModel } from '../model/user';
import { Observable, Subject, of, BehaviorSubject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { RoleModel } from '../model/role';
import {HttpService} from "./http.service";
import {HttpParams} from "@angular/common/http";
import {FacadeService} from "./facade.service";
import {AccountModel} from "../model/account";


@Injectable()
export class AuthService {

    public currentAccount: AccountModel;

    public user: BehaviorSubject<UserModel> = new BehaviorSubject<UserModel>({id: null} as UserModel);

    private role: RoleModel;

    private facebookOauthData;

    constructor(private http: HttpService) { }


    isAuth(): boolean {
        return this.user.getValue().id !== null;
    }

    isVerified(): boolean {
        return this.user.getValue().verified;
    }

    isEnabled(): boolean {
        if (this.user.getValue().roles) {
            return this.user.getValue().roles.filter((role) => role.account_id == this.currentAccount.id)[0].enabled;
        }
        return false;
    }

    isAdmin(): boolean {
        if (this.user.getValue().roles) {
            return this.user.getValue().roles.filter((role) => role.account_id == this.currentAccount.id)[0].authorization_level == 'admin';
        }
        return false;
    }

    isSuperuser(): boolean {
        if (this.user.getValue().roles) {
            return this.user.getValue().roles.filter((role) => role.account_id == this.currentAccount.id)[0].authorization_level == 'superuser';
        }
        return false;
    }

    isCollaborator(): boolean {
        if (this.user.getValue().roles) {
            return this.user.getValue().roles.filter((role) => role.account_id == this.currentAccount.id)[0].authorization_level == 'collaborator';
        }
        return false;
    }

    getUserId(): number {
        return this.user.getValue().id;
    }

    getUser(): Observable<UserModel> {
        return this.user.asObservable();
    }

    getGroups() {
        if (this.user.getValue().roles) {
            return this.user.getValue().roles.filter((role) => role.account_id == this.currentAccount.id)[0].groups;
        }
        return [];
    }


    getToken(): string | null {
        return localStorage.getItem('auth') || null;
    }

    setToken(token): void {
        if(token) {
            localStorage.setItem('auth', token);
        } else {
            localStorage.removeItem('auth');
        }
    }

    getDeviceUUID(): string | null {
        return localStorage.getItem('device') || null;
    }

    setDeviceUUID(): void {
        let chr4 = [], uuid;
        if (!this.getDeviceUUID()) {
            for (let i = 0; i < 8; i++) {
                chr4.push(Math.random().toString(16).slice(-4));
            }
            uuid = chr4[0] + chr4[1] + '-' + chr4[2] + '-' + chr4[3] + '-' + chr4[4] + '-' + chr4[5] + chr4[6] + chr4[7];
            localStorage.setItem('device', uuid);
        }
    }

    validate(token): Observable<boolean> {

        const url = `/auth/validate`;

        return this.http.post('validateUser', url, {validate_token: token}).pipe(
            map((loggedUser: UserModel) => {
                this.user.next(loggedUser);
                return true;
            }),
            catchError(() => of(false))
        );

    }

    signup(credentials): Observable<boolean> {

        const url = `/auth/signup`;

        return this.http.post('signupUser', url, credentials).pipe(
            map((user: UserModel) => {
                this.setToken(user.token);
                this.user.next(user);
                return true;
            }),
            catchError(() => of(false))
        );

    }

    forgotten(credentials): Observable<boolean> {

        const url = `/auth/forgotten`;

        return this.http.post('forgottenPassword', url, credentials).pipe(
            map((response: {success: boolean}) => {
                return response.success;
            }),
            catchError(() => of(false))
        );
    }

    recover(credentials): Observable<boolean> {

        const url = `/auth/recover`;

        return this.http.post('recoverPassword', url, credentials).pipe(
            map((user: UserModel) => {
                this.setToken(user.token);
                this.user.next(user);
                return true;
            }),
            catchError(() => of(false))
        );

    }

    setFacebookOauth() {
        const account = this.currentAccount,
            url = window.location.hash;
        let facebookOauth = null, accessToken, state;

        if (url.includes('#')) {
            const httpParams = new HttpParams({ fromString: url.split('#')[1] });
            accessToken = httpParams.get('access_token') || null;
            state = httpParams.get('state') || null;
        }

        if (state === account.domain && !!accessToken ) {
            facebookOauth = {authResponse: { accessToken: accessToken }};
        }

        this.facebookOauthData = facebookOauth;
    }

    getFacebookOauth() {
        const facebookOauth = this.facebookOauthData;
        this.facebookOauthData = null;
        return facebookOauth;
    }


    facebookLogin(credentials, force): Observable<boolean> {

        const url = `/auth/login-facebook`,
            authResponse = credentials.authResponse || {},
            grantedScopes = authResponse.grantedScopes || '',
            accessToken = authResponse.accessToken || null;

        if (credentials.status !== 'connected' && !force) { return of(false); }
        if (grantedScopes.indexOf('email') < 0 && !force) { return of(false); }

        return this.http.post('facebookLogin', url, { facebook_token: accessToken }).pipe(
            map((user: UserModel) => {
                this.setToken(user.token);
                this.user.next(user);
                return true;
            }),
            catchError(() => of(false))
        );

    }


    googleLogin(credentials): Observable<boolean> {

        const url = `/auth/login-google`;

        return this.http.post('googleLogin', url, credentials).pipe(
            map((user: UserModel) => {
                this.setToken(user.token);
                this.user.next(user);
                return true;
            }),
            catchError(() => of(false))
        );

    }


    login(credentials): Observable<boolean> {

        const url = `/auth/login`;

        return this.http.post('login', url, credentials).pipe(
            map((user: UserModel) => {
                this.setToken(user.token);
                this.user.next(user);
                return true;
            }),
            catchError(() => of(false))
        );
    }


    logout(): void {
        this.setToken(null);
        this.user.next({id: null} as UserModel);
    }

    loadProfile(): Observable<UserModel> {
        const id = this.getUserId();
        const url = `/users/${id}`;
        return this.http.get('loadProfile', url).pipe(
            map((user: UserModel) => {
                this.user.next(user);
                return user;
            }),
            tap(_ => this.log(`fetched profile`)),
            catchError(this.handleError<UserModel>(`getProfile`))
        );
    }

    /** PUT: update the hero on the server */
    updateProfile (user: UserModel): Observable<any> {

        const id = user.id;
        const url = `/users/${id}`;

        return this.http.post('updateProfile', url, user).pipe(
            map((updatedUser: UserModel) => {
                this.setToken(updatedUser.token);
                this.user.next(updatedUser);
                return updatedUser;
            }),
            tap(_ => this.log(`updated profile`)),
            catchError(this.handleError<any>('updateProfile'))
        );
    }


    addPushSubscriber(user: UserModel, sub) {

        const id = user.id;
        const url = `/users/${id}/push-subscribe`;

        return this.http.post('addPushSubscribe', url, { push_subscription: sub }).pipe(
            map((updatedUser: UserModel) => {
                this.setToken(updatedUser.token);
                this.user.next(updatedUser);
                return updatedUser;
            }),
            tap(_ => this.log(`push subscriber added`)),
            catchError(this.handleError<any>('addPushSubscriber'))
        );
    }


    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T> (operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    /** Log a HeroService message with the MessageService */
    private log(message: string) {
        console.log('AuthService: ' + message);
    }

}
