import {Component, OnInit, Input, Output, EventEmitter, Renderer2, ViewChild, ElementRef} from '@angular/core';
import { EventModel } from '../../../../shared/model/event';
import * as moment from 'moment-timezone';
import { ActivityModel } from '../../../../shared/model/actvity';
import {FacadeService} from "../../../../shared/services/facade.service";
import { DialogService } from '../../../../core/components/dialog/dialog.service';
import { RegisterPaymentDialogComponent } from '../../components/register-payment-dialog/register-payment-dialog.component';

@Component({
    selector: 'app-partecipant-view',
    templateUrl: './partecipant-view.component.html',
    styleUrls: ['./partecipant-view.component.scss']
})
export class PartecipantViewComponent implements OnInit {

    @ViewChild('linkdiv', { static: true }) linkdiv: ElementRef;

    public event: EventModel = {} as EventModel;
    public filter: any = { term: "" };
    public get Partecipants() {
        // return new Array(100).fill({full_name: 'Cerretani Luca', first_name: "Luca", last_name: "Cerretani", quantity: 5, payment: 0, note: "5 adulti e 8 bambini"});
        return this.event.partecipants.filter((p) => p.quantity > 0 || (p.quantity == 0 && p.payment > 0));
    }
    public eventDates: string;

    constructor(private facadeService: FacadeService, private renderer: Renderer2, private dialog: DialogService ) {}

    ngOnInit() {
        this.loadEvent();
    }

    loadEvent() {
        this.facadeService.eventService.getEvent().subscribe((event) => {

            this.event = event;

            let dates = [];
            this.event.activities.forEach((activity) => {
                if (activity.start_at) dates.push(moment(activity.start_at).format('DD/MM/YYYY'));
            });

            this.eventDates = dates.filter((item,index,self) => self.indexOf(item)==index).join(' - ');
        });
    }

    onFilter(filter) {
        this.filter = filter;
    }

    onPartecipantClick(partecipant) {
        const ref = this.dialog.open(RegisterPaymentDialogComponent, { data: { partecipant: partecipant }});
        ref.afterClosed.subscribe(result => {
            if (result && result.partecipant) {
                this.facadeService.eventService.updatePartecipantPayment(this.event.id, result.partecipant).subscribe(() => 
                    this.facadeService.eventService.loadEvent(this.event.id).subscribe()
                );
            }
        });    
    }

    export() {

        let me = this;

        this.facadeService.eventService.exportPartecipants(this.event).subscribe((data) => {

            let a = document.createElement('a');
            const byteString = atob(data.body);
            const arrayBuffer = new ArrayBuffer(byteString.length);
            const int8Array = new Uint8Array(arrayBuffer);
            for (let i = 0; i < byteString.length; i++) {
                int8Array[i] = byteString.charCodeAt(i);
            }
            const blob = new Blob([arrayBuffer],  { type: data.content_type });
            const url = URL.createObjectURL(blob);

            a.setAttribute('href', url);
            a.setAttribute('download', data.file_name);

            me.renderer.appendChild(me.linkdiv.nativeElement, a);

            a.click();
            setTimeout(() => {
                URL.revokeObjectURL(url);
                a.remove();
            }, 1000);

        });
    }


    getActivityType(activity: ActivityModel): string {
        let activityIcons = {
            'food': 'fas fa-utensils',
            'drink': 'fas fa-glass-martini',
            'music': 'fas fa-music',
            'assembly': 'fas fa-gavel',
            'sport': 'fas fa-futbol',
            'other': 'far fa-calendar',
        };

        return activityIcons[activity.type];
    }

}
