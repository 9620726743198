export class ReferentModel
{
    id: number;
    full_name: string;
    event_id: number;
    user_id: number;
    picture: string | any;
    email: string;
    tel: string;
}
