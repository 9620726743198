import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AppConfig} from './config/app.config';
import { Error404Component } from './core/pages/error404/error404.component';
import { AuthGuard } from './shared/guard/auth.guard';
import { AccountGuard } from './shared/guard/account.guard';
import { PlactiGuard } from './shared/guard/placti.guard';
import { AdminGuard } from './shared/guard/admin.guard';
import { SuperuserGuard } from './shared/guard/superuser.guard';

const routes: Routes = [
    {
        path: AppConfig.routes.error404,
        component: Error404Component
    },
    {
        path: '',
        redirectTo: '/',
        pathMatch: 'full'
    },
    {
        path: 'login',
        redirectTo: '/auth',
        pathMatch: 'full'
    },
    {
        path: AppConfig.routes.auth,
        loadChildren: 'app/modules/auth/auth.module#AuthModule',
        canLoad: [ AccountGuard ]
    },
    {
        path: AppConfig.routes.notifications,
        loadChildren: 'app/modules/notifications/notifications.module#NotificationsModule',
        canLoad: [ AccountGuard ]
    },
    {
        path: AppConfig.routes.account,
        loadChildren: 'app/modules/account/account.module#AccountModule',
        canLoad: [ AccountGuard, AuthGuard ],
        canActivate: [ AuthGuard, AdminGuard ]
    },
    {
        path: AppConfig.routes.users,
        loadChildren: 'app/modules/users/users.module#UsersModule',
        canLoad: [ AccountGuard, AuthGuard, PlactiGuard ],
        canActivate: [ AuthGuard, AdminGuard ]
    },
    {
        path: AppConfig.routes.events,
        loadChildren: 'app/modules/events/events.module#EventsModule',
        canLoad: [ AccountGuard, AuthGuard, PlactiGuard ]
    },
    {
        path: AppConfig.routes.shifts,
        loadChildren: 'app/modules/shifts/shifts.module#ShiftsModule',
        canLoad: [ AccountGuard, AuthGuard, PlactiGuard ],
        canActivate: [ AuthGuard ]
    },
    {
        path: AppConfig.routes.messages,
        loadChildren: 'app/modules/messages/messages.module#MessagesModule',
        canLoad: [ AccountGuard, AuthGuard, PlactiGuard ],
        canActivate: [ AuthGuard ]
    },
    {
        path: AppConfig.routes["purchase-orders"],
        loadChildren: 'app/modules/purchase-orders/purchase-orders.module#PurchaseOrdersModule',
        canLoad: [ AccountGuard, AuthGuard, PlactiGuard ],
        canActivate: [ AuthGuard ]
    },
    {
        path: AppConfig.routes.scheduler,
        loadChildren: 'app/modules/scheduler/scheduler.module#SchedulerModule',
        canLoad: [ AccountGuard, AuthGuard, PlactiGuard ] ,
        canActivate: [ AuthGuard ]
    },
    {
        path: '**',
        redirectTo: '/' + AppConfig.routes.error404
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ]
})

export class AppRoutingModule {
}