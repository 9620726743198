import { Component, OnInit } from '@angular/core';
import {PurchaseOrderModel} from "../../../../shared/model/purchase-orders/purchase-order";
import {FacadeService} from "../../../../shared/services/facade.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DialogService} from "../../../../core/components/dialog/dialog.service";
import {OrderRequestStatModel} from "../../../../shared/model/purchase-orders/order-request-stat";
import {StateService} from "../../../../shared/services/state.service";

@Component({
  selector: 'app-order-request-chart',
  templateUrl: './order-request-chart.component.html',
  styleUrls: ['./order-request-chart.component.scss']
})
export class OrderRequestChartComponent implements OnInit {

  public purchase_order: PurchaseOrderModel;

  public order_request_stats: OrderRequestStatModel[] = [];

  public state: { view: string, hidden: any } = {
    hidden: [],
    view: 'visible',
  };

  constructor(private facadeService: FacadeService, private stateService: StateService, private router: Router, public dialog: DialogService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data.subscribe((data: { data: {order_request_stats: OrderRequestStatModel[], purchase_order: PurchaseOrderModel }} ) => {
      if (data.data) {
        this.order_request_stats = data.data.order_request_stats;
        this.purchase_order = data.data.purchase_order;
      }
      else this.router.navigate(['/purchase-orders']);
    });

    this.stateService.getState().subscribe((state) => {
      this.state = Object.assign({}, this.state, state);
    });

    this.facadeService.purchaseOrderService.getOrderRequestStats().subscribe((order_request_stats) => this.order_request_stats = order_request_stats);
  }

  isHidden(orderRequestStat) {
    return this.state.hidden.findIndex((v) => v == orderRequestStat.name.toLowerCase()) > -1;
  }

  onChangeView(view)
  {
    this.stateService.applyState({ view: view });
  }

  onHide(orderRequestStat)
  {
    const hidden = this.state.hidden;
    hidden.push(orderRequestStat.name.toLowerCase());
    this.stateService.applyState({ hidden: hidden.slice() });
  }

  onShow(orderRequestStat)
  {
    const hidden = this.state.hidden,
        index = hidden.findIndex((v) => v == orderRequestStat.name.toLowerCase());
    hidden.splice(index, 1);
    this.stateService.applyState({ hidden: hidden.slice() })
  }


  refresh()
  {
    this.facadeService.purchaseOrderService.loadOrderRequestStats(this.purchase_order).subscribe();
  }
}
