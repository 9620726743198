import {Component, Input, OnInit} from '@angular/core';
import {ControlValueAccessor} from "@angular/forms";
import {ActivityModel} from "../../../../shared/model/actvity";
import {EventModel} from "../../../../shared/model/event";
import {FacadeService} from "../../../../shared/services/facade.service";

@Component({
  selector: 'app-activity-field',
  templateUrl: './activity-field.component.html',
  styleUrls: ['./activity-field.component.scss']
})
export class ActivityFieldComponent implements OnInit, ControlValueAccessor {

  constructor(private facadeService: FacadeService) { }

  @Input() config;
  @Input() group;

  value: string;

  event: EventModel;
  currentActivity: ActivityModel;

  ngOnInit() {
    this.event = this.config.event;
  }

  addActivity()
  {
    this.facadeService.eventService.addActivity(this.event, {} as ActivityModel)
        .subscribe(activity => {
          this.event.activities.push(activity);
          this.currentActivity = activity;
          this.changeValue(this.event.activities.length);
        });
  }

  onActivityUpdate(activity: ActivityModel) {
    let index = this.event.activities.findIndex((a) => a.id == activity.id);
    this.event.activities[index] = activity;
    this.currentActivity = {} as ActivityModel;
  }

  onActivityClose() {
    this.currentActivity = {} as ActivityModel;
  }

  editActivity(activity: ActivityModel)
  {
    this.currentActivity = activity;
  }

  removeActivity(activity: ActivityModel)
  {
    this.facadeService.eventService.deleteActivity(this.event, activity)
        .subscribe(success => {
          this.event.activities = this.event.activities.filter(a => a.id != activity.id);
          this.changeValue(this.event.activities.length);
        });
  }

  getActivityType(activity: ActivityModel): string {
    let activityIcons = {
      'food': 'fas fa-utensils',
      'drink': 'fas fa-glass-martini',
      'music': 'fas fa-music',
      'assembly': 'fas fa-gavel',
      'sport': 'fas fa-futbol',
      'other': 'far fa-calendar',
    };

    return activityIcons[activity.type];
  }


  changeValue(v)
  {
    this.value = v;
    this.propagateChange(v);
  }

  writeValue() {
  }

  validate() {
  }

  propagateChange: any = (v) => {
    const control = this.group.get(this.config.key);
    control.setValue(v);
    control.updateValueAndValidity();
  };
  validateFn: any = () => {};

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    //this.onChangeCallback = fn;
    this.propagateChange = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    //this.onTouchedCallback = fn;
  }

}