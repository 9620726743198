import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {
    Router, Resolve,
    ActivatedRouteSnapshot
} from '@angular/router';
import {FacadeService} from "../../../shared/services/facade.service";
import {PurchaseOrderModel} from "../../../shared/model/purchase-orders/purchase-order";


@Injectable()
export class PurchaseOrderResolver implements Resolve<PurchaseOrderModel> {
    constructor(private facadeService: FacadeService, private router: Router) { }
    
    resolve(route: ActivatedRouteSnapshot): Observable<PurchaseOrderModel> {
        let id = +route.params['id'];
        return this.facadeService.purchaseOrderService.getPurchaseOrder(id).pipe(
            map(purchase_order => purchase_order)
        );
    }
}