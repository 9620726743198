import { Component, OnInit } from '@angular/core';
import { Location } from "@angular/common";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {FacadeService} from "../../../../shared/services/facade.service";
import {ReactiveTextFieldComponent} from "../../../../core/components/reactive-text-field/reactive-text-field.component";
import {PurchaseOrderModel} from "../../../../shared/model/purchase-orders/purchase-order";
import {ReactiveDatetimeFieldComponent} from "../../../../core/components/reactive-datetime-field/reactive-datetime-field.component";
import {uniqueMenuItem} from "../../../../shared/model/purchase-orders/purchase-order";
import {AlertDialogComponent} from "../../../../core/components/alert-dialog/alert-dialog.component";
import {DialogService} from "../../../../core/components/dialog/dialog.service";
import {MenuItemFieldComponent} from "../../components/menu-item-field/menu-item-field.component";

@Component({
  selector: 'app-purchase-order-form',
  templateUrl: './purchase-order-form.component.html',
  styleUrls: ['./purchase-order-form.component.scss']
})
export class PurchaseOrderFormComponent implements OnInit {

  purchase_order: PurchaseOrderModel;
  purchaseOrderForm: FormGroup;
  config;
  formSubmitAttempt: boolean = false;

  activeCard: string = 'settings';

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private facadeService: FacadeService,
      private formBuilder: FormBuilder,
      private location: Location,
      public dialog: DialogService
  ) { }

  ngOnInit() {
    this.route.data.subscribe((data: { purchase_order: PurchaseOrderModel }) => {
      if(data.purchase_order) this.purchase_order = data.purchase_order;
      else this.router.navigate(['/purchase-orders']);
    });

    this.initForm();
  }

  isValid(card)
  {
    let valid = {
      settings: this.fieldIsValid('date') && this.fieldIsValid('name'),
      'menu-items': this.fieldIsValid('menu_items'),
    };

    return valid[card];
  }

  fieldIsValid(field) {
    const control = this.purchaseOrderForm.get(field);
    return control.valid || !(
        (!control.valid && control.touched) ||
        (control.untouched && this.formSubmitAttempt)
    );
  }

  initForm()
  {
    const me = this,
        purchaseOrder = me.purchase_order,
        config = {
            settings: [
              {
                component: ReactiveTextFieldComponent,
                type: 'control',
                label: 'Nome',
                key: 'name',
                placeholder: '',
                value: purchaseOrder.name,
                disabled: false,
                validators: [ Validators.required ]
              },
              {
                component: ReactiveDatetimeFieldComponent,
                type: 'control',
                dateLabel: 'Data',
                format: 'date',
                key: 'date',
                value: purchaseOrder.date,
                validators: [Validators.required]
              }
            ],

          menu_items: [
              {
                component: MenuItemFieldComponent,
                type: 'array',
                label: 'Portate',
                key: 'menu_items',
                purchase_order_id: purchaseOrder.id,
                value: purchaseOrder.menu_items,
                validators: [ uniqueMenuItem()]
              }
            ]
        };

    me.purchaseOrderForm = me.formBuilder.group({});

    for (let key in config) {
      config[key].forEach(control => {
        me.purchaseOrderForm.addControl(
            control.key,
            this.createControl(control)
        )
      });
    }

    me.config = config;
  }

  createControl(control)
  {
    switch(control.type) {
      case 'array':
        let array = this.formBuilder.array([], control.validators);
        for (let i in control.value) {
          array.push(this.formBuilder.group(control.value[i]));
        }
        return array;
      case 'control':
        return this.formBuilder.control({ value: control.value, disabled: control.disabled }, control.validators);
    }
  }


  onSubmit()
  {
    Object.assign(this.purchase_order, this.purchaseOrderForm.value);
    let fn;
    if(this.purchase_order.id < 0) {
      fn = this.facadeService.purchaseOrderService.addPurchaseOrder(this.purchase_order);
    } else {
      fn = this.facadeService.purchaseOrderService.updatePurchaseOrder(this.purchase_order);
    }

    fn.subscribe(purchase_order => {
      this.purchase_order = purchase_order;
      this.formSubmitAttempt = false;
      this.purchaseOrderForm.patchValue(purchase_order);
      this.purchaseOrderForm.updateValueAndValidity();
      this.purchaseOrderForm.markAsPristine();
      this.location.go('/purchase-orders/form/' + purchase_order.id);
    });
  }

  duplicate()
  {
    this.router.navigate(['/purchase-orders/form/-1']);
    this.purchaseOrderForm.get('name').setValue('');
    this.purchaseOrderForm.get('date').setValue('');
    this.purchaseOrderForm.updateValueAndValidity();
    this.purchaseOrderForm.markAsPristine();
  }


  save()
  {
    if (this.purchaseOrderForm.valid) {
      if (this.purchaseOrderForm.dirty) {
        this.onSubmit();
      }
    } else {
      this.formSubmitAttempt = true;
    }
  }


  remove()
  {
    const ref = this.dialog.open(AlertDialogComponent, { msg: 'Vuoi davvero eliminare l\'ordinazione?' });
    ref.afterClosed.subscribe(result => {
      if (result) {
        this.facadeService.purchaseOrderService.deletePurchaseOrder(this.purchase_order)
            .subscribe(() => this.router.navigate(['/purchase-orders']));
      }
    });
  }
}
