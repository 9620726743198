import { Component, OnInit } from '@angular/core';
import {ShiftModel, uniqueWorker} from "../../../../shared/model/shift/shift";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {FacadeService} from "../../../../shared/services/facade.service";
import {ReactiveTextFieldComponent} from "../../../../core/components/reactive-text-field/reactive-text-field.component";
import {WorkerFieldComponent} from "../../components/worker-field/worker-field.component";
import {ReactiveSelectFieldComponent} from "../../../../core/components/reactive-select-field/reactive-select-field.component";
import {ReactiveDatetimeFieldComponent} from "../../../../core/components/reactive-datetime-field/reactive-datetime-field.component";
import {ReactiveCheckboxFieldComponent} from "../../../../core/components/reactive-checkbox-field/reactive-checkbox-field.component";

@Component({
  selector: 'app-shift-form',
  templateUrl: './shift-form.component.html',
  styleUrls: ['./shift-form.component.scss']
})
export class ShiftFormComponent implements OnInit {

  shift: ShiftModel;
  shiftForm: FormGroup;
  config;
  activeCard: string = 'settings';
  formSubmitAttempt: boolean = false;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private facadeService: FacadeService,
      private formBuilder: FormBuilder ) { }

  ngOnInit() {
    this.route.data.subscribe((data: { shift: ShiftModel }) => {
      if (data.shift) this.shift = data.shift;
      else this.router.navigate(['/shifts/edit']);
    });

    this.initForm();
  }

  isValid(card)
  {
    let valid = {
      settings: this.fieldIsValid('date') && this.fieldIsValid('type'),
      workers: this.fieldIsValid('workers'),
      alert: true
    };

    return valid[card];
  }

  fieldIsValid(field) {
    const control = this.shiftForm.get(field);
    return control.valid || !(
        (!control.valid && control.touched) ||
      (control.untouched && this.formSubmitAttempt)
    );
  }

  initForm()
  {
    const me = this,
        shift = me.shift,
        config = {
          settings: [
            {
              component: ReactiveDatetimeFieldComponent,
              type: 'control',
              dateLabel: 'Data',
              format: 'date',
              key: 'date',
              value: shift.date,
              validators: [Validators.required]
            },
            {
              component: ReactiveSelectFieldComponent,
              type: 'control',
              label: 'Tipo',
              key: 'type',
              options: [
                {value: "other", label: 'Generico'},
                {value: "bar", label: 'Bar'},
                {value: "tables", label: 'Tavoli'},
                {value: "cook", label: 'Cucina'},
                {value: "wash", label: 'Lavaggio'},
              ],
              placeholder: '',
              value: shift.type,
              disabled: false,
              validators: [Validators.required]
            },
            {
              component: ReactiveTextFieldComponent,
              type: 'control',
              label: 'Descrizione',
              key: 'description',
              placeholder: '',
              value: shift.description,
              disabled: shift.type != 'other',
              validators: []
            },
            {
              component: ReactiveCheckboxFieldComponent,
              type: 'control',
              label: 'Pubblico',
              key: 'is_public',
              value: shift.is_public,
              disabled: false,
              validators: []
            },
          ],
          workers: [
            {
              component: WorkerFieldComponent,
              type: 'array',
              label: 'Turnisti',
              key: 'workers',
              shift_id: shift.id,
              validators: [Validators.required, uniqueWorker()],
              value: shift.workers,
            }],

          alert: []
        };

    me.shiftForm = me.formBuilder.group({});

    for (let key in config) {
      config[key].forEach(control => {
        me.shiftForm.addControl(
            control.key,
            this.createControl(control)
        )
      });
    }

    me.shiftForm.get('type').valueChanges.subscribe((value) => {
      let descriptionControl = this.shiftForm.get('description');
      descriptionControl[value == 'other' ? 'enable' : 'disable']();
      descriptionControl.setValue('');
      descriptionControl.updateValueAndValidity();
    });
    me.config = config;
  }

  createControl(control)
  {
    switch(control.type) {
      case 'array':
        let array = this.formBuilder.array([], control.validators);
        for (let i in control.value) {
          array.push(this.formBuilder.group(control.value[i]));
        }
        return array;
      case 'control':
        return this.formBuilder.control({ value: control.value, disabled: control.disabled }, control.validators);
    }
  }


  onSubmit()
  {
    Object.assign(this.shift, this.shiftForm.getRawValue());
    let fn;
    if(this.shift.id < 0) {
      fn = this.facadeService.shiftService.addShift(this.shift);
    } else {
      fn = this.facadeService.shiftService.updateShift(this.shift);
    }

    fn.subscribe(shift => {
      this.shift = shift;
      this.formSubmitAttempt = false;
      this.shiftForm.patchValue(shift);
      this.shiftForm.updateValueAndValidity();
      this.shiftForm.markAsPristine();
    });
  }

  save()
  {
    if (this.shiftForm.valid) {
      if (this.shiftForm.dirty) {
        this.onSubmit();
      }
    } else {
      this.formSubmitAttempt = true;
    }
  }

  remove()
  {
    this.facadeService.shiftService.deleteShift(this.shift)
        .subscribe(() => this.router.navigate(['/shifts/edit']));
  }
}
