import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { EventModel } from '../../../../shared/model/event';
import {FacadeService} from "../../../../shared/services/facade.service";

@Component({
  selector: 'app-advanced-form',
  templateUrl: './advanced-form.component.html',
  styleUrls: ['./advanced-form.component.scss']
})
export class AdvancedFormComponent implements OnInit {

  @Input() set event(event: EventModel){
    this._event = Object.assign({}, event) as EventModel;
    this._snapshot = event;
  };

  @Output() onUpdate: EventEmitter<EventModel> = new EventEmitter<EventModel>();
  @Output() onClose: EventEmitter<any> = new EventEmitter();

  public _event: EventModel = {} as EventModel;
  public _snapshot: EventModel;

  constructor(private facadeService: FacadeService) { }

  ngOnInit() { }

  close()
  {
    this._event = {} as EventModel;
    this.onClose.emit();
  }

  onSubmit() {
    this.facadeService.eventService.updateEvent(this._event)
    .subscribe(event => {
      this.onUpdate.emit(event);
    });    
  }

}
