import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { ReferentModel } from '../../../../shared/model/referent';
import {FacadeService} from "../../../../shared/services/facade.service";

@Component({
  selector: 'app-referent-form',
  templateUrl: './referent-form.component.html',
  styleUrls: ['./referent-form.component.scss']
})
export class ReferentFormComponent implements OnInit {

  @Input() set referent(referent: ReferentModel){
    this._referent = Object.assign({}, referent) as ReferentModel;
    this._snapshot = referent;
  };

  @Output() onUpdate: EventEmitter<ReferentModel> = new EventEmitter<ReferentModel>();
  @Output() onClose: EventEmitter<any> = new EventEmitter();

  public _referent: ReferentModel = {} as ReferentModel;
  public _snapshot: ReferentModel;

  public searchTerm: string;

  constructor(private facadeService: FacadeService) { }

  ngOnInit() { }

  close()
  {
    this._referent = {} as ReferentModel;
    this.onClose.emit();
  }

  onSubmit() {
    this.facadeService.eventService.updateReferent(this._referent.event_id, this._referent)
    .subscribe(activity => {
      this.onUpdate.emit(activity);
    });    
  }

  onSelectUser(autocomplete) {
    this.searchTerm = autocomplete.term;

    if (autocomplete.selected) {
      this._referent.user_id = autocomplete.selected.id;
      this._referent.full_name = autocomplete.selected.name;
      this._referent.tel = autocomplete.selected.phone;
      this._referent.email = autocomplete.selected.email;
    } else {
      this._referent.user_id = null;
      this._referent.full_name = autocomplete.term;
      this._referent.tel = null;
      this._referent.email = null;
    }
  }

}
