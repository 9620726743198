import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import {HttpService} from "../../http.service";
import {MessageModel} from "../../../model/messages/message";
import {NotificationModel} from "../../../model/notification";
import {UserModel} from "../../../model/user";
import {EventModel} from "../../../model/event";
import {PartecipantModel} from "../../../model/partecipant";
import {AttachmentModel} from "../../../model/messages/attachment";

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  private messagesUrl = '/messages';  // URL to web api


  private messageCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private messages: BehaviorSubject<MessageModel[]> = new BehaviorSubject<MessageModel[]>([]);

  private dataStore: {
    messages: MessageModel[],
    count: number
  } = { messages: [], count: 0 };

  constructor(private http: HttpService) { }

  getMessages() {
    return this.messages.asObservable();
  }

  loadMessages (): Observable<MessageModel[]> {
    return this.http.get('loadMessages', this.messagesUrl).pipe(
        map((messages) => {
          Object.assign(this.dataStore, {messages: messages});
          this.messages.next(this.dataStore.messages);
          return messages;
        }),
        tap(messages => this.log(`fetched messages`)),
        catchError(this.handleError('getMessages', []))
    );
  }


  getMessage(id: number): Observable<MessageModel> {

    const url = `${this.messagesUrl}/${id}`;

    return this.http.get('loadMessage', url).pipe(
        tap(_ => this.log(`fetched message id=${id}`)),
        catchError(this.handleError<MessageModel>(`getMessage id=${id}`))
    );

  }

  deleteMessage (message: MessageModel | number): Observable<MessageModel> {

    const id = typeof message === 'number' ? message : message.id;
    const url = `${this.messagesUrl}/${id}`;

    return this.http.delete('deleteMessage', url).pipe(
        tap(_ => this.log(`deleted message id=${id}`)),
        catchError(this.handleError<MessageModel>('deleteMessage'))
    );

  }

  getMessageCount(): Observable<number> {
    return this.messageCount.asObservable();
  }

  onMessageAdded(e)
  {
      let message = e.data as MessageModel;
      this.updateMessageCount(1);

      this.dataStore.messages.splice(0,0, message);
      this.messages.next(this.dataStore.messages);
  }

  onMessageDeleted(e)
  {
    const index = this.dataStore.messages.findIndex((m) => m.id == e.data.id);
    if (index > -1) {
      let deletedMessage = this.dataStore.messages.splice(index,1);
      if (deletedMessage[0].read  == false ) {
        this.updateMessageCount(-1);
      }
      this.messages.next(this.dataStore.messages);
    }
  }

  onMessageRead(e)
  {
    const index = this.dataStore.messages.findIndex((m) => m.id == e.data.id);
    this.updateMessageCount(-1);
    if (index > -1) {
      this.dataStore.messages[index].read = true;
      this.messages.next(this.dataStore.messages);
    }
  }


  updateMessageCount(increment)
  {
    this.dataStore.count += increment;
    this.messageCount.next(this.dataStore.count);
  }



  loadMessageCount(): Observable<any> {

    const url = `${this.messagesUrl}/count`;

    return this.http.get('loadMessageCount', url).pipe(
        map((messages: {count: number}) => {

          Object.assign(this.dataStore, messages);
          this.messageCount.next(messages.count);

          return messages;
        }),
        tap(notifications => this.log(`fetched message count`)),
        catchError(this.handleError('getMessageCount', []))
    );
  }


  downloadAttachment(message: MessageModel | number, attachment: AttachmentModel | number): Observable<any> {

    const id = typeof message === 'number' ? message : message.id;
    const attachmentId = typeof attachment === 'number' ? attachment : attachment.id;

    const url = `${this.messagesUrl}/${id}/attachments/${attachmentId}`;

    return this.http.get('getAttachment', url).pipe(
        tap(_ => this.log(`attachment`)),
        catchError(this.handleError<any>(`getAttachment`))
    );

  }

  deleteAttachment(message: MessageModel | number, attachment: AttachmentModel | number): Observable<any> {

    const id = typeof message === 'number' ? message : message.id;
    const attachmentId = typeof attachment === 'number' ? attachment : attachment.id;

    const url = `${this.messagesUrl}/${id}/attachments/${attachmentId}`;

    return this.http.delete('deleteAttachment', url).pipe(

        tap(_ => this.log(`remove attachment`)),
        catchError(this.handleError<any>(`deleteAttachment`))
    );

  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    console.log('ShiftsService: ' + message);
  }
}
