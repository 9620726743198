import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Route } from '@angular/compiler/src/core';
import {FacadeService} from "../services/facade.service";

@Injectable()
export class AdminGuard implements CanActivate, CanLoad {

  constructor( private facadeService: FacadeService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if(this.facadeService.getCurrentAccount().is_placti || this.facadeService.authService.isAdmin()) {
      return Promise.resolve(true);
    }

    this.router.navigate(['/']);
    return Promise.resolve(false);
  }

  canLoad(route:Route): Observable<boolean> | Promise<boolean> | boolean {

    if(this.facadeService.getCurrentAccount().is_placti || this.facadeService.authService.isAdmin()) {
      return Promise.resolve(true);
    }

    this.router.navigate(['/']);
    return Promise.resolve(false);
  }
}
