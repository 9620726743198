import {InjectionToken} from '@angular/core';

export let APP_CONFIG = new InjectionToken('app.config');

export const AppConfig = {
  routes: {
    scheduler: '',
    events: 'events',
    error404: '404',
    auth: 'auth',
    account: 'account',
    users: 'users',
    shifts: 'shifts',
    notifications: 'notifications',
    messages: 'messages',
    'purchase-orders': 'purchase-orders'
  }
};