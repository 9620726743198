import { Component, OnInit } from '@angular/core';
import { EventModel } from '../../../../shared/model/event';
import { Router } from '@angular/router';
import { ActivityModel } from '../../../../shared/model/actvity';
import * as moment from 'moment-timezone';
import {AuthService} from "../../../../shared/services/auth.service";
import {DialogService} from "../../../../core/components/dialog/dialog.service";
import {FilterDialogComponent} from "../../../shifts/components/filter-dialog/filter-dialog.component";
import {FacadeService} from "../../../../shared/services/facade.service";

@Component({
    selector: 'app-event-list',
    templateUrl: './event-list.component.html',
    styleUrls: ['./event-list.component.scss']
})
export class EventListComponent implements OnInit {

    public events: EventModel[];
    public drafts: EventModel[];

    public currentDay: any = moment().tz('Europe/Rome').startOf('day');
    public sort: string = 'ASC';
    public isLoaded: boolean[] = [false, false];

    public isSuperuser: boolean = false;
    public view: string = 'event';

    constructor(private facadeService: FacadeService, private router: Router, public dialog: DialogService) { }

    ngOnInit() {
        this.getDrafts();
        this.getEvents();
        this.isSuperuser =  (this.facadeService.authService.isSuperuser() || this.facadeService.authService.isAdmin() || this.facadeService.authService.isCollaborator()) && this.facadeService.authService.isEnabled();
    }

    getDrafts(): void {
        const filter = {
            sort: 'DESC',
            type: 'draft'
        };

        this.facadeService.eventService.loadEvents(filter)
            .subscribe(events => {
                this.drafts = events;
                this.isLoaded.pop();
            });
    }

    getEvents(): void {

        const filter = {
            day: this.currentDay.format(),
            sort: this.sort,
            type: 'editable'
        };

        this.facadeService.eventService.loadEvents(filter)
            .subscribe(events => {
                this.events = events;
                this.isLoaded.pop();
                this.scrollTop();
            });
    }

    add(): void {

        const newEvent = { share: this.facadeService.eventService.getDefaultShareOptions() } as EventModel;

        this.facadeService.eventService.addEvent(newEvent)
            .subscribe(
                event => {
                    if(event) {
                        this.events.push(event);
                        this.router.navigate(['/events/detail', event.id]);
                    }
                }
            );
    }

    delete(event: EventModel): void {

        this.facadeService.eventService.deleteEvent(event)
            .subscribe(() => {
                this.events = this.events.filter(h => h !== event)
            });

    }

    getActivityType(activity: ActivityModel): string {
        let activityIcons = {
            'food': 'fas fa-utensils',
            'drink': 'fas fa-glass-martini',
            'music': 'fas fa-music',
            'assembly': 'fas fa-gavel',
            'sport': 'fas fa-futbol',
            'other': 'far fa-calendar',
        };

        return activityIcons[activity.type];
    }

    changeView(view): void {

        const tmp = this.events;
        this.events = this.drafts;
        this.drafts = tmp;

        this.view = view;
    }


    scrollTop(): void {
        const section = document.querySelector('section.event-list');
        if (section) {
            section.scrollIntoView();
        }
    }

    onSort(sort): void {
        this.sort = sort;
        this.getEvents();
    }

    onFilter(): void {

        const ref = this.dialog.open(FilterDialogComponent, { data: { filterDay: this.currentDay.format() }});

        ref.afterClosed.subscribe(result => {
            if (result && result.filterDay) {
                this.currentDay = moment(result.filterDay).tz('Europe/Rome');
                this.getEvents();
            }
        });

    }

    canEdit(group) {
        const userGroup = this.facadeService.authService.getGroups();
        if (this.facadeService.authService.isCollaborator()) {

            return !!group.share.filter((share) => userGroup.indexOf(share) > -1).length;

        }
        return true;
    }
}
