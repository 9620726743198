import { Injectable } from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RoutesRecognized} from "@angular/router";
import {filter, map, pairwise} from "rxjs/operators";


@Injectable()
export class RoutingStateService {
    private history = [];

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}

    public loadRouting(): void {
        this.router.events
            .pipe(
                filter((e: any) => e instanceof RoutesRecognized),
                pairwise()
            )
            .subscribe((e: any) => {

                let child = this.activatedRoute.firstChild;
                while (child.firstChild) {
                    child = child.firstChild;
                }

                if (child.snapshot.data['history'] !== false) {
                    this.history = [...this.history, e[0].urlAfterRedirects];
                }

            });
    }

    public popHistory(removeFromHistory) {

        if (this.history[this.history.length - 1] == removeFromHistory) {
            this.history.pop();
        }
    }

    public getHistory(): string[] {
        return this.history;
    }

    public getPreviousUrl(): string {

        var prev = this.history[this.history.length - 1];

        if (prev == window.location.pathname) { this.history.pop(); }

        return this.history[this.history.length - 1] || '/';
    }
}