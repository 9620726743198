import { Component, OnInit } from '@angular/core';
import {FacadeService} from "../../../../shared/services/facade.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DialogService} from "../../../../core/components/dialog/dialog.service";
import {PurchaseOrderModel} from "../../../../shared/model/purchase-orders/purchase-order";
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-purchase-order-list',
  templateUrl: './purchase-order-list.component.html',
  styleUrls: ['./purchase-order-list.component.scss']
})
export class PurchaseOrderListComponent implements OnInit {

  public purchase_orders: PurchaseOrderModel[];
  public isSuperuser: boolean = false;
  public currentDay: any = moment().tz('Europe/Rome').startOf('day');
  public sort: string = 'ASC';

  canEditMenu: boolean = false;

  constructor(private facadeService: FacadeService, private router: Router, public dialog: DialogService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data.subscribe((data: { purchase_orders: PurchaseOrderModel[] }) => {
      if(data.purchase_orders) this.purchase_orders = data.purchase_orders;
      else this.router.navigate(['/']);
    });

    this.canEditMenu = this.facadeService.authService.isEnabled() && (this.facadeService.authService.isSuperuser() || this.facadeService.authService.isAdmin());
  }

  getPurchaseOrders()
  {
    const filter = {
      day: this.currentDay.format(),
      sort: this.sort,
      type: 'all'
    };

    this.facadeService.purchaseOrderService.loadPurchaseOrders(filter).subscribe((purchase_orders) => {
        this.purchase_orders = purchase_orders;
    });

  }


  add(): void {
    this.router.navigate(['/purchase-orders/form/-1']);
  }


  scrollTop(): void {
    const section = document.querySelector('section.shift-list');
    if (section) {
      section.scrollIntoView();
    }
  }

  onSort(sort): void {
    this.sort = sort;
    this.getPurchaseOrders();
  }


  onFilter(): void {

    // const ref = this.dialog.open(FilterDialogComponent, { data: { filterDay: this.currentDay.format() }});
    //
    // ref.afterClosed.subscribe(result => {
    //   if (result.filterDay) {
    //     this.currentDay = moment(result.filterDay).tz('Europe/Rome');
    //     this.getPurchaseOrders();
    //   }
    // });

  }


}
