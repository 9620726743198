import { Injectable } from '@angular/core';
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class BluetoothService {

    private printCharacteristic;

    constructor() {}

    print(str)
    {
        if (this.printCharacteristic) {
            this.sendPrinterData(str);
        } else {
            this.connectAndPrint(str);
        }
    }

    connectAndPrint(str) {

        let mobileNavigatorObject: any = window.navigator;

        if(mobileNavigatorObject && mobileNavigatorObject.bluetooth) {

            return mobileNavigatorObject.bluetooth.requestDevice({
                filters: [{
                    services: ['000018f0-0000-1000-8000-00805f9b34fb']
                }],
                optionalServices: ['battery_service']
            })
                .then(device => {
                    console.log('> Found ' + device.name);
                    console.log('Connecting to GATT Server...');
                    return device.gatt.connect();
                })
                .then(server => server.getPrimaryService('000018f0-0000-1000-8000-00805f9b34fb'))
                .then(service => service.getCharacteristic(0x2af1))
                .then(characteristic => {
                    this.printCharacteristic = characteristic;
                    this.sendPrinterData(str);
                    return true;
                }).catch(function (error) {
                    console.error('Connection failed!', error);
                });
        }
    }

    sendPrinterData(text) {
        let me = this, arr = [], chunks = [];

        text += "\x1b\x64\x04";

        for (let i = 0, len = text.length; i < len; i++) {
            //arr.push(parseInt(text.substr(i, 2), 16));
            arr.push(text[i].charCodeAt(0))
        }

        for (let i = 0, len = arr.length; i < len; i+=8) {
            chunks.push(arr.slice(i, i+8));
        }

        chunks.reduce(
            (p, x) =>
                p.then(_ => me.sendDataToPrinter(x)),
            Promise.resolve()
        );
    }


    sendDataToPrinter(arr)
    {
        let data = new Uint8Array(arr);
        return this.printCharacteristic.writeValue(data.buffer);
    }

}