import { Injectable } from '@angular/core';
import * as Echo from "laravel-echo";
import * as io from 'socket.io-client';
import {environment} from "../../../environments/environment";
import {Router} from "@angular/router";
import {FacadeService} from "./facade.service";

@Injectable()
export class EchoService {

    constructor(private facadeService: FacadeService, private router: Router) { }

    init()
    {
        this.facadeService.authService.getUser().subscribe((user) => {
            if (user.id) {
                this.startListen(user.id, this.facadeService.authService.getToken());
            } else {
                this.stopListen();
            }
        });

    }


    startListen(userId, token)
    {
        if (!window.Echo){

            console.log('Echo: startListen');

            const accountId = this.facadeService.getCurrentAccountId();

            window.io = io;
            window.Echo = new Echo.default({
                broadcaster: 'socket.io',
                host: environment.websocketUrl,
                auth: {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Origin': location.hostname
                    }
                }
            });

            window.Echo.private('users.' + accountId + '.' + userId)
                .listen('.NotificationAdded', this.facadeService.notificationService.onNotificationAdded.bind(this.facadeService.notificationService))
                .listen('.MessageRead', this.facadeService.messageService.onMessageRead.bind(this.facadeService.messageService));

            window.Echo.private('users.' + accountId)
                .listen('.MessageAdded', this.facadeService.messageService.onMessageAdded.bind(this.facadeService.messageService))
                .listen('.MessageDeleted', this.facadeService.messageService.onMessageDeleted.bind(this.facadeService.messageService))
                .listen('.OrderRequestAdded',  this.facadeService.purchaseOrderService.onOrderRequestAdded.bind(this.facadeService.purchaseOrderService))
                .listen('.OrderRequestUpdated',  this.facadeService.purchaseOrderService.onOrderRequestUpdated.bind(this.facadeService.purchaseOrderService))
                .listen('.OrderRequestStatsUpdated', this.facadeService.purchaseOrderService.onOrderRequestStatsUpdated.bind(this.facadeService.purchaseOrderService))
                .listen('.OrderRequestCashUpdated', this.facadeService.purchaseOrderService.onOrderRequestCashUpdated.bind(this.facadeService.purchaseOrderService));
        }
    }


    stopListen()
    {
        if (window.Echo) {

            console.log('Echo: stopListen');

            window.Echo.disconnect();
            window.Echo = null;

        }
    }

}
