import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivityModel } from '../../../../shared/model/actvity';
import {FacadeService} from "../../../../shared/services/facade.service";

@Component({
  selector: 'app-activity-form',
  templateUrl: './activity-form.component.html',
  styleUrls: ['./activity-form.component.scss']
})
export class ActivityFormComponent implements OnInit {

  @Input() set activity(activity: ActivityModel){
    this._activity = Object.assign({}, activity) as ActivityModel;
    this._snapshot = activity;
  };

  @Output() onUpdate: EventEmitter<ActivityModel> = new EventEmitter<ActivityModel>();
  @Output() onClose: EventEmitter<any> = new EventEmitter();

  public _activity: ActivityModel = {} as ActivityModel;
  public _snapshot: ActivityModel;

  constructor(private facadeService: FacadeService) { }

  ngOnInit() { }

  close()
  {
    this._activity = {} as ActivityModel;
    this.onClose.emit();
  }

  onSubmit() {
    this.facadeService.eventService.updateActivity(this._activity.event_id, this._activity)
    .subscribe(activity => {
      this.onUpdate.emit(activity);
    });    
  }

}
