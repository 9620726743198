import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { ShiftModel } from '../../model/shift/shift';
import { WorkerModel } from '../../model/shift/worker';
import {HttpService} from "../http.service";


@Injectable({
    providedIn: 'root'
})
export class ShiftsService {

    private shiftsUrl = '/shifts';  // URL to web api

    private shifts: BehaviorSubject<ShiftModel[]> = new BehaviorSubject<ShiftModel[]>([]);

    private dataStore: {
        shifts: ShiftModel[]
    } = { shifts: []};


    constructor(private http: HttpService) { }

    getShifts() {
        return this.shifts.asObservable();
    }

    loadShifts (filter): Observable<ShiftModel[]> {
        return this.http.get('loadShifts', this.shiftsUrl, filter).pipe(
            map((shifts) => {
                Object.assign(this.dataStore, {shifts: shifts});
                this.shifts.next(this.dataStore.shifts);
                return shifts;
            }),
            tap(shifts => this.log(`fetched shifts`)),
            catchError(this.handleError('getShifts', []))
        );
    }


    getShift(id: number): Observable<ShiftModel> {

        const url = `${this.shiftsUrl}/${id}`;

        if (id < 0) {
            return of({ id: -1, type: '', workers: [], is_public: false } as ShiftModel);
        }

        return this.http.get('loadShift', url).pipe(
            tap(_ => this.log(`fetched shift id=${id}`)),
            catchError(this.handleError<ShiftModel>(`getShift id=${id}`))
        );

    }


    addShift (shift: ShiftModel): Observable<ShiftModel> {

        return this.http.post('addShift', this.shiftsUrl, shift).pipe(
            tap((shift: ShiftModel) => this.log(`added shift w/ id=${shift.id}`)),
            catchError(this.handleError<ShiftModel>('addShift'))
        );

    }


    deleteShift (shift: ShiftModel | number): Observable<ShiftModel> {

        const id = typeof shift === 'number' ? shift : shift.id;
        const url = `${this.shiftsUrl}/${id}`;

        return this.http.delete('deleteShift', url).pipe(
            tap(_ => this.log(`deleted shift id=${id}`)),
            catchError(this.handleError<ShiftModel>('deleteShift'))
        );

    }

    /** PUT: update the hero on the server */
    updateShift (shift: ShiftModel): Observable<any> {

        const id = shift.id;
        const url = `${this.shiftsUrl}/${id}`;

        return this.http.post('updateShift', url, shift).pipe(
            tap(_ => this.log(`updated shift id=${shift.id}`)),
            catchError(this.handleError<any>('updateShift'))
        );

    }


    addWorker (event: ShiftModel, worker: WorkerModel): Observable<WorkerModel> {

        const id = event.id;
        const url = `${this.shiftsUrl}/${id}/workers`;

        return this.http.post('addWorker', url, worker).pipe(
            tap((worker: WorkerModel) => this.log(`added worker w/ id=${worker.id}`)),
            catchError(this.handleError<WorkerModel>('addWorker'))
        );

    }


    deleteWorker (event: ShiftModel, worker: WorkerModel | number): Observable<WorkerModel> {

        const id = event.id;
        const workerId = typeof worker === 'number' ? worker : worker.id;
        const url = `${this.shiftsUrl}/${id}/workers/${workerId}`;

        return this.http.delete('deleteWorker', url).pipe(
            tap(_ => this.log(`deleted worker id=${workerId}`)),
            catchError(this.handleError<WorkerModel>('deleteWorker'))
        );

    }

    /** PUT: update the hero on the server */
    updateWorker (event: ShiftModel | number, worker: WorkerModel): Observable<any> {

        const id = typeof event === 'number' ? event : event.id;
        const workerId = worker.id;
        const url = `${this.shiftsUrl}/${id}/workers/${workerId}`;

        return this.http.post('updateWorker', url, worker).pipe(
            tap(_ => this.log(`updated worker id=${workerId}`)),
            catchError(this.handleError<any>('updateWorker'))
        );

    }


    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T> (operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    /** Log a HeroService message with the MessageService */
    private log(message: string) {
        console.log('ShiftsService: ' + message);
    }

}
