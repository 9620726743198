import { Component, OnInit } from '@angular/core';

import {MenuItemModel} from "../../../../shared/model/purchase-orders/menu-item";
import {DialogConfig} from "../../../../core/components/dialog/dialog-config";
import {DialogRef} from "../../../../core/components/dialog/dialog-ref";

@Component({
  selector: 'menu-item-field-modal',
  templateUrl: './menu-item-field-modal.component.html',
  styleUrls: ['./menu-item-field-modal.component.scss']
})
export class MenuItemFieldModalComponent implements OnInit {

    public menuItem: MenuItemModel;

    constructor(public config: DialogConfig, public dialog: DialogRef) {}

    onClose() {
        this.dialog.close({});
    }

    ngOnInit() {
        this.menuItem = this.config.data.menu_item;
    }


    menuItemIsValid()
    {
        return this.menuItem.name && this.menuItem.price;
    }


    onPositionKeyPress(e)
    {
        return !!e.key.match(new RegExp('[0-9]'));
    }

    updatePosition($event)
    {
        this.menuItem.position = parseInt($event.target.value);
    }

    onMaxQuantityKeyPress(e)
    {
        return !!e.key.match(new RegExp('[0-9]'));
    }

    updateMaxQuantity($event)
    {
        this.menuItem.max_quantity = parseInt($event.target.value);
    }


    onPriceKeyPress(e)
    {
        return !!e.key.match(new RegExp('[0-9\.]'));
    }

    updatePrice($event)
    {
        const price = parseFloat($event.target.value);
        this.menuItem.price = isNaN(price) ? '' : price.toFixed(2);
    }


    onOk() {
        this.dialog.close({ menu_item: this.menuItem } );
    }
}