import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';

@Component({
    selector: 'app-toggle-switch',
    templateUrl: './toggle-switch.component.html',
    styleUrls: ['./toggle-switch.component.scss']
})
export class ToggleSwitchComponent implements OnInit {

    @Input() beforeClick: Function = () => true;
    @Input() checked: boolean = false;
    @Input() label: string = '';
    @Output() onClick: EventEmitter<any> = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

    click(e) {
        if (this.beforeClick()) {
            this.onClick.emit(e);
            return true;
        }
        return false;
    }

}
