import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {SuperuserGuard} from "../../shared/guard/superuser.guard";
import {PurchaseOrdersComponent} from "./purchase-orders.component";
import {PurchaseOrderListComponent} from "./pages/purchase-order-list/purchase-order-list.component";
import {PurchaseOrderFormComponent} from "./pages/purchase-order-form/purchase-order-form.component";
import {PurchaseOrderResolver} from "./resolvers/purchase-order.resolver";
import {PurchaseOrdersResolver} from "./resolvers/purchase-orders.resolver";
import {OrderRequestListComponent} from "./pages/order-request-list/order-request-list.component";
import {OrderRequestChartComponent} from "./pages/order-request-chart/order-request-chart.component";
import {OrderRequestFormComponent} from "./pages/order-request-form/order-request-form.component";
import {OrderRequestResolver} from "./resolvers/order-request.resolver";
import {OrderRequestStatsResolver} from "./resolvers/order-request-stats.resolver";
import {OrderRequestCashComponent} from "./pages/order-request-cash/order-request-cash.component";
import {OrderRequestCashResolver} from "./resolvers/order-request-cash.resolver";

const purchaseOrdersRoutes: Routes = [
    {
        path: '',
        component: PurchaseOrdersComponent,
        children: [
            {
                path: '',
                component: PurchaseOrderListComponent,
                resolve: {
                    purchase_orders: PurchaseOrdersResolver
                }
            },
            {
                path: 'form/:id',
                component: PurchaseOrderFormComponent,
                data: { history: false },
                resolve: {
                    purchase_order: PurchaseOrderResolver
                }
            },
            {
                path: 'order-request-list/:id',
                component: OrderRequestListComponent,
                data: { history: false },
                resolve: {
                    purchase_order: PurchaseOrderResolver
                }
            },
            {
                path: 'order-request-chart/:id',
                component: OrderRequestChartComponent,
                data: { history: false },
                resolve: {
                    data: OrderRequestStatsResolver
                }
            },
            {
                path: 'order-request-cash/:id',
                component: OrderRequestCashComponent,
                data: { history: false },
                resolve: {
                    data: OrderRequestCashResolver
                }
            },
            {
                path: 'order-request-form/:purchaseOrderId/:id',
                component: OrderRequestFormComponent,
                data: { history: false },
                resolve: {
                    order_request: OrderRequestResolver
                }
            },
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(purchaseOrdersRoutes)
    ],
    exports: [
        RouterModule
    ]
})

export class PurchaseOrdersRoutingModule {
}