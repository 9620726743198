import {Injectable, Injector} from '@angular/core';
import {NotificationsService} from "./facade/notifications.service";
import {AccountService} from "./facade/account.service";
import {EventsService} from "./facade/events.service";
import {ShiftsService} from "./facade/shifts.service";
import {UsersService} from "./facade/users.service";
import {AccountModel} from "../model/account";
import {HttpService} from "./http.service";
import {UserModel} from "../model/user";
import {AuthService} from "./auth.service";
import {BehaviorSubject, Subject} from "rxjs";
import {Router} from "@angular/router";
import {ShiftGroupsService} from "./facade/shift-groups.service";
import {MessagesService} from "./facade/messages/messages.service";
import {PurchaseOrdersService} from "./facade/purchase-orders/purchase-orders.service";
import {BluetoothService} from "./bluetooth.service";
import {SoundService} from "./sound.service";


export function initAccount(facadeService: FacadeService, router: Router) {
    return () => {
        return facadeService.initializeApp();
    }
};

@Injectable({
    providedIn: 'root'
})
export class FacadeService {

    private _notificationService: NotificationsService;
    public get notificationService(): NotificationsService {
        if(!this._notificationService){
            this._notificationService = this.injector.get(NotificationsService);
        }
        return this._notificationService;
    }

    private _messageService: MessagesService;
    public get messageService(): MessagesService {
        if(!this._messageService){
            this._messageService = this.injector.get(MessagesService);
        }
        return this._messageService;
    }

    private _accountService: AccountService;
    public get accountService(): AccountService {
        if(!this._accountService){
            this._accountService = this.injector.get(AccountService);
        }
        return this._accountService;
    }

    private _eventService: EventsService;
    public get eventService(): EventsService {
        if(!this._eventService){
            this._eventService = this.injector.get(EventsService);
            this.currentAccount.subscribe((account) => this._eventService.currentAccount = account);
        }
        return this._eventService;
    }

    private _shiftService: ShiftsService;
    public get shiftService(): ShiftsService {
        if(!this._shiftService){
            this._shiftService = this.injector.get(ShiftsService);
        }
        return this._shiftService;
    }

    private _shiftGroupService: ShiftGroupsService;
    public get shiftGroupService(): ShiftGroupsService {
        if(!this._shiftGroupService){
            this._shiftGroupService = this.injector.get(ShiftGroupsService);
        }
        return this._shiftGroupService;
    }

    private _userService: UsersService;
    public get userService(): UsersService {
        if(!this._userService){
            this._userService = this.injector.get(UsersService);
            this.currentAccount.subscribe((account) => this._userService.currentAccount = account);
        }
        return this._userService;
    }


    private _authService: AuthService;
    public get authService(): AuthService {
        if(!this._authService){
            this._authService = this.injector.get(AuthService);
            this.currentAccount.subscribe((account) => this._authService.currentAccount = account);
        }
        return this._authService;
    }


    private _purchaseOrderService: PurchaseOrdersService;
    public get purchaseOrderService(): PurchaseOrdersService {
        if(!this._purchaseOrderService){
            this._purchaseOrderService = this.injector.get(PurchaseOrdersService);
        }
        return this._purchaseOrderService;
    }


    private _bluetoothService: BluetoothService;
    public get bluetoothService(): BluetoothService {
        if(!this._bluetoothService){
            this._bluetoothService = this.injector.get(BluetoothService);
        }
        return this._bluetoothService;
    }

    constructor(private injector: Injector, private http: HttpService, public sound: SoundService) {  }

    public currentAccount: BehaviorSubject<AccountModel> = new BehaviorSubject<AccountModel>({} as AccountModel);
    private autheticationLoading: Subject<boolean> = new Subject<boolean>();


    initializeApp(): Promise<any> {
        return Promise.resolve(window.Placti)
            .then((placti) => {
                this.currentAccount.next(placti.account);
                return placti.account;
            })
            .catch(() => {
                this.currentAccount.next({ id: null, name: 'Placti', logo: '/upload/placti.png' } as AccountModel);
            });
    }


    initializeUser(): Promise<any> {
        const url = `/auth`;
        this.autheticationLoading.next(true);

        return this.http.get('initializeUser', url)
            .toPromise()
            .then(user => {

                this.autheticationLoading.next(false);

                this.authService.user.next(user as UserModel);

                return Promise.resolve(true);
            }).catch(()=> {
                this.autheticationLoading.next(false);

                this.authService.setToken(null);

                return Promise.reject(false);
            });
    }


    getCurrentVersion(): string {
        return this.currentAccount.getValue().version;
    }

    getCurrentAccount(): AccountModel {
        return this.currentAccount.getValue();
    }

    getCurrentAccountId(): number {
        return this.currentAccount.getValue().id;
    }

    getAuthenticationLoading() {
        return this.autheticationLoading.asObservable();
    }

}
