import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";
import {RoutingStateService} from "../../../shared/services/routingstate.service";

@Component({
    selector: 'app-nav-top-bar',
    templateUrl: './nav-top-bar.component.html',
    styleUrls: ['./nav-top-bar.component.scss']
})
export class NavTopBarComponent implements OnInit {

    @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onBack: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input() title: string;
    @Input() subtitle: string;
    @Input() config = { close: true, back: true };

    private previousUrl: string;

    constructor(private router: Router, private routingState: RoutingStateService) {}

    ngOnInit() { }

    back() {
        this.router.navigate([this.routingState.getPreviousUrl()]);
        this.onBack.emit();
    }

    close() {
        this.onClose.emit();
    }

}
