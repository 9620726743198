import { Component, OnInit } from "@angular/core";
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { DialogConfig } from '../dialog/dialog-config';
import { DialogRef } from '../dialog/dialog-ref';

@Component({
    selector: 'app-text-dialog',
    templateUrl: './text-dialog.component.html'
})
export class TextDialogComponent implements OnInit {
    url: SafeResourceUrl = "";
    markdown: string;

    constructor(public config: DialogConfig, public dialog: DialogRef, private sanitizer: DomSanitizer) {}

    onClose() {
        this.dialog.close(false);
    }

    ngOnInit() {
        this.markdown = this.config.data.markdown;
    }
    
    onReady() {
        
    }


}