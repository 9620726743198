import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {
    Router, Resolve,
    ActivatedRouteSnapshot
} from '@angular/router';
import { ShiftModel } from '../../../shared/model/shift/shift';
import {FacadeService} from "../../../shared/services/facade.service";
import {ShiftGroupModel} from "../../../shared/model/shift/shift_group";


@Injectable()
export class ShiftGroupResolver implements Resolve<ShiftGroupModel> {
    constructor(private facadeService: FacadeService, private router: Router) { }
    
    resolve(route: ActivatedRouteSnapshot): Observable<ShiftGroupModel> {
        let id = +route.params['id'];
        return this.facadeService.shiftGroupService.getShiftGroup(id).pipe(
            map(shift_group => shift_group)
        );
    }
}