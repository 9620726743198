import { Component, OnInit } from '@angular/core';
import {ShiftModel} from "../../../../shared/model/shift/shift";
import * as moment from 'moment-timezone';
import {AuthService} from "../../../../shared/services/auth.service";
import {Router} from "@angular/router";
import {FilterDialogComponent} from "../../components/filter-dialog/filter-dialog.component";
import {DialogService} from "../../../../core/components/dialog/dialog.service";
import {FacadeService} from "../../../../shared/services/facade.service";


@Component({
  selector: 'app-shift-page',
  templateUrl: './shift-page.component.html',
  styleUrls: ['./shift-page.component.scss']
})

export class ShiftPageComponent implements OnInit {

    public shifts: ShiftModel[];

    public isLoaded: boolean = false;
    public currentDay: any = moment().tz('Europe/Rome').startOf('day');
    public sort: string = 'ASC';
    public isSuperuser: boolean = false;

    constructor(private facadeService: FacadeService, private router: Router, public dialog: DialogService) { }

    ngOnInit() {
        this.getShifts();
        this.isSuperuser =  (this.facadeService.authService.isSuperuser() || this.facadeService.authService.isAdmin()) && this.facadeService.authService.isEnabled();
    }

    getShifts(): void {

        const filter = {
            day: this.currentDay.format(),
            sort: this.sort
        };

        this.facadeService.shiftService.loadShifts(filter)
            .subscribe(shifts => {
                this.shifts = shifts;
                this.isLoaded = true;
                this.scrollTop();
            });
    }

    getShiftType(shift: ShiftModel): string {
        const shiftTypes = {
            tables: 'Turno Tavoli',
            bar: 'Turno Bar',
            cook: 'Turno Cucina',
            wash: 'Turno Lavaggio',
            other: 'Turno ' + (shift.description || '')
        };

        return shiftTypes[shift.type];
    }


    scrollTop(): void {
        const section = document.querySelector('section.shift-page');
        if (section) {
            section.scrollIntoView();
        }
    }

    onSort(sort): void {
        this.sort = sort;
        this.getShifts();
    }

    onFilter(): void {

        const ref = this.dialog.open(FilterDialogComponent, { data: { filterDay: this.currentDay.format() }});

        ref.afterClosed.subscribe(result => {
            if (result && result.filterDay) {
                this.currentDay = moment(result.filterDay).tz('Europe/Rome');
                this.getShifts();
            }
        });

    }

    highlightWorker(worker) {
        return worker.user_id == this.facadeService.authService.getUserId();
    }

}
