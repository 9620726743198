import { WorkerModel } from './worker';
import {AbstractControl, ValidatorFn} from "@angular/forms";

export class ShiftModel
{
    id: number;
    date: Date;
    status: string = 'hidden';
    label: string;
    type: string;
    description?: string;
    workers: Array<WorkerModel>;
    is_public: boolean = false;
}

export function uniqueWorker(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
        let uniq = control.value
            .map((worker) => {
                return {
                    count: 1,
                    user_id: worker.user_id
                }
            })
            .reduce((a, b) => {
                a[b.user_id] = (a[b.user_id] || 0) + b.count;
                return a
            }, {});

        let duplicates = Object.keys(uniq).filter((a) => uniq[a] > 1 && !!parseInt(a)).map(v => parseInt(v));
        return duplicates.length ? {'duplicateValues': duplicates} : null;
    };
}