import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import { ShiftsComponent } from './shifts.component';
import { ShiftResolver } from './resolvers/shift.resolver';
import {ShiftListComponent} from "./pages/shift-list/shift-list.component";
import {SuperuserGuard} from "../../shared/guard/superuser.guard";
import {ShiftPageComponent} from "./pages/shift-page/shift-page.component";
import {ShiftGroupListComponent} from "./pages/shift-group-list/shift-group-list.component";
import {ShiftGroupFormComponent} from "./pages/shift-group-form/shift-group-form.component";
import {ShiftGroupResolver} from "./resolvers/shift-group.resolver";
import {ShiftFormComponent} from "./pages/shift-form/shift-form.component";

const shiftsRoutes: Routes = [
    {
        path: '',
        component: ShiftsComponent,
        children: [
            {
                path: '',
                component: ShiftPageComponent
            },
            {
                path: 'groups',
                component: ShiftGroupListComponent
            },
            {
                path: 'groups/:id',
                component: ShiftGroupFormComponent,
                canActivate: [ SuperuserGuard ],
                resolve: {
                    shift_group: ShiftGroupResolver
                }
            },
            {
                path: 'edit',
                component: ShiftListComponent,
                canActivate: [ SuperuserGuard ]
            },
            {
                path: 'form/:id',
                component: ShiftFormComponent,
                canActivate: [ SuperuserGuard ],
                resolve: {
                    shift: ShiftResolver
                }
            }
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(shiftsRoutes)
    ],
    exports: [
        RouterModule
    ]
})

export class ShiftsRoutingModule {
}