import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterPartecipantByName',
  pure: false
})
export class FilterPartecipantByNamePipe implements PipeTransform {

  transform(value: any, args?: any): any {

    let filter = args,
      searchTerm = (filter.term || '').trim().toLowerCase(),
      filtered = value;

    if (searchTerm) {
      filtered =  filtered.filter((user) => { 
        let firstName = user.first_name.toLowerCase(),
          lastName = user.last_name.toLowerCase(),
          fullName = firstName + ' ' + lastName,
          reverseFullName = lastName + ' ' + firstName  ;

        return firstName.indexOf(searchTerm) > -1 
          || lastName.indexOf(searchTerm) > -1 
          || fullName.indexOf(searchTerm) > -1 
          || reverseFullName.indexOf(searchTerm) > -1;
      });
    }

    return filtered;    
  }

}
