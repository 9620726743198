import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {
    Router, Resolve,
    ActivatedRouteSnapshot
} from '@angular/router';
import {FacadeService} from "../../../shared/services/facade.service";
import {PurchaseOrderModel} from "../../../shared/model/purchase-orders/purchase-order";
import * as moment from 'moment-timezone';


@Injectable()
export class PurchaseOrdersResolver implements Resolve<PurchaseOrderModel[]> {
    constructor(private facadeService: FacadeService, private router: Router) { }
    
    resolve(route: ActivatedRouteSnapshot): Observable<PurchaseOrderModel[]> {

        const filter = {
            day: moment().tz('Europe/Rome').startOf('day').format(),
            sort: 'ASC'
        };

        return this.facadeService.purchaseOrderService.loadPurchaseOrders(filter).pipe(
            map(purchase_orders => purchase_orders)
        );
    }
}