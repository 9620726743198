import { Component, OnInit } from '@angular/core';
import {EventModel} from '../../../../shared/model/event';
import {Router} from '@angular/router';
import * as moment from 'moment-timezone';
import {ActivityModel} from '../../../../shared/model/actvity';
import {AuthService} from "../../../../shared/services/auth.service";
import {FilterDialogComponent} from "../../../shifts/components/filter-dialog/filter-dialog.component";
import {DialogService} from "../../../../core/components/dialog/dialog.service";
import {FacadeService} from "../../../../shared/services/facade.service";

@Component({
  selector: 'app-event-page',
  templateUrl: './event-page.component.html',
  styleUrls: ['./event-page.component.scss']
})
export class EventPageComponent implements OnInit {

    public events: EventModel[] = [];
    public isLoaded: boolean = false;
    public currentDay: any = moment().tz('Europe/Rome').startOf('day');
    public sort: string = 'ASC';
    public isSuperuser: boolean = false;

    constructor(private facadeService: FacadeService, private router: Router,  public dialog: DialogService) { }

    ngOnInit() {
        this.getEvents();
        this.isSuperuser =  (this.facadeService.authService.isSuperuser() || this.facadeService.authService.isAdmin() || this.facadeService.authService.isCollaborator()) && this.facadeService.authService.isEnabled();
    }


    getEvents(): void {

        const filter = {
            day: this.currentDay.format(),
            sort: this.sort
        };

        this.facadeService.eventService.loadEvents(filter)
            .subscribe(events => {

                this.events = events;
                this.isLoaded = true;

                this.scrollTop();

            });
    }

    getActivityType(activity: ActivityModel): string {
        const activityIcons = {
            'food': 'fas fa-utensils',
            'drink': 'fas fa-glass-martini',
            'music': 'fas fa-music',
            'assembly': 'fas fa-gavel',
            'sport': 'fas fa-futbol',
            'other': 'far fa-calendar',
        };

        return activityIcons[activity.type];
    }

    scrollTop(): void {
        const section = document.querySelector('section.event-page');
        if (section) {
            section.scrollIntoView();
        }
    }

    onSort(sort): void {
        this.sort = sort;
        this.getEvents();
    }

    onFilter(): void {

        const ref = this.dialog.open(FilterDialogComponent, { data: { filterDay: this.currentDay.format() }});

        ref.afterClosed.subscribe(result => {
            if (result && result.filterDay) {
                this.currentDay = moment(result.filterDay).tz('Europe/Rome');
                this.getEvents();
            }
        });

    }

}