import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Route } from '@angular/compiler/src/core';
import {FacadeService} from "../services/facade.service";

@Injectable()
export class PlactiGuard implements CanLoad {
  constructor(private facadeService: FacadeService, private router: Router) {}

  canLoad(route:Route): Observable<boolean> | Promise<boolean> | boolean {

      if(!this.facadeService.getCurrentAccount().is_placti) {
        return Promise.resolve(true);
      }

    this.router.navigate(['/account']);
    return Promise.resolve(false);
  }
}
