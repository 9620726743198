import { Component, OnInit } from '@angular/core';
import { EventModel } from '../../../../shared/model/event';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivityModel } from '../../../../shared/model/actvity';
import { ReferentModel } from '../../../../shared/model/referent';
import {FacadeService} from "../../../../shared/services/facade.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TextFieldComponent} from "../../../../core/components/text-field/text-field.component";
import {ReactiveTextFieldComponent} from "../../../../core/components/reactive-text-field/reactive-text-field.component";
import {ActivityFieldComponent} from "../../components/activity-field/activity-field.component";

@Component({
    selector: 'app-event-detail',
    templateUrl: './event-detail.component.html',
    styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent implements OnInit {

    public event: EventModel = {} as EventModel;
    public currentActivity: ActivityModel = {} as ActivityModel;
    public currentReferent: ReferentModel = {} as ReferentModel;
    public currentEventForDetail: EventModel = {} as EventModel;
    public currentEventForAdvanced: EventModel = {} as EventModel;

    public eForm: FormGroup;
    public config;

    public shareOptions: Array<any> = [];

    get startDate(): Date {
        return this.event.activities.map((activity) => activity.start_at).sort()[0];
    }

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private facadeService: FacadeService,
        private location: Location,
        private formBuilder: FormBuilder ) { }

    ngOnInit() {
        const me = this;
        me.facadeService.eventService.getEvent().subscribe((event) => me.event = event);
        me.shareOptions = me.facadeService.eventService.getShareOptions();

    }

    initForm()
    {
        const me = this,
            config = [
                {
                    type: ReactiveTextFieldComponent,
                    label: 'Nome',
                    key: 'name',
                    placeholder: '',
                    value: '',
                    disabled: false,
                    validators: [ Validators.required ]
                },
                {
                    type: ActivityFieldComponent,
                    key: 'activities',
                    event: me.event,
                    value: me.event.activities.length,
                    disabled: false,
                    validators: [ Validators.required, Validators.min(1) ]
                }
            ];

        me.eForm = me.formBuilder.group({});
        config.forEach(control => me.eForm.addControl(
            control.key,
            this.formBuilder.control({ value: control.value, disabled: control.disabled }, control.validators)
        ));

        this.config = config;

        // <form [formGroup]="eForm" (ngSubmit)="onReactiveSubmit($event)">
        // <ng-container
        // *ngFor="let c of config;"
        // appDynamicControl
        //     [config] = "c"
        //     [group] = "eForm"
        //     >
        //     </ng-container>
        //     <button type="submit">submit</button>
        // </form>
    }


    onSubmit() {
        this.facadeService.eventService.updateEvent(this.event).subscribe((event) => {
            if (event) {
                this.event = event;
            } else {
                this.router.navigate(['/events/edit']);
            }
        });
    }

    removeEvent(event)
    {
        this.facadeService.eventService.deleteEvent(event)
            .subscribe(event => {
                this.router.navigate(['/events/edit']);
            });
    }

    // TODO: Remove this when we're done
    get diagnostic() { return JSON.stringify(this.event); }

    goBack(): void {
        this.location.back();
    }

    editDetail()
    {
        this.currentEventForDetail = this.event;
    }

    onDetailUpdate(event: EventModel)
    {
        this.event = event;
        this.currentEventForDetail = {} as EventModel;
    }

    onDetailClose() {
        this.currentEventForDetail = {} as EventModel;
    }

    editAdvanced()
    {
        this.currentEventForAdvanced = this.event;
    }

    onAdvancedUpdate(event: EventModel)
    {
        this.event = event;
        this.currentEventForAdvanced = {} as EventModel;
    }

    onAdvancedClose() {
        this.currentEventForAdvanced = {} as EventModel;
    }

    addActivity()
    {
        this.facadeService.eventService.addActivity(this.event, {} as ActivityModel)
            .subscribe(activity => {
                this.event.activities.push(activity);
                this.currentActivity = activity;
            });
    }

    onActivityUpdate(activity: ActivityModel) {
        let index = this.event.activities.findIndex((a) => a.id == activity.id);
        this.event.activities[index] = activity;
        this.currentActivity = {} as ActivityModel;
    }

    onActivityClose() {
        this.currentActivity = {} as ActivityModel;
    }

    editActivity(activity: ActivityModel)
    {
        this.currentActivity = activity;
    }

    removeActivity(activity: ActivityModel)
    {
        this.facadeService.eventService.deleteActivity(this.event, activity)
            .subscribe(success => {
                this.event.activities = this.event.activities.filter(a => a.id != activity.id);
            });
    }

    addReferent()
    {
        this.facadeService.eventService.addReferent(this.event, {} as ReferentModel)
            .subscribe(referent => {
                this.event.referents.push(referent);
                this.currentReferent = referent;
            });


        this.currentReferent = { id: 1 } as ReferentModel;
    }

    onReferentUpdate(referent: ReferentModel) {
        let index = this.event.referents.findIndex((a) => a.id == referent.id);
        this.event.referents[index] = referent;
        this.currentReferent = {} as ReferentModel;
    }

    editReferent(referent: ReferentModel)
    {
        this.currentReferent = referent;
    }

    removeReferent(referent: ReferentModel)
    {
        this.facadeService.eventService.deleteReferent(this.event, referent)
            .subscribe(success => {
                this.event.referents = this.event.referents.filter(a => a.id != referent.id);
            });
    }

    onReferentClose() {
        this.currentReferent = {} as ReferentModel;
    }

    getActivityType(activity: ActivityModel): string {
        let activityIcons = {
            'food': 'fas fa-utensils',
            'drink': 'fas fa-glass-martini',
            'music': 'fas fa-music',
            'assembly': 'fas fa-gavel',
            'sport': 'fas fa-futbol',
            'other': 'far fa-calendar',
        };

        return activityIcons[activity.type];
    }

}
