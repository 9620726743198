import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../shared/services/auth.service';
import { Router } from '@angular/router';
import {AccountModel} from "../../../shared/model/account";
import {FacadeService} from "../../../shared/services/facade.service";

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  public width: number = 0;
  public superuser: boolean = false;
  public admin: boolean = false;
  public account: AccountModel;

  constructor(private facadeService: FacadeService, private router: Router) { }

  ngOnInit() {
    this.account = this.facadeService.getCurrentAccount();
    this.superuser = (this.facadeService.authService.isSuperuser() || this.facadeService.authService.isAdmin()) && this.facadeService.authService.isEnabled();
    this.admin = this.facadeService.authService.isAdmin() && this.facadeService.authService.isEnabled();
  }

  show() {
    this.width = 250;
  }

  close() {
    this.width = 0;
  }

  logout() {
    this.facadeService.authService.logout();
    this.close();
    this.router.navigate(['/auth']);
  }

}
