import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../../../shared/services/loading.service';

@Component({
  selector: 'app-loading-mask',
  templateUrl: './loading-mask.component.html',
  styleUrls: ['./loading-mask.component.scss']
})
export class LoadingMaskComponent implements OnInit {

  public isLoading: boolean;

  constructor(private loadingService: LoadingService) { 
  }

  ngOnInit() {
    this.loadingService.getIsLoading().subscribe((loading) => {this.isLoading = loading});
  }

}
