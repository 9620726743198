import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../../shared/services/auth.service';
import { UserModel } from '../../../shared/model/user';
import { Observable } from 'rxjs';
import { AccountModel } from '../../../shared/model/account';
import { environment } from '../../../../environments/environment';
import {FacadeService} from "../../../shared/services/facade.service";

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {

  public user: UserModel;
  public account: AccountModel;
  public messageAlert: number = 0;

  public isEnabled: boolean = false;
  public isPlacti: boolean = false;

  @ViewChild('sideNav', { static: false }) sideNav;

  constructor(private facadeService: FacadeService) { }

  ngOnInit() {
    this.account = this.facadeService.getCurrentAccount();
    this.isPlacti = this.account.is_placti;
    this.facadeService.authService.getUser().subscribe((user) => this.user = user);
    this.facadeService.messageService.getMessageCount().subscribe((count) => {
      this.messageAlert = count;
    });

    this.isEnabled = this.facadeService.authService.isEnabled();
  }



  getAccountLogo() {
    return environment.apiUrl + this.account.logo + '?v=' + environment.version;
  }

  showSideNav(){
    return this.user.id && (this.facadeService.authService.isSuperuser() || this.facadeService.authService.isAdmin());
  }


  openNav() {
    this.sideNav.show();
  }


}
