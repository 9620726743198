import { Component, OnInit } from '@angular/core';
import {DialogConfig} from "../../../../core/components/dialog/dialog-config";
import {DialogRef} from "../../../../core/components/dialog/dialog-ref";

@Component({
  selector: 'app-register-payment-dialog',
  templateUrl: './register-payment-dialog.component.html',
  styleUrls: ['./register-payment-dialog.component.scss']
})
export class RegisterPaymentDialogComponent implements OnInit {

  public partecipant: any;
  public payment: number;
  public payment_note: string;

  constructor(public config: DialogConfig, public dialog: DialogRef) {}

  increment() {
    if (this.payment < this.partecipant.quantity) {
        this.payment++;
    }
  }

  decrement() {
      if (this.payment > 0) {
          this.payment--;
      }
  }

  onClose() {
    this.dialog.close({});
  }

  ngOnInit() {
    this.partecipant = this.config.data.partecipant;
    this.payment = this.partecipant.payment || 0;
    this.payment_note = this.partecipant.payment_note || "";
  }

  onOk() {
    const partecipant = Object.assign({}, this.partecipant, {payment: this.payment, payment_note: this.payment_note});
    this.dialog.close({ partecipant: (partecipant)  } );
  }
}
