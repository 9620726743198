import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient) { }

  get(action, url, params?): Observable<any> {
    params = params || {};
    return this.http.get(url, { headers: { 'X-Action':  action }, params: params });
  }

  post(action, url, params): Observable<any>
  {
      return this.http.post(url, params, { headers: { 'X-Action':  action } });
  }

  put(action, url, params): Observable<any>
  {
      return this.http.put(url, params, { headers: { 'X-Action':  action } });
  }

  delete(action, url): Observable<any>
  {
      return this.http.delete(url, { headers: { 'X-Action':  action } });
  }

}
