import {Component, Input, OnInit, Output, forwardRef} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import {DialogService} from "../dialog/dialog.service";
import {MultiSelectFieldModalComponent} from "./multi-select-field-modal.component";


const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MultiSelectFieldComponent),
    multi: true
};
@Component({
    selector: 'app-multi-select-field',
    templateUrl: './multi-select-field.component.html',
    styleUrls: ['./multi-select-field.component.scss'],
    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class MultiSelectFieldComponent implements OnInit, ControlValueAccessor {

    @Input() label: string = '';
    @Input() name: string = '';
    @Input() options: any = {};

    //@Input() options: Array<string> = [];
    // @Input() set options(o: any) {
    //   console.log(o);
    //   if (o != this.outerOptions) {
    //     this.outerOptions = o;
    //   }
    // }
    // get options(): any {
    //   return this.outerOptions;
    // }


    //get accessor
    @Input() set value(v: any) {
      console.log(v);
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCallback(v);
        }
    }
    get value(): any {
        return this.innerValue;
    };

    constructor(public dialog: DialogService) { }

    ngOnInit() {
        for (let i in this.options) {
            this.innerOptions[this.options[i].value] = this.options[i];
        }
    }

    //The internal data model
    private outerOptions: any = [];
    private innerOptions: any = {};
    private innerValue: any = [];

    //Placeholders for the callbacks which are later providesd
    //by the Control Value Accessor
    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;


    //Set touched on blur
    onBlur() {
        this.onTouchedCallback();
    }

    onClick()
    {
        const ref = this.dialog.open(MultiSelectFieldModalComponent, { data: { options: this.options, selected: this.value }});

        ref.afterClosed.subscribe(result => {
            if (result.selected) {
                this.value = result.selected;
            }
        });
    }

    //From ControlValueAccessor interface
    writeValue(value: any) {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    }

    onRemove(val, e) {
        e.preventDefault();
        e.stopPropagation();

        let index = this.value.indexOf(val);
        this.value.splice(index, 1);
    }

    //From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    //From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

}

