import { Component, OnInit } from '@angular/core';
import {DialogConfig} from "../dialog/dialog-config";
import {DialogRef} from "../dialog/dialog-ref";


@Component({
  selector: 'multi-select-field-modal',
  templateUrl: './multi-select-field-modal.component.html',
  styleUrls: ['./multi-select-field-modal.component.scss']
})
export class MultiSelectFieldModalComponent implements OnInit {

    public options: any = {};
    public selected: Array<string> = [];
    private exclusive: Array<string> = [];

    constructor(public config: DialogConfig, public dialog: DialogRef) {}

    onClose() {
        this.dialog.close({});
    }

    ngOnInit() {
        this.options = this.config.data.options;
        for(let i in this.options) {
            if (this.options[i].exclusive) {
                this.exclusive.push(this.options[i].value);
            }
        }
        this.selected = (this.config.data.selected || []).slice(0);
    }

    onSelect(option)
    {

        let index = this.selected.indexOf(option.value);

        if (option.disabled) {
            return false;
        }

        if (index > -1) {
            this.selected.splice(index, 1);
        } else {

            if (option.exclusive) {

                if (this.options.some((o) => o.disabled == true && this.selected.indexOf(o.value) > -1)) {
                    return false;
                }

                this.selected = [option.value];


            } else {

                // se un esclusiva è selezionata ma disabilitata non posso fare niente
                if (this.options.some((o) => o.disabled == true && o.exclusive == true && this.selected.indexOf(o.value) > -1)) {
                    return false;
                }

                this.selected.push(option.value);
                this.selected = this.selected.filter((item) => this.exclusive.indexOf(item) < 0);
            }
        }

    }

    onOk() {
        this.dialog.close({ selected: this.selected } );
    }
}