import {PlateModel} from "./plate";
import {AbstractControl, ValidatorFn} from "@angular/forms";

export class OrderRequestModel
{
    id: number;
    number: number;

    client_name: string;
    reference: string;

    total_price: string = '0.00';
    completed: boolean = false;
    cancelled: boolean = false;

    purchase_order_id: number;

    created_at: Date;

    plates: PlateModel[];
}

export function requiredPlate(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
        return control.value.filter((v) => v.quantity && v.quantity > 0).length ? null : { required: true };
    };
}

export function plateAvailable(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {

        let outOfOrder = control.value
            .filter((v) => v.quantity && v.available_quantity && v.quantity > v.available_quantity);

        const errors = {};

        for (let i in outOfOrder) {
            errors[outOfOrder[i].menu_item_id] =  outOfOrder[i].available_quantity;
        }

        return outOfOrder.length ? {'outOfOrderValues': errors} : null;
    };
}