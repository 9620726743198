import { Component, OnInit } from '@angular/core';
import {DialogConfig} from "../../../../core/components/dialog/dialog-config";
import {DialogRef} from "../../../../core/components/dialog/dialog-ref";

@Component({
  selector: 'app-filter-order-request-dialog',
  templateUrl: './filter-order-request-dialog.component.html',
  styleUrls: ['./filter-order-request-dialog.component.scss']
})
export class FilterOrderRequestDialogComponent implements OnInit {

  public selected: string;

  options = [
    {
      label: 'Tutte',
      value: 'all'
    },
    {
      label: 'In attesa',
      value: 'pending'
    },
    {
      label: 'Processate',
      value: 'processed'
    },
    {
      label: 'Completate',
      value: 'completed'
    },
    {
      label: 'Cancellate',
      value: 'cancelled'
    }
  ];

  constructor(public config: DialogConfig, public dialog: DialogRef) {}

  onClose() {
    this.dialog.close({});
  }

  ngOnInit() {
    this.selected = this.config.data.view;
  }

  onOk() {
    this.dialog.close({ view: this.selected } );
  }

  onSelect(option)
  {
    this.selected = option.value;
  }
}