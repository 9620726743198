import { Component, OnInit } from '@angular/core';
import {PurchaseOrderModel} from "../../../../shared/model/purchase-orders/purchase-order";
import {FacadeService} from "../../../../shared/services/facade.service";
import {ActivatedRoute, Router} from "@angular/router";
import {OrderRequestCashModel} from "../../../../shared/model/purchase-orders/order-request-cash";

@Component({
  selector: 'app-order-request-cash',
  templateUrl: './order-request-cash.component.html',
  styleUrls: ['./order-request-cash.component.scss']
})
export class OrderRequestCashComponent implements OnInit {

  public purchase_order: PurchaseOrderModel;

  public order_request_cash: OrderRequestCashModel;

  constructor(private facadeService: FacadeService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data.subscribe((data: { data: {order_request_cash: OrderRequestCashModel, purchase_order: PurchaseOrderModel }} ) => {
      if (data.data) {
        this.order_request_cash = data.data.order_request_cash;
        this.purchase_order = data.data.purchase_order;
      }
      else this.router.navigate(['/purchase-orders']);
    });

    this.facadeService.purchaseOrderService.getOrderRequestCash().subscribe((order_request_cash) => this.order_request_cash = order_request_cash);
  }


  refresh()
  {
    this.facadeService.purchaseOrderService.loadOrderRequestCash(this.purchase_order).subscribe();
  }

}
