import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterOrderRequestStats'
})
export class FilterOrderRequestStatsPipe implements PipeTransform {

  transform(value: any, hiddenOrderRequestStats: Array<string>, visibility?: string, args?: any): any {
    return value.filter((v) => {
      if (visibility == 'hidden') return hiddenOrderRequestStats.indexOf(v.name.toLowerCase()) > -1;
      else return hiddenOrderRequestStats.indexOf(v.name.toLowerCase()) < 0;
    });
  }

}
