import {Component, ElementRef, forwardRef, Input, OnInit} from '@angular/core';
import {FacadeService} from "../../../../shared/services/facade.service";
import {ControlValueAccessor, FormBuilder, NG_VALIDATORS, NG_VALUE_ACCESSOR} from "@angular/forms";
import {MenuItemModel} from "../../../../shared/model/purchase-orders/menu-item";
import {DialogService} from "../../../../core/components/dialog/dialog.service";
import {MenuItemFieldModalComponent} from "./menu-item-field-modal.component";

@Component({
  selector: 'app-menu-item-field',
  templateUrl: './menu-item-field.component.html',
  styleUrls: ['./menu-item-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MenuItemFieldComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MenuItemFieldComponent),
      multi: true
    }
  ]
})
export class MenuItemFieldComponent implements OnInit, ControlValueAccessor  {

  constructor(private facadeService: FacadeService, private elementRef: ElementRef, private formBuilder: FormBuilder, public dialog: DialogService) { }

  @Input() config;
  @Input() group;
  @Input() formSubmitAttempt;

  value: Partial<MenuItemModel>[];

  purchase_order_id: number;

  formControl;

  private ids = 0;

  ngOnInit() {
    this.purchase_order_id = this.config.purchase_order_id;
    this.formControl = this.group.get(this.config.key);
    this.formControl.valueChanges.subscribe((value) => {
      this.writeValue(value.slice())
    });

    this.writeValue(this.formControl.value.slice());
  }


  editMenuItem(menuItem)
  {
    const ref = this.dialog.open(MenuItemFieldModalComponent, { data: { menu_item: Object.assign({}, menuItem) } });
    ref.afterClosed.subscribe(result => {
      if (result && result.menu_item) {
        this.update(result.menu_item);
      }
    });
  }


  addMenuItem() {
    const ref = this.dialog.open(MenuItemFieldModalComponent, { data: { menu_item: { position: (this.formControl.value.length+1), name: '', price: '', max_quantity: null, auto_served: false } } });
    ref.afterClosed.subscribe(result => {
      if (result && result.menu_item) {
        this.add(result.menu_item);
      }
    });
  }

  update(selectedMenuItem) {
    const v = this.value.slice(),
      index = v.findIndex((item) => item.id == selectedMenuItem.id);

    v.splice(index, 1);

    // ricalcolo le posizioni
    v.map((item, index) => {
      item.position = index + 1;
      return item;
    });

    let position = parseInt(selectedMenuItem.position);
    if (!position || position > v.length + 1) {
      position = v.length+1;
    }

    selectedMenuItem.position = position;
    v.push(selectedMenuItem);

    v.map((item) => {
      if (item.position >= selectedMenuItem.position && item.id != selectedMenuItem.id) {
        item.position += 1;
      }
      return item;
    });

    this.propagateChange(v);
  }

  add(selectedMenuItem)
  {
    const v = this.value.slice();
    let newItem;
    let position = parseInt(selectedMenuItem.position);

    if (!position || position > v.length + 1) {
      position = v.length+1;
    }

    v.push(newItem = Object.assign({}, selectedMenuItem, {
      id: this.ids-=1,
      position: position,
      purchase_order_id: this.purchase_order_id
    }));

    v.map((item) => {
      if (item.position >= newItem.position && item.id != newItem.id) {
        item.position += 1;
      }
      return item;
    });

    this.propagateChange(v);
  }


  remove(menuItem: Partial<MenuItemModel>)
  {
    const v = this.value.filter((c) => c.id != menuItem.id);

    v.map((item) => {
      if (item.position >= menuItem.position) {
        item.position = item.position-1;
      }
      return item;
    });

    this.propagateChange(v);
  }

  isValid()
  {
    return this.formControl.valid || !(
        (!this.formControl.valid && this.formControl.touched) ||
        (this.formControl.untouched && this.formSubmitAttempt)
    );
  }

  hasErrors(menuItem)
  {
    if(this.formControl.errors && this.formControl.errors.duplicateValues) {
      return this.formControl.errors.duplicateValues.indexOf(menuItem.name.toLowerCase()) > -1;
    }
    return false;
  }

  writeValue(value) {

    value.sort((a, b) => {
      const nameA = (a.position || 0);
      const nameB = (b.position || 0);
      let comparison = 0;
      if (nameA > nameB) {
        comparison = 1;
      }
      if (nameA < nameB) {
        comparison = -1;
      }
      return comparison;
    });

    this.value = value;
  }

  validate() {
  }

  propagateChange: any = (value) => {

    let currentValue = this.formControl.value;
    let currentValueIds = currentValue.map(v => v.id);
    let valueIds = value.map(v => v.id);
    let valuesToAdd = value.filter((v) => currentValueIds.indexOf(v.id) < 0);
    let valuesToRemove = currentValue.filter(v => valueIds.indexOf(v.id) < 0);

    for (let i in valuesToRemove) {
      this.formControl.removeAt(currentValue.findIndex(v => v.id == valuesToRemove[i].id));
    }

    for (let i in valuesToAdd) {
      this.formControl.push(this.formBuilder.group(valuesToAdd[i]));
    }

    this.formControl.patchValue(value);

    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
    this.formControl.updateValueAndValidity();

  };


  validateFn: any = () => {};

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    //this.onChangeCallback = fn;
    this.propagateChange = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    //this.onTouchedCallback = fn;
  }
}
