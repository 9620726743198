import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceMarkdown'
})
export class ReplaceMarkdownPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;
    return value.replace(/\n/g, '<br/>').replace(/\*(\S[^\*]+\S)\*/g, "<strong>$1</strong>").replace(/\_(\S[^\_]+\S)\_/g, "<em>$1</em>");
  }
}
