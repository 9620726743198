import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, of, throwError} from "rxjs";
import {PurchaseOrderModel} from "../../../model/purchase-orders/purchase-order";
import {catchError, map, tap} from "rxjs/operators";
import {HttpService} from "../../http.service";
import {ShiftModel} from "../../../model/shift/shift";
import {OrderRequestModel} from "../../../model/purchase-orders/order-request";
import {PlateModel} from "../../../model/purchase-orders/plate";
import {OrderRequestStatModel} from "../../../model/purchase-orders/order-request-stat";
import {OrderRequestCashModel} from "../../../model/purchase-orders/order-request-cash";

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrdersService {
  
  private purchaseOrdersUrl = '/purchase-orders';  // URL to web api

  private purchase_orders: BehaviorSubject<PurchaseOrderModel[]> = new BehaviorSubject<PurchaseOrderModel[]>([]);
  private order_requests: BehaviorSubject<OrderRequestModel[]> = new BehaviorSubject<OrderRequestModel[]>([]);
  private order_request_stats: BehaviorSubject<OrderRequestStatModel[]> = new BehaviorSubject<OrderRequestStatModel[]>([]);
  private order_request_cash: BehaviorSubject<OrderRequestCashModel> = new BehaviorSubject<OrderRequestCashModel>(null);
  private currentPurchaseOrderId: number;

  private dataStore: {
    purchase_orders: PurchaseOrderModel[],
    order_requests: OrderRequestModel[],
    order_request_stats: OrderRequestStatModel[],
    order_request_cash: OrderRequestCashModel,
  } = { purchase_orders: [], order_requests: [], order_request_stats: [], order_request_cash: null };

  constructor(private http: HttpService) { }

  getPurchaseOrder(id) {

    this.currentPurchaseOrderId = id;

    return id < 0
        ? of({ id: -1, name: '' } as PurchaseOrderModel)
        : this.loadPurchaseOrder(id);
  }

  getPurchaseOrders() {
    return this.purchase_orders.asObservable();
  }

  loadPurchaseOrder(id)
  {
    const url = `${this.purchaseOrdersUrl}/${id}`;

    this.currentPurchaseOrderId = id;

    return this.http.get('loadPurchaseOrder', url).pipe(
        tap(_ => this.log(`fetched purchase_order id=${id}`)),
        catchError(this.handleError<ShiftModel>(`loadPurchaseOrder id=${id}`))
    );
  }

  loadPurchaseOrders(filter): Observable<PurchaseOrderModel[]> {

    return this.http.get('loadPurchaseOrders', this.purchaseOrdersUrl, filter).pipe(
        map((purchase_orders) => {
          Object.assign(this.dataStore, {purchase_orders: purchase_orders});
          this.purchase_orders.next(this.dataStore.purchase_orders);
          return purchase_orders;
        }),
        tap(shifts => this.log(`fetched purchase orders`)),
        catchError(this.handleError('loadPurchaseOrders', []))
    );
  }


  addPurchaseOrder (purchaseOrder: PurchaseOrderModel): Observable<PurchaseOrderModel> {

    return this.http.post('addPurchaseOrder', this.purchaseOrdersUrl, purchaseOrder).pipe(
        tap((purchase_order: PurchaseOrderModel) => this.log(`added purchase_order w/ id=${purchase_order.id}`)),
        catchError(this.handleError<PurchaseOrderModel>('addPurchaseOrder'))
    );

  }

  updatePurchaseOrder (purchaseOrder: PurchaseOrderModel): Observable<any> {

    const id = purchaseOrder.id;
    const url = `${this.purchaseOrdersUrl}/${id}`;

    return this.http.post('updatePurchaseOrder', url, purchaseOrder).pipe(
        tap((purchase_order: PurchaseOrderModel) => this.log(`updated purchase_order id=${purchase_order.id}`)),
        catchError(this.handleError<any>('updatePurchaseOrder'))
    );
  }

  deletePurchaseOrder (purchaseOrder: PurchaseOrderModel| number): Observable<PurchaseOrderModel> {

    const id = typeof purchaseOrder === 'number' ? purchaseOrder : purchaseOrder.id;
    const url = `${this.purchaseOrdersUrl}/${id}`;

    return this.http.delete('deletePurchaseOrder', url).pipe(
        tap(_ => this.log(`deleted purchase_order id=${id}`)),
        catchError(this.handleError<PurchaseOrderModel>('deletePurchaseOrder'))
    );

  }


  getOrderRequest(purchaseOrderId, id)
  {
    this.currentPurchaseOrderId = purchaseOrderId;

    if (id < 0) {
      return this.loadPurchaseOrder(purchaseOrderId).pipe(
          map((purchaseOrder) => {
            let index = 0,
              plates = [];
            for(let i = 0; i < purchaseOrder.menu_items.length; i++) {
              plates.push({
                id: index-=1,
                quantity: null,
                position: purchaseOrder.menu_items[i].position,
                name: purchaseOrder.menu_items[i].name,
                price: purchaseOrder.menu_items[i].price,
                menu_item_id: purchaseOrder.menu_items[i].id,
                available_quantity: purchaseOrder.menu_items[i].available_quantity,
                served: false
              } as PlateModel)
            }
            return ({ id: -1, client_name: '', purchase_order_id: purchaseOrderId, plates: plates, total_price: '0.00' } as OrderRequestModel)
          })
      );
    }

    return this.loadOrderRequest(purchaseOrderId, id);

  }

  getOrderRequests(){
    return this.order_requests.asObservable();
  }

  loadOrderRequest(purchaseOrderId, id) {
    const url = `${this.purchaseOrdersUrl}/${purchaseOrderId}/order-requests/${id}`;

    this.currentPurchaseOrderId = purchaseOrderId;

    return this.http.get('loadOrderRequest', url).pipe(
        tap(_ => this.log(`fetched order_request id=${id}`)),
        catchError(this.handleError<ShiftModel>(`loadOrderRequest id=${id}`))
    );
  }

  loadOrderRequests(purchaseOrderId, filter): Observable<OrderRequestModel[]> {

    const url = `${this.purchaseOrdersUrl}/${purchaseOrderId}/order-requests`;

    this.currentPurchaseOrderId = purchaseOrderId;

    return this.http.get('loadOrderRequests', url, filter).pipe(
        map((order_requests) => {
          Object.assign(this.dataStore, {order_requests: order_requests});
          this.order_requests.next(this.dataStore.order_requests);
          return order_requests;
        }),
        tap(shifts => this.log(`fetched order requests`)),
        catchError(this.handleError('loadOrderRequests', []))
    );

  }

  addOrderRequest (orderRequest: OrderRequestModel): Observable<OrderRequestModel> {

    const url = `${this.purchaseOrdersUrl}/${orderRequest.purchase_order_id}/order-requests`;

    return this.http.post('addOrderRequest', url, orderRequest).pipe(
        tap((order_request: OrderRequestModel) => this.log(`added order request w/ id=${order_request.id}`)),
        catchError(this.handleError<OrderRequestModel>('addOrderRequest'))
    );

  }

  updateOrderRequest (orderRequest: OrderRequestModel): Observable<any> {
    const url = `${this.purchaseOrdersUrl}/${orderRequest.purchase_order_id}/order-requests/${orderRequest.id}`;

    return this.http.post('updateOrderRequest', url, orderRequest).pipe(
        tap((order_request: OrderRequestModel) => this.log(`updated order_request id=${order_request.id}`)),
        catchError(this.handleError<any>('updateOrderRequest'))
    );
  }


  completeOrderRequest(orderRequest: OrderRequestModel) {

    const url = `${this.purchaseOrdersUrl}/${orderRequest.purchase_order_id}/order-requests/${orderRequest.id}/complete`;
    return this.http.put('completeOrderRequest', url, {}).pipe(
        tap(_ => this.log(`complete order_request id=${orderRequest.id}`)),
        catchError(this.handleError<ShiftModel>(`getOrderRequest id=${orderRequest.id}`))
    );

  }

  cancelOrderRequest(orderRequest: OrderRequestModel)
  {
    const url = `${this.purchaseOrdersUrl}/${orderRequest.purchase_order_id}/order-requests/${orderRequest.id}/cancel`;
    return this.http.put('completeOrderRequest', url, {}).pipe(
        tap(_ => this.log(`complete order_request id=${orderRequest.id}`)),
        catchError(this.handleError<ShiftModel>(`getOrderRequest id=${orderRequest.id}`))
    );
  }

  onOrderRequestAdded(e)
  {
    let orderRequest = e.data as OrderRequestModel;

    if (this.currentPurchaseOrderId == orderRequest.purchase_order_id) {

      this.dataStore.order_requests.push(orderRequest);
      this.dataStore.order_requests = this.dataStore.order_requests.slice();
      this.order_requests.next(this.dataStore.order_requests);
    }
  }

  onOrderRequestUpdated(e)
  {
    let orderRequest = e.data as OrderRequestModel;

    if (this.currentPurchaseOrderId == orderRequest.purchase_order_id) {
      const index = this.dataStore.order_requests.findIndex((or) => or.id == orderRequest.id);
      this.dataStore.order_requests[index] = orderRequest;
      this.dataStore.order_requests = this.dataStore.order_requests.slice();
      this.order_requests.next(this.dataStore.order_requests);
    }
  }

  getOrderRequestStats(): Observable<OrderRequestStatModel[]>
  {
    return this.order_request_stats.asObservable();
  }

  loadOrderRequestStats (purchaseOrder: PurchaseOrderModel | number): Observable<OrderRequestStatModel[]>
  {
    const id = typeof purchaseOrder === 'number' ? purchaseOrder : purchaseOrder.id;
    const url = `${this.purchaseOrdersUrl}/${id}/order-requests/stats`;

    this.currentPurchaseOrderId = id;

    return this.http.get('loadOrderRequestStats', url).pipe(
        map((order_request_stats) => {
          Object.assign(this.dataStore, {order_request_stats: order_request_stats});
          this.order_request_stats.next(this.dataStore.order_request_stats);
          return order_request_stats;
        }),
        tap(_ => this.log(`read order request stats id=${id}`)),
        catchError(this.handleError<OrderRequestStatModel>('loadOrderRequestStats'))
    );
  }

  onOrderRequestStatsUpdated(e)
  {
    let orderRequestStats = e.data.order_request_stats as OrderRequestStatModel[],
        purchaseOrderId = e.data.purchase_order_id;

    if (this.currentPurchaseOrderId == purchaseOrderId) {
      this.dataStore.order_request_stats = orderRequestStats;
      this.order_request_stats.next(this.dataStore.order_request_stats);
    }
  }

  getOrderRequestCash(): Observable<OrderRequestCashModel>
  {
    return this.order_request_cash.asObservable();
  }

  loadOrderRequestCash (purchaseOrder: PurchaseOrderModel | number): Observable<OrderRequestCashModel>
  {
    const id = typeof purchaseOrder === 'number' ? purchaseOrder : purchaseOrder.id;
    const url = `${this.purchaseOrdersUrl}/${id}/order-requests/cash`;

    this.currentPurchaseOrderId = id;

    return this.http.get('loadOrderRequestCash', url).pipe(
        map((order_request_cash) => {
          Object.assign(this.dataStore, {order_request_cash: order_request_cash});
          this.order_request_cash.next(this.dataStore.order_request_cash);
          return order_request_cash;
        }),
        tap(_ => this.log(`read order request cash id=${id}`)),
        catchError(this.handleError<OrderRequestStatModel>('loadOrderRequestCash'))
    );
  }

  onOrderRequestCashUpdated(e)
  {
    let orderRequestCash = e.data.order_request_cash as OrderRequestCashModel,
        purchaseOrderId = e.data.purchase_order_id;

    if (this.currentPurchaseOrderId == purchaseOrderId) {
      this.dataStore.order_request_cash = orderRequestCash;
      this.order_request_cash.next(this.dataStore.order_request_cash);
    }
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {

    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return throwError(error.error.errors || null);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    console.log('PurchaseOrdersService: ' + message);
  }

}
