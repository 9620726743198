import { Component, OnInit } from '@angular/core';
import {EventModel} from "../../../../shared/model/event";
import {PartecipantModel} from "../../../../shared/model/partecipant";
import {FacadeService} from "../../../../shared/services/facade.service";
import * as moment from 'moment-timezone';
import {Router} from "@angular/router";
import {AuthService} from "../../../../shared/services/auth.service";
import { AccountModel } from '../../../../shared/model/account';

@Component({
    selector: 'app-partecipant-form',
    templateUrl: './partecipant-form.component.html',
    styleUrls: ['./partecipant-form.component.scss']
})
export class PartecipantFormComponent implements OnInit {

    public event: EventModel = {} as EventModel;
    public partecipant: PartecipantModel = {} as PartecipantModel;
    public eventDates: string = '';
    public account: AccountModel;
    public notePlaceholder: string = "Inserisci qui informazioni aggiuntive sulla prenotazione, segnalando la presenza di bambini, celiaci e vegetariani.";

    constructor(private facadeService: FacadeService, private router: Router) {}

    ngOnInit() {
        this.account = this.facadeService.getCurrentAccount();
        this.notePlaceholder = this.account.config ? this.account.config.note_placeholder || this.notePlaceholder : this.notePlaceholder;

        this.facadeService.eventService.getEvent().subscribe((event) => {

            const userId = this.facadeService.authService.getUserId();

            this.event = event;

            this.partecipant = event.partecipants.filter((p) => p.user_id == userId)[0] || { id: -1, quantity: 1 } as PartecipantModel;

            let dates = [];
            this.event.activities.forEach((activity) => {
                if (activity.start_at) dates.push(moment(activity.start_at).format('DD/MM/YYYY'));
            });

            this.eventDates = dates.filter((item,index,self) => self.indexOf(item)==index).join(' - ');
        });
    }

    increment() {
        if (!this.event.partecipants_per_user || this.partecipant.quantity < this.event.partecipants_per_user) {
            this.partecipant.quantity++;
        }
    }

    decrement() {
        if (this.partecipant.quantity > 0) {
            this.partecipant.quantity--;
        }
    }

    onSubmit() {
        let action;

        if (this.partecipant.id < 0 && this.partecipant.quantity > 0) {
            action = this.facadeService.eventService.addPartecipant(this.event.id, this.partecipant);
        }

        if (this.partecipant.id > 0 && this.partecipant.quantity == 0) {
            action = this.facadeService.eventService.deletePartecipant(this.event.id, this.partecipant);
        }

        if (this.partecipant.id > 0 && this.partecipant.quantity > 0) {
            action = this.facadeService.eventService.updatePartecipant(this.event.id, this.partecipant);
        }

        if(action) {
            action.subscribe(() => this.router.navigate(['/']));
        } else {
            this.router.navigate(['/']);
        }

    }


}
