import { Injectable } from '@angular/core';

import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { NotificationModel } from '../../model/notification';
import {HttpService} from "../http.service";


@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    private notificationsUrl = "/notifications";

    private notificationCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private readNotifications: BehaviorSubject<NotificationModel[]> = new BehaviorSubject<NotificationModel[]>([]);
    private unreadNotifications: BehaviorSubject<NotificationModel[]> = new BehaviorSubject<NotificationModel[]>([]);

    private dataStore: {
        read: NotificationModel[],
        unread: NotificationModel[],
        count: number
    } = { read: [], unread: [], count: 0 };

    constructor(private http: HttpService) { }


    loadNotifications(): Observable<{ unread: NotificationModel[], read: NotificationModel[] }> {

        return this.http.get('loadNotifications', this.notificationsUrl).pipe(
            map((notifications) => {

                Object.assign(this.dataStore, notifications);
                this.readNotifications.next(this.dataStore.read);
                this.unreadNotifications.next(this.dataStore.unread);

                return notifications;
            }),
            tap(notifications => this.log(`fetched notifications`)),
            catchError(this.handleError('getNotifications', []))
        );
    }


    getReadNotifications(): Observable<NotificationModel[]> {
        return this.readNotifications.asObservable();
    }

    getUnreadNotifications(): Observable<NotificationModel[]> {
        return this.unreadNotifications.asObservable();
    }


    markAsRead (): Observable<any> {

        const url = `${this.notificationsUrl}/read`;

        return this.http.put('markNotificationsAsRead', url, {}).pipe(
            map(() => this.notificationCount.next(0)),
            tap(_ => this.log(`readed notifications`)),
            catchError(this.handleError<any>('readNotifications'))
        );

    }


    markAsClick (notification: NotificationModel | number): Observable<any> {

        const id = typeof notification == 'number' ? notification : notification.id;
        const url = `${this.notificationsUrl}/${id}/click`;

        return this.http.put('markNotificationAsClick', url, {}).pipe(
            tap(_ => this.log(`click notification`)),
            catchError(this.handleError<any>('clickNotification'))
        );

    }


    onNotificationAdded(e)
    {
        this.updateNotificationCount();

        let notification = e.data as NotificationModel;

        this.dataStore.unread.splice(0,0, notification);
        this.unreadNotifications.next(this.dataStore.unread);

        if (['role_enabled','role_disabled'].indexOf(notification.type) > -1) {
            window.location.reload();
        }

    }


    getNotificationCount(): Observable<number> {
        return this.notificationCount.asObservable();
    }


    loadNotificationCount(): Observable<any> {

        const url = `${this.notificationsUrl}/count`;

        return this.http.get('loadNotificationCount', url).pipe(
            map((notifications: {count: number}) => {

                Object.assign(this.dataStore, notifications);
                this.notificationCount.next(notifications.count);

                return notifications;
            }),
            tap(notifications => this.log(`fetched notifications`)),
            catchError(this.handleError('getNotifications', []))
        );
    }


    updateNotificationCount()
    {
        this.notificationCount.next(Number(this.notificationCount.getValue())+1);
    }


    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T> (operation = 'operation', result?: T) {

        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };

    }

    /** Log a HeroService message with the MessageService */
    private log(message: string) {
        console.log('NotificationService: ' + message);
    }

}