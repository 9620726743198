import {Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, FormBuilder, NG_VALIDATORS, NG_VALUE_ACCESSOR} from "@angular/forms";
import {FacadeService} from "../../../../shared/services/facade.service";
import {PlateModel} from "../../../../shared/model/purchase-orders/plate";

@Component({
  selector: 'app-plate-field',
  templateUrl: './plate-field.component.html',
  styleUrls: ['./plate-field.component.scss'],  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PlateFieldComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PlateFieldComponent),
      multi: true
    }
  ]
})
export class PlateFieldComponent implements OnInit, ControlValueAccessor  {

  constructor(private facadeService: FacadeService, private elementRef: ElementRef, private formBuilder: FormBuilder) { }

  @Input() config;
  @Input() group;
  @Input() formSubmitAttempt;

  value: Partial<PlateModel>[];

  order_request_id: number;

  formControl;

  ngOnInit() {
    this.order_request_id = this.config.purchase_order_id;
    this.formControl = this.group.get(this.config.key);
    this.formControl.valueChanges.subscribe((value) => {
      this.writeValue(value.slice())
    });

    this.writeValue(this.formControl.value.slice());
  }

  isValid()
  {
    return this.formControl.disabled || this.formControl.valid || !(
        (!this.formControl.valid && this.formControl.touched) ||
        (this.formControl.untouched && this.formSubmitAttempt)
    );
  }

  hasErrors(plate)
  {
    if(this.formControl.errors && this.formControl.errors.outOfOrderValues) {
      if (Object.keys(this.formControl.errors.outOfOrderValues).map((v) => parseInt(v)).indexOf(plate.menu_item_id) > -1) {
        plate.available_quantity = this.formControl.errors.outOfOrderValues[plate.menu_item_id];
        return true;
      }
    }
    return false;
  }

  writeValue(value) {

    value.sort((a, b) => {
      const nameA = (a.position || 0);
      const nameB = (b.position || 0);
      let comparison = 0;
      if (nameA > nameB) {
        comparison = 1;
      }
      if (nameA < nameB) {
        comparison = -1;
      }
      return comparison;
    });

    this.value = value;
  }

  validate() {
  }

  onQuantityKeyPress(e)
  {
    return !!e.key.match(new RegExp('[0-9]'));
  }

  addClick(plate) {
    this.facadeService.sound.playClick();
    this.updateQuantity(plate, plate.quantity+1);
  }

  updateQuantity(plate, value)
  {
    const v = this.value.slice(),
      index = v.findIndex((p) => p.id == plate.id);
    value = parseInt(value);
    v[index].quantity = value > 0 ? (value < 1000 ? value : 999) : '';
    this.propagateChange(v);
  }

  serve(plate)
  {
    const v = this.value.slice(),
        index = v.findIndex((p) => p.id == plate.id);
    v[index].served = true;
    this.propagateChange(v);
  }


  propagateChange: any = (value) => {

    this.formControl.patchValue(value);

    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
    this.formControl.updateValueAndValidity();

  };

  validateFn: any = () => {};

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    //this.onChangeCallback = fn;
    this.propagateChange = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    //this.onTouchedCallback = fn;
  }

}
