import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {
    Router, Resolve,
    ActivatedRouteSnapshot
} from '@angular/router';
import {FacadeService} from "../../../shared/services/facade.service";
import {AccountModel} from "../../../shared/model/account";

@Injectable()
export class AccountResolve implements Resolve<AccountModel> {
    constructor(private facadeService: FacadeService, private router: Router) { }
    
    resolve(route: ActivatedRouteSnapshot): Observable<AccountModel> {
        let id = +route.params['id'];
        return this.facadeService.accountService.getAccount(id).pipe(
            map((account) => account)
        );
    }
}