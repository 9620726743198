import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad, Route } from '@angular/router';
import { Observable } from 'rxjs';
import {FacadeService} from "../services/facade.service";

@Injectable()
export class AuthGuard implements CanLoad, CanActivate {
  
  constructor(private facadeService: FacadeService, private router: Router) {}

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {

    if (this.facadeService.authService.isAuth()) {

      if (!this.facadeService.authService.isVerified()) {
        this.router.navigate(['auth/validate']);
        return Promise.resolve(false);
      }

      if (!this.facadeService.authService.isEnabled()) {
        this.router.navigate(['auth/disabled']);
        return Promise.resolve(false);
      }

      return Promise.resolve(true);
    }

    if (this.facadeService.authService.getToken()) {
      return this.facadeService.initializeUser().then(() => {

        if(!this.facadeService.authService.isVerified()) {
          this.router.navigate(['auth/validate']);
          return false;
        }

        if (!this.facadeService.authService.isEnabled()) {
          this.router.navigate(['auth/disabled']);
          return Promise.resolve(false);
        }

        return true;

      }).catch(() => {
        this.router.navigate(['auth']);
        return false;
      });
    }

    this.router.navigate(['auth']);
    return Promise.resolve(false);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

      if (this.facadeService.authService.isAuth()) {

        if (!this.facadeService.authService.isVerified()) {
          this.router.navigate(['auth/validate']);
          return Promise.resolve(false);
        }
  
        if (!this.facadeService.authService.isEnabled()) {
          this.router.navigate(['auth/disabled']);
          return Promise.resolve(false);
        }
  
        return Promise.resolve(true);
      }

      return Promise.resolve(false);
  }

}
