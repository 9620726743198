import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-custom-top-bar',
  templateUrl: './custom-top-bar.component.html',
  styleUrls: ['./custom-top-bar.component.scss']
})
export class CustomTopBarComponent implements OnInit {

    @Input() title: boolean = true;

    constructor() { }

    ngOnInit() {
    }

}
