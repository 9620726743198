import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UserModel } from '../../../shared/model/user';
import { AuthService } from '../../../shared/services/auth.service';
import {FacadeService} from "../../../shared/services/facade.service";

@Component({
  selector: 'app-bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.scss']
})
export class BottomBarComponent implements OnInit {
  public user: UserModel;

  public isEnabled: boolean = false;
  public isAuth: boolean = false;

  public notificationAlert: number = 0;

  accountId: number;

  constructor(private facadeService: FacadeService) { }

  ngOnInit() {

    this.isAuth = this.facadeService.authService.isAuth();
    this.isEnabled = this.facadeService.authService.isEnabled();

    this.accountId = this.facadeService.getCurrentAccountId();

    this.facadeService.authService.getUser().subscribe((user) => {
      this.user = user;
    });

    this.facadeService.notificationService.getNotificationCount().subscribe((count) => this.notificationAlert = count);
  }

}
