import {GroupUserModel} from "./group_user";
import {AbstractControl, ValidatorFn} from "@angular/forms";

export class ShiftGroupModel
{
    id: number;
    name: string;
    group_users: Array<GroupUserModel>;
}

export function uniqueGroupUser(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
        let uniq = control.value
            .map((groupUser) => {
                return {
                    count: 1,
                    user_id: groupUser.user_id
                }
            })
            .reduce((a, b) => {
                a[b.user_id] = (a[b.user_id] || 0) + b.count;
                return a
            }, {});

        let duplicates = Object.keys(uniq).filter((a) => uniq[a] > 1).map(v => parseInt(v));
        return duplicates.length ? {'duplicateValues': duplicates} : null;
    };
}