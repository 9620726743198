import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../../shared/services/alert.service';

@Component({
  selector: 'app-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss']
})
export class ChipsComponent implements OnInit {

  public message: { success: boolean, text: string };

  constructor(private alertService: AlertService) { }

  ngOnInit() {
    let me = this;

    me.alertService.getMessage().subscribe(message => {
      if (message.text) {
        console.log(message);
        me.message = message;
        setTimeout(function(){
          me.message = {} as {success:boolean, text:string};
        }, 3000);
      }
    });

  }

}
